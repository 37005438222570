import { useState } from '@hookstate/core';
import moment from 'moment';
import React, { useEffect } from 'react';
import API from '../../common/API';


import emptyImg from "../../assets/img/illustrations/no_contact.svg";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import AddEdit from './AddEdit';


const TaskSectionList = props => {
    const state = useState({
        Contacts: [],
        UI: {
            showAddEdit: false,
            SelectedItem: null
        }
    })
    useEffect(() => {
        //Load remote records 
        request();
    }, []);

    const request = async () => {
        let query = "";

       
        if (props.OrganizationId) {
            query += (query.length > 0 ? "&" : "") + "OrganizationId=" + props.OrganizationId;
        }


        if (query.length === 0) {
            return;
        }
        let requestAPI = await API.getAction("ToDoTask/List?" + query);

        if (requestAPI.data.status === "ok") {
            console.log("post", requestAPI);
            let copy = JSON.parse(JSON.stringify(state.value));
            copy.Contacts = requestAPI.data.response;
            state.set(copy);
        }
    };
    const toggle = async (obj, reload) => {
        state.UI.SelectedItem.set(obj);
        state.UI.showAddEdit.set(!state.UI.showAddEdit.value);
        if (reload !== undefined) {
            request();
        }
    }

    const deleteConfirm = async (obj) => {
        confirmAlert({
            title: 'Confirm',
            message: 'Are you sure to delete ' + obj.name + "?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {

                        await API.getAction("ToDoTask/DeleteSection?id=" + obj.id);
                        await request();
                    }
                },
                {
                    label: 'No',
                    onClick: () => alert('Click No')
                }
            ]
        });
    }


    return (
        <>
            {state.UI.showAddEdit.value ?
                <AddEdit toggle={toggle} show={state.UI.showAddEdit.value} model={state.UI.SelectedItem.value} />
                : null}
            <div className="col-12">
                <div className="row">
                    <div className="col-auto">
                        <h5 className="display-4 d-inline">Secciones To-Do</h5>
                    </div>
                    <div className="col ml-auto text-right">
                        <button className="btn btn-primary d-inline ml-auto btn-sm" onClick={() =>
                            toggle({
                                id: "",
                                created: "",
                                name: "", lastName: "",
                                position: "", phone: "",
                                note: "",
                                employeeId: props.EmployeeId,
                                customerId: props.CustomerId,
                                employeePositionId: props.EmployeePositionId
                            })}>
                            <i className="fa fa-plus" />
                        </button>
                    </div>
                </div>
                {state.Contacts.length > 0 ?
                    state.Contacts.value.map((item, index) => {
                        let itemParsed = JSON.parse(JSON.stringify(item));
                        console.log("the item", item, itemParsed)
                        return (<div className="card shadow-sm mt-4" key={index}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        {item.name} 
                                      
                                    </div>
                                    <div className="col text-right">
                                        <div className="btn-group">
                                            <button className="btn btn-primary btn-sm" onClick={() => toggle(itemParsed)}>
                                                <i className="fa fa-edit" />
                                            </button>
                                            <button className="btn btn-primary btn-sm" onClick={() => deleteConfirm(itemParsed)}>
                                                <i className="fa fa-trash text-danger" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>);
                    })

                    : <div className="col-12 text-center">
                        <img src={emptyImg} className="img-fluid" style={{ maxWidth: "25%" }} />
                    </div>}

            </div>
        </>)
}

export default TaskSectionList