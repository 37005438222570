import OneSignal from "react-onesignal";

async function runOneSignal() {

	await OneSignal.init({
		appId: "a887bc6a-b2c1-44c2-990a-954807856f06",
		safari_web_id: "web.onesignal.auto.62c55c8f-4b76-4a2c-bf2b-fd3fb88f056f",
		notifyButton: {
			enable: true,
		},
		allowLocalhostAsSecureOrigin: true,
	});
	// OneSignal.Slidedown.promptPush();
}
export default runOneSignal;
