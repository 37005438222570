import React, { createContext, useEffect } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { date } from 'yup';
import Settings from '../common/Settings';

export const socketComponent = ExecuteOnStart => {

  const socket = new HubConnectionBuilder()
    .withUrl(Settings.SignalURL)
    .configureLogging(LogLevel.Debug)
    .withAutomaticReconnect()
    .build();

  socket.onreconnecting(error => {
    console.log("Connection lost due to error " + error + ". Reconnecting.");
  });

  socket.onreconnected(() => {
    //  cleanUp();
    console.log("socket reconnected", socket.state);

    ExecuteOnStart();
  });

  const startSocket = () => {
    console.log("start socket", new date())

    // cleanUp();
    socket.start().then(state => {
      console.log("socket connected", socket.state)

      ExecuteOnStart()

    })

      ;
  }

  startSocket();

  return socket;
}

export const SocketContext = createContext();