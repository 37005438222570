import axios from "axios"
import Settings from "./Settings"
import GetCurrentUser from "./GetCurrentUser"
import { ProperBasePathAPI } from "../components/files2/helper"

let options = {
    headers: { "Content-Type": "application/json", accept: "text/plain" },

    crossdomain: true,
}
let path = ProperBasePathAPI()
let user = GetCurrentUser()
axios.interceptors.request.use(config => {
    // log a message before any HTTP request is sent
    console.log("The request ==== ", config)
    return config
})
export default {
    postAction: async (endpoint, params, newOpts) => {

        if (user) {
            options = {
                headers: {
                    "Content-Type": "application/json",
                    accept: "text/plain",
                    Authorization: "Bearer " + user,
                },

                crossdomain: true,
            }
        }

        return axios.post(
            path + endpoint,
            params ? params : null,
            newOpts ? newOpts : options
        )
    },
    getAction: async (endpoint, params, newOpts) => {

        if (user) {
            options = {
                headers: {
                    "Content-Type": "application/json",
                    accept: "text/plain",
                    Authorization: "Bearer " + user,
                },

                crossdomain: true,
            }
        }
        return axios.get(
            path +
            endpoint +
            (params !== undefined && params !== null ? "?" + params : ""),
            newOpts !== undefined && newOpts !== null ? newOpts : options
        )
    },
    postActionExternal: async (endpoint, params, newOpts) =>
        axios.post(
            endpoint,
            params !== null ? params : null,
            newOpts !== null ? newOpts : options
        ),
    getActionExternal: async (endpoint, newOpts) => {
        let results = axios.get(endpoint, newOpts)

        return results
    },
}
