import React, { useEffect, useState } from "react";

import { Modal, Button, Col, Row, Form } from "react-bootstrap";

import * as yup from "yup";
import { Formik, useFormik } from "formik";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6

import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead"; // ES2015

import "react-bootstrap-typeahead/css/Typeahead.css";

import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import API from "../../../common/API";
import { getDayOfWeek, makeId, onlyHTMLTags } from "../../../common/helpers";
import { parsedUser } from "../../../common/GetCurrentUser";
import { confirmAlert } from "react-confirm-alert";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { db } from "../../../common/db";

//Validation
const validationSchema = yup.object({
  name: yup.string().required(),
  date: yup.string().required(),
  status: yup.number().required(),
  type: yup.number().required(),
});
//Component
const AddEditActivity = (props) => {
  const [state, setState] = useState({
    UI: {
      isLoading: false,
    },
  });
  const [invitees, setInvitees] = useState([]);
  const [inviteesOpts, setInviteesOpts] = useState([]);
  const [projects, setProjects] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [showMinute, setShowMinute] = useState(
    props.model
      ? props.model.minute && props.model.minute.length > 0
        ? true
        : false
      : false
  );

  const [model, setModel] = useState(
    props.model
      ? props.model
      : {
        name: "",
        notes: "",

        status: 0,
        createdId: null,
        updatedId: "",
        invites: "",
        location: "",
        date: moment(moment().format("YYYY-MM-DD")).add(8, "hours").format(),
        created: moment().format(),
        id: "",
        organizationId: null,
        appointmentTypeId: null,
        assignedToId: null,
        projectId: props.projectId,
        type: 1,
        //  duration: duration
      }
  );

  const [singleSelections, setSingleSelections] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    console.log("props received on activity", props);

    let parsed = parsedUser();

    setModel({
      ...model,
      organizationId: parsed.OrganizationId,
      assignedToId: parsed.ID,
    });

    if (model && model.assignedTo) {
      //find the user
      setSingleSelections([
        {
          id: model.assignedTo.assignedToId,
          label: model.assignedTo.email,
        },
      ]);
    } else {
      //Select current user by default
      setSingleSelections([
        {
          id: parsed.ID,
          label: parsed.Email,
        },
      ]);
    }

    //Invitees
    if (model.invitees) {
      let listInvitees = model.invitees.split(",");
      let editableInvitees = [];
      listInvitees.forEach((item) => {
        editableInvitees.push({
          id: item,
          label: item,
        });
      });

      setInvitees(editableInvitees);
    }

    projectRequest();
    requestOrganizations();

    //Focus
    setTimeout(() => {
      let focus = document.getElementsByClassName("name")[0];
      if (focus) {
        focus.focus();
      }
    }, 1000);
  }, []);

  //Load all the projects

  const projectRequest = async () => {
    let user = parsedUser();
    let projectsLocal = await db.projects
      .where({
        organizationId: props.model.organizationId
          ? props.model.organizationId
          : user.OrganizationId,
      })
      .toArray();
    projectsLocal = projectsLocal.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    setProjects(projectsLocal);
  };

  const requestOrganizations = async () => {
    let theUser = parsedUser();

    if (theUser === null) {
      return;
    }
    let requestAPI = await API.getAction(
      "Organization/OrganizationsWhereTheUserBelong?AccountId=" + theUser.ID
    );

    if (requestAPI.data.status === "ok") {
      setOrganizations(requestAPI.data.response);
    }
  };

  const handleSubmit = async (obj) => {
    try {
      console.log("values to submit", obj);

      if (obj.id.length === 0) {
        obj.id = uuidv4();
        obj.created = moment().format();
      }

      let parsed = parsedUser();

      //Load the invitees
      let theInvitees = "";
      invitees.forEach((item) => {
        theInvitees += (theInvitees.length > 0 ? "," : "") + item.label;
      });
      obj.organizationId = parsed.OrganizationId;
      obj.invitees = theInvitees;
      obj.projectId = obj.projectId === null ? props.projectId : obj.projectId;
      var parsedDate = moment(obj.date).format();
      obj.date = parsedDate.replace("+00:00", "-04:00");
      debugger;
      if (obj.endDate) {
        var parsedEndDate = moment(obj.endDate).format();
        obj.endDate = parsedEndDate.replace("+00:00", "-04:00");
      } else {
        obj.allDay = true;
      }

      setState({
        ...state,
        UI: {
          ...state.UI,
          isLoading: !state.UI.isLoading,
        },
      });

      let request = await API.postAction(
        "Appointments/AddEdit?CurrentUserId=" + parsed.ID,
        obj
      );
      setState({
        ...state,
        UI: {
          ...state.UI,
          isLoading: !state.UI.isLoading,
        },
      });

      if (request.status === 200) {
        props.toggle(request.data, "", true);
      }
    } catch (ex) {
      console.error(ex);
      setState({
        ...state,
        UI: {
          ...state.UI,
          isLoading: false,
        },
      });
    }
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: model,
    validationSchema,
    onSubmit: handleSubmit,
  });
  const handleSearchUsers = async (query) => {
    var theUser = parsedUser();
    var request = await API.getAction(
      "users/AutoCompleteSearch?Search=" +
      query +
      "&OrganizationId=" +
      theUser.OrganizationId
    );
    if (request.data.status === "ok") {
      let opts = request.data.response.map((e) => ({
        id: e.id,
        label: e.firstName + " " + e.lastName + " (" + e.email + ")",
      }));
      setOptions(opts);
    }
  };

  const toggleRecurrency = () => {
    if (formik.values.recurrence > 0) {
      formik.setFieldValue("recurrence", null);
    } else {
      formik.setFieldValue("recurrence", 2);
    }
  };
  const handleSearchInvitees = async (query) => {
    var theUser = parsedUser();
    var request = await API.getAction(
      "invitees/AutoCompleteSearch?Search=" +
      query +
      "&OrganizationId=" +
      theUser.OrganizationId
    );
    if (request.data.status === "ok") {
      let opts = request.data.response.map((e) => ({
        id: e,
        label: e,
      }));
      setInviteesOpts(opts);
    }
  };

  const deleteConfirm = async () => {


    confirmAlert({
      title: "Confirm",
      message: "Are you sure to delete " + props.model.name + "?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await API.getAction("appointments/Delete?id=" + props.model.id);
            props.toggle(props.model, "delete", true);
          },
        },
        {
          label: "No",
          //     onClick: () => alert('Click No')
        },
      ],
    });
  };

  const formUI = () => {
    return (
      <>
        {props.unified === true ? (
          <Col xs={6}>
            <Form.Label>Organización</Form.Label>
            <select
              value={formik.values.organizationId}
              onChange={formik.handleChange}
              className="form-control "
              id="organizationId"
            >
              <option value="">-</option>
              {organizations &&
                organizations.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  );
                })}
            </select>

            {formik.errors.organizationId ? (
              <div className="invalid text-sm">
                {formik.errors.organizationId}
              </div>
            ) : null}
          </Col>
        ) : null}
        <Col xs={6}>
          <Form.Label>Proyecto</Form.Label>
          <select
            value={formik.values.projectId}
            onChange={formik.handleChange}
            className="form-control "
            id="projectId"
          >
            <option value="">-</option>
            {projects &&
              projects.map((item, index) => {
                return (
                  <option value={item.id} key={index}>
                    {item.name}
                  </option>
                );
              })}
          </select>

          {formik.errors.projectId ? (
            <div className="invalid text-sm">{formik.errors.projectId}</div>
          ) : null}
        </Col>
        <Col xs={12}>
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type="text"
            className="name "
            id="name"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          {formik.errors.name ? (
            <div className="invalid text-sm">{formik.errors.name}</div>
          ) : null}
        </Col>
        <Col xs={12} md={6} className="mt-2">
          <Form.Label>Fecha</Form.Label>
          <div className="row mx-0">
            <div className="col-7 mx-0 px-0">
              <Form.Control
                className=""
                type="date"
                value={moment(formik.values.date).format("YYYY-MM-DD")}
                onChange={(e) => {
                  let combinedDate =
                    e.target.value +
                    "T" +
                    moment(formik.values.date).format("HH:mm:ssZ");
                  let theDate = moment(combinedDate).toDate();
                  console.log("The date", combinedDate, theDate);

                  formik.setFieldValue("date", theDate);
                  let day = getDayOfWeek(theDate);
                  console.log(day);
                  formik.setFieldValue("everyDay", day.value)
                  //formik.setFieldValue("date", dateOnly)
                }}
              />
              {formik.errors.date ? (
                <div className="invalid text-sm">{formik.errors.date}</div>
              ) : null}
            </div>

            <div className="col-5 mx-0 px-0">
              <DatePicker
                className="form-control "
                selected={
                  formik.values.date
                    ? moment(formik.values.date).toDate()
                    : moment().toDate()
                }
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                timeCaption="Time"
                dateFormat="h:mm aa"
                onChange={(e) => {
                  formik.setFieldValue("date", e);
                }}
              />
            </div>
          </div>
        </Col>

        <Col xs={12} md={6} className="mt-2">
          <Form.Label>Duración</Form.Label>
          <div className="row mx-0">

            <div className="col-12 mx-0 px-0">
              <DatePicker
                className="form-control "
                selected={
                  formik.values.endDate
                    ? moment(formik.values.endDate).toDate()
                    : moment().toDate()
                }
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                timeCaption="Time"
                dateFormat="h:mm aa"
                onChange={(e) => {
                  formik.setFieldValue("endDate", e);
                }}
              />
            </div>
          </div>

          {formik.errors.endDate ? (
            <div className="invalid text-sm">{formik.errors.endDate}</div>
          ) : null}
        </Col>

        <Col xs={12} className="mt-2">
          <Form.Label>Asignado a</Form.Label>

          <AsyncTypeahead
            id="basic-typeahead-single"
            labelKey="label"
            className=""
            isLoading={state.UI.isLoading}
            onChange={(e) => {
              setSingleSelections(e);
              if (e[0] !== undefined) {
                formik.setFieldValue("assignedToId", e[0].id);
              }
            }}
            options={options}
            minLength={2}
            onSearch={handleSearchUsers}
            placeholder="Selecciona un usuario"
            selected={singleSelections}
          />

          {formik.errors.assignedToId ? (
            <div className="invalid text-sm">{formik.errors.assignedToId}</div>
          ) : null}
        </Col>

        <Col xs={12} className="mt-2">
          <Form.Label>Invitados</Form.Label>
          <AsyncTypeahead
            id="basic-typeahead-singleInvitees"
            labelKey="label"
            allowNew
            multiple
            isLoading={state.UI.isLoading}
            onChange={(e) => {
              setInvitees(e);
              if (e[0] !== undefined) {
                formik.setFieldValue("invitees", e[0].id);
              }
            }}
            options={inviteesOpts}
            minLength={2}
            onSearch={handleSearchInvitees}
            placeholder="Correo"
            selected={invitees}
          />

          {formik.errors.invitees ? (
            <div className="invalid text-sm">{formik.errors.invitees}</div>
          ) : null}
        </Col>
        <Col xs={12} className="mt-2">
          <Form.Label>Ubicación</Form.Label>
          <div className="input-group">
            <Form.Control
              type="text"
              className=""
              name="location"
              value={formik.values.location}
              onChange={formik.handleChange}
            />
            <button
              className="btn btn-secondary btn-sm"
              type="button"
              title="Generar enlace"
              onClick={() => {
                let id = makeId(5);
                formik.setFieldValue(
                  "location",
                  "https://vo.shearly.com/meeting/details/" + id
                );
              }}
            >
              <i className="fas fa-retweet"></i>
            </button>
            <button
              className="btn btn-primary btn-sm"
              type="button"
              onClick={() => {
                window.open(formik.values.location);
              }}
            >
              <i className="fas fa-external-link-alt"></i> Abrir
            </button>
          </div>
          {formik.errors.location ? (
            <div className="invalid text-sm">{formik.errors.location}</div>
          ) : null}
        </Col>
        {/* RECURRENT TASK */}
        <Col xs={12} className="mt-4">
          <label htmlFor="isRecurrent">Es una actividad recurrente?</label>
          <input
            type="checkbox"
            checked={formik.values.recurrence > 0}
            name="isRecurrent"
            className="mx-2"
            onClick={() => toggleRecurrency()}
          />
        </Col>
        {formik.values.recurrence > 0 ? (
          <>
            <Col xs={6} className="mt-4">
              <Form.Label>Ocurre</Form.Label>
              <select
                name="recurrence"
                className="form-control"
                value={formik.values.recurrence}
                onChange={e => {

                  console.log(formik.values.recurrence)

                  formik.setFieldValue("recurrence", e.target.value)
                }}

              >
                <option value="">-</option>
                <option value="1">Diariamente</option>
                <option value="2">Semanalmente</option>
                <option value="3">Mensualmente</option>
                <option value="4">Anualmente</option>
                <option value="5">Personalizado</option>
              </select>
            </Col>
            {formik.values.recurrence === '5' ?
              <>

                <Col xs={6} className="mt-4">
                  <Form.Label>Se repite cada</Form.Label>
                  <div className="input-group">
                    <input type="number" className="form-control"
                      onChange={formik.handleChange}
                      value={formik.values.everyAmount} name="everyAmount"></input>
                    <select
                      name="every"
                      className="form-control"
                      value={formik.values.every}
                      onChange={formik.handleChange} >
                      <option value="">-</option>
                      {/* <option value="1">día (s)</option> */}
                      <option value="2">semana (s)</option>
                      <option value="3">mes (es)</option>
                      <option value="4">año (s)</option>
                    </select>
                  </div>

                </Col>
                <Col xs={6} className="mt-4">
                  <Form.Label>Día de la semana</Form.Label>
                  <select
                    name="everyDay"
                    disabled
                    className="form-control"
                    value={formik.values.everyDay ?? getDayOfWeek(formik.values.date).value}
                    onChange={e => {

                      console.log(formik.values.everyDay)

                      formik.setFieldValue("everyDay", e.target.value)
                    }}

                  >
                    <option value="">-</option>
                    <option value="1">Lunes</option>
                    <option value="2">Martes</option>
                    <option value="3">Miércoles</option>
                    <option value="4">Jueves</option>
                    <option value="5">Viernes</option>
                    <option value="6">Sábado</option>
                    <option value="0">Domingo</option>
                  </select>
                </Col>
              </>
              : null}
            <Col xs={6} className="mt-4">
              <Form.Label>Hasta</Form.Label>
              <input
                type="date"
                className="form-control"
                value={formik.values.untilDate}
                onChange={formik.handleChange}
              />
            </Col>
          </>
        ) : null}
        <Col xs={12} className="mt-2">
          <Form.Label>Notas</Form.Label>
          <textarea
            className="form-control "
            rows={3}
            value={formik.values.notes}
            onChange={(e) => {
              let onlyTags = onlyHTMLTags(e.target.value);
              formik.setFieldValue("notes", onlyTags ? "" : e.target.value);
            }}
          ></textarea>

          {formik.errors.notes ? (
            <div className="invalid text-sm">{formik.errors.notes}</div>
          ) : null}
        </Col>
      </>
    );
  };

  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      size={showMinute ? "xl" : "md"}
      className="modalRight"
    >
      <Modal.Header closeButton>
        <Modal.Title>Actividad</Modal.Title>
        {props.model.id.length !== 0 ? <>
          <button
            className={
              (showMinute ? "btn-primary" : "btn-secondary") + " btn btn-sm ms-2"
            }
            onClick={() => {
              setShowMinute(!showMinute);
            }}
          >
            Minuta
          </button>
          <button
            className="btn btn-sm "
            title="Compartir"
            onClick={async () => {
              if (navigator.share) {
                const shareData = {
                  title: "Compartir tarea",

                  url: "https://developer.mozilla.org",
                };

                await navigator.share(shareData);
              } else {
              }
            }}
          >
            <i class="fas fa-share-square"></i>
          </button>
        </> : null}
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            {showMinute ? (
              <>
                <Col xs={12} md={6}>
                  {formUI()}
                </Col>
                <Col xs={12} md={6} className="bg-light">
                  <Form.Label>Minuta</Form.Label>
                  <ReactQuill
                    value={formik.values.minute}
                    name="minute"
                    onChange={(e) => {
                      let onlyTags = onlyHTMLTags(e);
                      formik.setFieldValue("minute", onlyTags ? "" : e);
                    }}
                  />
                </Col>
              </>
            ) : (
              formUI()
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div
            className="row"
            style={{
              width: "100%",
            }}
          >
            <div className="col-6">
              {props.model.id.length !== 0 ? (
                <Button
                  variant="danger"
                  type="button"
                  className="btn-sm"
                  disabled={state.UI.isLoading}
                  onClick={() => deleteConfirm()}
                >
                  <i className="fa fa-trash"></i>
                </Button>
              ) : null}
            </div>
            <div className="col-6 text-right">
              <Button
                variant="primary"
                type="submit"
                className="btn-sm"
                disabled={state.UI.isLoading}
              >
                Guardar
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddEditActivity;
