import React, { useState } from "react";
import { Col, Row, Button, Dropdown } from '@themesberg/react-bootstrap';

import Users from "../Users/Users";
import OrganizationBox from "../Organizations/OrganizationBox";
import TaskSectionList from "../TaskSections/TaskSectionList";



export default (props) => {
  const [activeSection, setActiveSection] = useState("users");
  return (
    <>



      <Row className="my-4">
        <Col xs={12} xl={8}>

          {activeSection === "users" ?
            <div className="mb-4">
              <Users Full />
            </div> :
            null}
    {activeSection === "todotasksections" ?
            <div className="mb-4">
              <TaskSectionList />
            </div> :
            null}

        </Col>

        <Col xs={12} xl={4}>
          <Row>
            <Col xs={12}>
              <OrganizationBox />

              <ul className="nav nav-pills nav-fill  flex-column my-4 mx-4">
                <li className="nav-item "><button className={" btn-block btn  text-left " + (activeSection === "users" ? "btn-primary" : "btn")} onClick={() => setActiveSection("users")}>Usuarios</button></li>
                <li className="nav-item "><button className={" btn-block btn  text-left " + (activeSection === "roles" ? "btn-primary" : "btn")} onClick={() => setActiveSection("roles")}>Roles</button></li>
                <li className="nav-item "><button className={" btn-block btn  text-left " + (activeSection === "todotasksections" ? "btn-primary" : "btn")} onClick={() => setActiveSection("todotasksections")}>Secciones To-Do</button></li>
              </ul>
            </Col>

          </Row>
        </Col>
      </Row>
    </>
  );
};
