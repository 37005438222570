import React, { useEffect, useState } from "react";
import { parsedUser } from "../../../common/GetCurrentUser";
import API from "../../../common/API";
import { Routes } from "../../../routes";
import { Col, Form, Row } from "react-bootstrap";
import { onlyHTMLTags } from "../../../common/helpers";
import moment from "moment";

const OutsideActivityDetails = (props) => {
  const [model, setModel] = useState({});
  const request = async () => {
    let requestAPI = await API.getAction(
      "Appointments/Details?Id=" + props.match.params.id
    );

    if (requestAPI.data.status === "ok") {
      setModel(requestAPI.data.response);
      //  toggle();
    }
  };

  useEffect(() => {
    let theUser = parsedUser();

    if (!theUser) {
      window.location = Routes.SignIn.path;
    }
    request();
  }, []);
  return (
    <div className="row mx-0">
      <div className="col-md-8 col-12 mt-4 mx-auto">
        <div className="card shadow-lg">
          <div className="card-header p-1 px-2">
            <Row className="mx-0">
              <Col xs={8}>
                <h3>{model.name}</h3>
              </Col>
              <Col xs={4} className="text-right">
                <button className="btn  btn-sm">
                  <i className="fa fa-edit"></i>
                </button>
              </Col>
            </Row>
          </div>
          <div className="card-body">
            <Row>
              {props.unified === true ? (
                <Col xs={6}>
                  <label>Organización</label>
                  <br />
                  <label className="text-muted">
                    {/* {model.organization.name} */}
                  </label>
                </Col>
              ) : null}
              <Col xs={6}>
                <small>Proyecto</small>
                <br />
                {/* <label>{model.project.name}</label> */}
              </Col>

              <Col xs={12} md={6} className="mt-2">
                <small>Fecha</small>
                <div className="row mx-0">
                  <div className="col-7 mx-0 px-0">
                    {moment(model.date).format("YYYY-MM-DD h:mm a")}
                    {model.endDate
                      ? moment(model.endDate).format(" - h:mm a")
                      : moment(model.date).format(" - h:mm a")}
                  </div>
                </div>
              </Col>

              <Col xs={12} className="mt-2">
                <small>Asignado a</small>
                <br />
                <label>
                  {model.assignedTo &&
                    `${model.assignedTo.firstName} ${model.assignedTo.lastName}`}
                </label>
                {/* <AsyncTypeahead
                  id="basic-typeahead-single"
                  labelKey="label"
                  isLoading={state.UI.isLoading}
                  onChange={(e) => {
                    setSingleSelections(e);
                    if (e[0] !== undefined) {
                      formik.setFieldValue("assignedToId", e[0].id);
                    }
                  }}
                  options={options}
                  minLength={2}
                  onSearch={handleSearchUsers}
                  placeholder="Selecciona un usuario"
                  selected={singleSelections}
                />

                {formik.errors.assignedToId ? (
                  <div className="invalid text-sm">
                    {formik.errors.assignedToId}
                  </div>
                ) : null} */}
              </Col>
              {model.invitees ? (
                <Col xs={12} className="mt-2">
                  <small>Invitados</small>
                  <br />
                  {model.invitees.split(",").map((item, index) => {
                    return (
                      <a
                        href={"mailto:" + item}
                        className="btn btn-sm btn-primary me-2"
                        key={index}
                      >
                        {item}
                      </a>
                    );
                  })}
                </Col>
              ) : null}
              {model.location ? (
                <Col xs={12} className="mt-2">
                  <small>Ubicación</small>
                  <br />
                  <a
                    target="_blank"
                    href={model.location}
                    className="btn btn-sm btn-primary"
                  >
                    {model.location}
                  </a>
                </Col>
              ) : null}
              {/* RECURRENT TASK */}
              <Col xs={12} className="mt-4">
                <small htmlFor="isRecurrent">
                  Es una actividad recurrente?
                </small>
              </Col>

              <Col xs={12} className="mt-2">
                <small>Notas</small>
                <br />
                <p>{model.notes}</p>
              </Col>
              {model.minute ? (
                <Col xs={12} className="mt-2">
                  <small>Minuta</small>
                  <br />
                  <p>{model.minute}</p>
                </Col>
              ) : null}
            </Row>
          </div>
        </div>
        <p className="text-center mt-4">
          <h4>
            Virtual&nbsp;<small>Office</small>
          </h4>
        </p>
      </div>
    </div>
  );
};

export default OutsideActivityDetails;
