import React, { useEffect, useState } from 'react';

import { Modal, Button, Col, Row, Form } from '@themesberg/react-bootstrap';

import * as yup from 'yup';
import { Formik, useFormik } from 'formik';

import API from '../../common/API';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { parsedUser } from '../../common/GetCurrentUser';

//Validation
const validationSchema = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required(),
    organizationRoleId: yup.string().required()
    //  phone: yup.string().required(),

});
//Component
const AddEditUser = props => {
    const [model, setModel] = useState(props.model);
    const [state, setState] = useState({

        UI: {
            isLoading: false
        },
        Roles: []

    })
    useEffect(() => {
        console.log("the props", props)
        setModel({
            ...model, 
            password: ""
        });
        requestRoles();
    }, [])

    const user = parsedUser();
    const requestRoles = async () => {
        let request = await API.getAction("Organization/Roles?OrganizationId=" + user.OrganizationId);
        if (request.data.status === "ok") {

            let copy =state
            let records = []
            request.data.response.forEach(item => {
                records.push(item);
            })

            setState({
                ...state,
                Roles: records
            })
            

        }
    }
    const handleSubmit = async obj => {
        try {
            
            console.log("values to submit", obj);
            let path = "Users/AddEditUser?refer="+window.location.protocol+"//"+window.location.hostname;
            obj.currentUserId = user.ID;
            if (obj.id.length === 0) {
                obj.id = uuidv4();
                obj.created = moment().format("YYYY-MM-DDTHH:mm:ss")
           
            }
            setState({
                ...state,
                UI:{
                    ...state.UI,
                    isLoading: !state.UI.isLoading
                }
            })
            
            let request = await API.postAction(path, obj);
            setState({
                ...state,
                UI:{
                    ...state.UI,
                    isLoading: !state.UI.isLoading
                }
            })
            
            if (request.status === 200) {
                props.toggle(request.data, true);
            }
        } catch (ex) {
            console.error(ex);
            setState({
                ...state,
                UI:{
                    ...state.UI,
                    isLoading: false
                }
            })
            
        }

    };

    const formik = useFormik({
        initialValues: model ,
        validationSchema,
        onSubmit: handleSubmit,
    });


    return (
        <Modal show={props.show} onHide={props.toggle} size='md'>
            <Modal.Header closeButton>
                <Modal.Title>Usuario</Modal.Title>
            </Modal.Header>

            <Form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <Row>
                        <Col xs={6}>
                            <Form.Label>Nombre</Form.Label>
                            <input type="text" value={formik.values.firstName} 
                            name="firstName"
                            onChange={formik.handleChange}
                            className="form-control"
                            />
                            
                            {formik.errors.firstName ? <div className="invalid text-sm">{formik.errors.firstName}</div> : null}

                        </Col>
                        <Col xs={6}>
                            <Form.Label>Apellido</Form.Label>
                            <Form.Control type="text"
                                name="lastName"
                                value={formik.values.lastName} onChange={formik.handleChange} />
                            {formik.errors.lastName ? <div className="invalid text-sm">{formik.errors.lastName}</div> : null}

                        </Col>

                        <Col xs={6} className="mt-2">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email"
                                name="email"
                                value={formik.values.email} onChange={formik.handleChange} />
                            {formik.errors.email ? <div className="invalid text-sm">{formik.errors.email}</div> : null}

                        </Col>
                        <Col xs={6} className="mt-2">
                            <Form.Label>Teléfono</Form.Label>
                            <Form.Control type="text"
                                name="phone"
                                value={formik.values.phone} onChange={formik.handleChange} />
                            {formik.errors.phone ? <div className="invalid text-sm">{formik.errors.phone}</div> : null}

                        </Col>
                        <Col xs={12} className="mt-2">
                            <Form.Label>Rol</Form.Label>
                            <select className='form-control'
                                value={formik.values.organizationRoleId}
                                onChange={e => {

                                    formik.setFieldValue("organizationRoleId", e.target.value);
                                }}>
                                <option value="">-</option>
                                {state.Roles && state.Roles.map((item, index) => {
                                    return (<option value={item.id} key={index}>{item.name}</option>);
                                })}
                            </select>
                            {formik.errors.organizationRoleId ? <div className="invalid text-sm">{formik.errors.organizationRoleId}</div> : null}

                        </Col>
                        <Col xs={12} className="mt-2">
                            <Form.Label>Clave</Form.Label>
                            <Form.Control type="text"
                                name="password"
                                value={formik.values.password} onChange={formik.handleChange} />
                            {formik.errors.password ? <div className="invalid text-sm">{formik.errors.password}</div> : null}

                        </Col>


                    </Row>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" type="submit" className="btn-sm"
                        disabled={state.UI.isLoading}
                    >
                        Guardar
                    </Button>
                </Modal.Footer>
            </Form>


        </Modal>
    )
}

export default AddEditUser;