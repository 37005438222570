import moment from "moment";
import React, { useEffect, useState } from "react";
import { NavItem } from "react-bootstrap";
import API from "../../common/API";
import { parsedUser } from "../../common/GetCurrentUser";
import { Routes } from "../../routes";
import { BarChart, SalesValueChartphone } from "../Charts";
import AddEditActivity from "../Common/Agenda/AddEditActivity";
import OverviewTaskByAssignee from "./OverviewTaskByAssignee";
import OverviewTaskByProject from "./OverviewTaskByProject";

const WeeklyResume = props => {
    const [state, setState] = useState({
        completedTasks: 0,
        pendingTasks: 0,
        totalTasks: 0,
        overdueTasks: 0
    })

    const [showAddEdit, setShowAddEdit] = useState(false);

    const [activityMode, setActivityMode] = useState("add");
    const [ActivitySelected, setActivitySelected] = useState({
        name: "",
        notes: "",

        status: 0,
        createdId: null,
        updatedId: "",
        invites: "",
        location: "",
        date: moment(moment().format("YYYY-MM-DD")).add(8, "hours").format(),
        created: moment().format(),
        id: "",
        organizationId: null,
        appointmentTypeId: null,
        assignedToId: null,
        projectId: props.projectId,
        type: 1,
        //  duration: duration

    });
    const toggleAddEdit = async (opt, mode, reload) => {
        setActivityMode(mode);
        let theUser = parsedUser();
        if (opt === null) {
            opt = {
                name: "",
                notes: "",

                status: 0,
                createdId: theUser.ID,
                updatedId: "",
                invites: "",
                customerId: null,
                location: "",
                date: moment().format("YYYY-MM-DD HH:mm"),
                created: moment(new Date()).format("YYYY-MM-DDTHH:mm:SSZ"),
                id: "",
                organizationId: theUser.OrganizationId,
                appointmentTypeId: null,
                assignedToId: theUser.ID,
                duration: 30,
                projectId: props.projectId,
                type: 2
            }
        }
        setActivitySelected(opt);
        if (mode === "edit") {
            let ExistingTask = await API.getAction("appointments/details?id=" + opt.id);
            if (ExistingTask.data.status === "ok") {
                opt = ExistingTask.data.response;
                setActivitySelected(opt);
            }
        }




        setShowAddEdit(!showAddEdit);

        if (reload === true) {
            await request();
        }

    }
    const request = async () => {
        let theUser = parsedUser();
        if (theUser.OrganizationId === undefined) {
            window.location = Routes.SignIn.path;
        }
        let requested = await API.getAction("appointments/WeeklyMinutes?organizationid=");
        if (requested.data.status === "ok") {
            setState(requested.data.response);
        }
    }
    useEffect(() => {
        let theUser = parsedUser();
        console.log(theUser)
        if (!theUser) {
            window.location = Routes.SignIn.path;
            return;
        }
        if (window.location.toString().includes("http:")) {
            // window.location= window.location.replace("http:", "https:");
        }

        request();

    }, []);

    const pushNotificationPermissionsRequest = async () => {
        let response = await Notification.requestPermission();
        if (response === "granted") {
            new Notification("Hello", {
                body: "This is more text",


            });
        } else {
            alert(response)
        }
    }

    if (!state || !Array.isArray(state.projects)) {
        return (<></>)
    }
    return (
        <>
            {showAddEdit ? <AddEditActivity model={ActivitySelected} toggle={toggleAddEdit} show={showAddEdit} mode={activityMode} /> : null}
            <div className="row mt-4">
                <div className="col-12">
                    <span className="display-3">
                        Semana - {moment(state.from).format("MMM DD")} - {moment(state.end).format("MMM DD")}
                    </span>
                </div>
                <div className="col-2 mb-2">
                    <div className="card shadow-sm mt-2">
                        <div className="card-header">
                            Proyectos
                        </div>
                        <div className="card-body p-1">
                            <ol className="list-group">
                                {state && state.projects.map((item, index) => {
                                    return (<li key={index}

                                        className="list-group-item clickable text-truncate">
                                        <a href={"#" + item.id}>    {item.name}
                                        </a>
                                    </li>)
                                })}
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="col  mb-2" style={{ overflowY: "scroll", height: "77vh" }}>
                    {state.projects && state.projects.map((itemProj, index) => {
                        return (<div key={index} id={itemProj.id} className="mb-4">
                            <label className="display-4">{itemProj.name}</label><br />
                            {itemProj.activities && itemProj.activities.map((item, index2) => {
                                return (<div className="card shadow-sm mt-2" key={index2}
                                    
                                >
                                    <div className="card-body p-1 px-2">
                                        <div className="row">
                                            <div className="col clickable" onClick={() => { toggleAddEdit(item) }} >
                                                <i className="fa fa-circle me-2"
                                                style={{ color: item.project && item.project.color ? item.project.color : "#fff" }}></i>
                                                {item.date ? <small title={moment(item.date).format()}><b>{moment(item.date).format("MMM DD")} - </b> {moment(item.date).format("hh:mm a")}</small> : ""}
                                                <br />
                                                {item.name} <small>{moment(item.date).fromNow()}</small>
                                            </div>
                                            <div className="col-auto ml-auto">
                                                {item.minute ?
                                                    <i className="fa fa-comment"></i> : null}
                                            </div>
                                            {item.minute ?
                                                <div className="col-12">
                                                    <div class="alert alert-info">
                                                        <label className="display-5">Minuta</label>
                                                        <div
                                                            dangerouslySetInnerHTML={{ __html: item.minute }}
                                                        />
                                                    </div>
                                                </div> : null}
                                        </div>
                                    </div>
                                </div>);
                            })}
                        </div>)
                    })}
                </div>




            </div>
        </>)
}

export default WeeklyResume;