import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "./routes";

// pages

import CalendarOverview from "./components/dashboard/CalendarOverview";

import Settings from "./components/Settings/Settings";
// components
import Sidebar from "./components/Common/Sidebar";
import Navbar from "./components/Common/Navbar";
import Footer from "./components/Common/Footer";
import Preloader from "./components/Preloader";

import Profile from "./components/Profile/Profile";
import Login from "./components/Profile/Login";
import Register from "./components/Profile/Register";
import ForgotPassword from "./components/Profile/ForgotPassword";
import Meeting from "./components/Meeting/Meeting";

import SelectOrganization from "./components/Organizations/SelectOrganization";
import Users from "./components/Users/Users";
import AddEditOrganization from "./components/Organizations/AddEditOrganization";
import ActivityDetails from "./components/Common/Agenda/ActivityDetails";

import Files from "./components/files";
import Files2 from "./components/files2";
import ProjectDashboard from "./components/Projects/ProjectDashboard";
import ActivityLog from "./components/ActivityLog/ActivityLog";
import Chat from "./components/Chat/EntireScreenChat";
import DashboardOverview from "./components/dashboard/DashboardOverview";
import CalendarUnifiedOverview from "./components/dashboard/CalendarUnifiedOverview";
import Search from "./components/Common/Search";


import { parsedUser } from "./common/GetCurrentUser";
import { socketComponent, SocketContext } from "./Context/socket";
import WeeklyResume from "./components/dashboard/WeeklyResume";
import Summary from "./components/dashboard/Summary";
import OutsideActivityDetails from "./components/Common/Agenda/OutsideActivityDetails";
import ContactList from "./components/Contacts/ContactList";
import Notes from "./components/Notes/Notes";
import External from "./components/files2/External";

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem("settingsVisible") === "false" ? false : true;
  };

  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} />
          {/* <OmniBar /> */}
          <Sidebar repsly={rest.repsly} />

          <main className="content ">
            <div className="row mx-0">
              <div className="contentContainer">
                <Navbar {...props} />
                <Component {...props} />

                {/* <Footer
                  toggleSettings={toggleSettings}
                  showSettings={showSettings}
                /> */}
              </div>
            </div>
          </main>
        </>
      )}
    />
  );
};
const theUser = parsedUser();
export default () => {
  const socket = socketComponent(() => {

    if (theUser) {
      socket.invoke("Online", theUser.ID);
    }
  });
  return (
    <SocketContext.Provider value={socket}>
      <Switch>
        {/* pages */}
        <Route path={Routes.FileExternal.path} component={External} />
        <RouteWithSidebar
          exact
          path={Routes.Dashboard.path}
          component={DashboardOverview}
        />

        <RouteWithSidebar exact path={Routes.Chat.path} component={Chat} />
        <RouteWithSidebar
          exact
          path={Routes.Calendar.path}
          component={CalendarOverview}
        />
        <RouteWithSidebar
          exact
          path={Routes.CalendarUnified.path}
          component={CalendarUnifiedOverview}
        />
        <RouteWithSidebar
          exact
          path={Routes.Summary.path}
          component={Summary}
        />
        <RouteWithSidebar
          exact
          path={Routes.Settings.path}
          component={Settings}
        />
        <RouteWithSidebar path={Routes.Search.path} component={Search} />
        <RouteWithSidebar exact path={Routes.Users.path} component={Users} />
        <RouteWithSidebar
          exact
          path={Routes.Contacts.path}
          component={ContactList}
        />

        <RouteWithSidebar exact path={Routes.Files.path} component={Files} />
        <RouteWithSidebar
          exact
          path={Routes.Profile.path}
          component={Profile}
        />

        <RouteWithSidebar
          exact
          path={Routes.Files2.path}
          component={Files2}
        />

        <RouteWithSidebar
          exact
          path={Routes.ActivityLog.path}
          component={ActivityLog}
        />
        <Route path={Routes.SignIn.path} component={Login} />
        <Route path={Routes.Register.path} component={Register} />
        <Route
          path={Routes.SelectOrganization.path}
          component={SelectOrganization}
        />
        <Route
          path={Routes.AddEditOrganization.path}
          component={AddEditOrganization}
        />

        <Route path={Routes.ForgotPassword.path} component={ForgotPassword} />

        <Route path={Routes.Meeting.path} component={Meeting} />

        <RouteWithSidebar
          exact
          path={Routes.ProjectDashboard.path}
          component={ProjectDashboard}
        />


        <RouteWithSidebar
          exact
          path={Routes.WeeklyResume.path}
          component={WeeklyResume}
        />
        <Route
          exact
          path={Routes.AppointmentDetails.path}
          component={ActivityDetails}
        />
        <Route
          exact
          path={Routes.Notes.path}
          component={Notes}
        />
        <Route
          path={Routes.OutsideApointment.path}
          component={OutsideActivityDetails}
        />
        <Redirect to={Routes.NotFound.path} />
      </Switch>
    </SocketContext.Provider>
  );
};
