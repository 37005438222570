import React, { useEffect } from "react";

import { Modal, Button, Col, Row, Form } from "@themesberg/react-bootstrap";
import { useState } from "@hookstate/core";
import * as yup from "yup";
import { Formik, useFormik } from "formik";

import { onlyHTMLTags } from "../../common/helpers";
import API from "../../common/API";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { parsedUser } from "../../common/GetCurrentUser";

//Validation
const validationSchema = yup.object({
  name: yup.string().required("required"),
  lastName: yup.string().required(),
  phone: yup.string().required(),
});
//Component
const AddEditContact = (props) => {
  const state = useState({
    UI: {
      isLoading: false,
    },
  });
  useEffect(() => {
    console.log("props received on contact", props);
  }, []);

  const handleSubmit = async (obj) => {
    console.log("values to submit", obj);
    let u = parsedUser();
    if (obj.id.length === 0) {
      obj.id = uuidv4();
      obj.created = moment().format("YYYY-MM-DD HH:mm:ss");
    }
    obj.organizationId = u.OrganizationId;
    state.UI.isLoading.set(!state.UI.isLoading.value);

    debugger;
    let request = await API.postAction(
      "Contact/AddEdit?currentUserId=" + u.ID,
      obj
    );
    state.UI.isLoading.set(!state.UI.isLoading.value);
    if (request.status === 200) {
      props.toggle(request.data, true);
    }
  };

  const formik = useFormik({
    initialValues: props.model
      ? JSON.parse(JSON.stringify(props.model))
      : {
          name: "",
          lastName: "",
          phone: "",
          position: "",
          email: "",
          note: "",

          created: new Date(),
          id: "",
          OrganizationId: "",
          employeeId: "",
          customerId: "",
        },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Modal show={props.show} onHide={props.toggle} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Contacto</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={6}>
              <Form.Label>Primer nombre</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.errors.name ? (
                <div className="invalid text-sm">{formik.errors.name}</div>
              ) : null}
            </Col>
            <Col xs={6}>
              <Form.Label>Apellido</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
              />
              {formik.errors.lastName ? (
                <div className="invalid text-sm">{formik.errors.lastName}</div>
              ) : null}
            </Col>

            <Col xs={12}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.errors.email ? (
                <div className="invalid text-sm">{formik.errors.email}</div>
              ) : null}
            </Col>
            <Col xs={6}>
              <Form.Label>Telefono</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
              />
              {formik.errors.phone ? (
                <div className="invalid text-sm">{formik.errors.phone}</div>
              ) : null}
            </Col>
            <Col xs={6}>
              <Form.Label>Compañia</Form.Label>
              <Form.Control
                type="text"
                name="company"
                value={formik.values.company}
                onChange={formik.handleChange}
              />
              {formik.errors.company ? (
                <div className="invalid text-sm">{formik.errors.company}</div>
              ) : null}
            </Col>
            <Col xs={12}>
              <Form.Label>Notas</Form.Label>

              <textarea
                row={5}
                className="form-control"
                value={formik.values.note}
                name="note"
                onChange={(e) => {
                  let onlyTags = onlyHTMLTags(e);
                  formik.setFieldValue("note", onlyTags ? "" : e);
                }}
              />
              {formik.errors.note ? (
                <div className="invalid text-sm">{formik.errors.note}</div>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.toggle} className="btn-sm">
            Cerrar
          </Button>
          <Button
            variant="primary"
            type="submit"
            className="btn-sm"
            disabled={state.UI.isLoading.value}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddEditContact;
