import React, { useEffect, useState } from "react";

import {
  DocumentEditorContainerComponent,
  Toolbar,
} from "@syncfusion/ej2-react-documenteditor";
import Settings from "../../common/Settings";

import { Col, Container, Modal, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import moment from "moment";
import { ProperBasePath } from "./helper";

DocumentEditorContainerComponent.Inject(Toolbar);

const DocumentContainer = (props) => {
  const { id } = useParams();

  let documentEditorRef = null;
  const [fileSeleted, setFileSelected] = useState(null);
  const [fileName, setfileName] = useState(
    !id || !fileSeleted ? "Untitled " + moment().format() : setFileSelected.name
  );
  const requestFileRemote = async () => {};
  useEffect(() => {
    //Now that we have the file, let's revise what kind of type it is
    if (fileSeleted != null) {
    }
  }, [fileSeleted]);

  useEffect(() => {
    //This is to be used to request for the remote file
    if (id) {
      requestFileRemote();
    }
  }, [id]);

  useEffect(() => {
    //Now let's try to get the file and open

    setfileName(props.file.name);

    setFileSelected(props.file);
  }, [props.file]);

  const loadTheFile = () => {
    let http = new XMLHttpRequest();
    let fullPath = `${ProperBasePath()}files/${props.file.path}`;
    //add your url in which you want to open document inside the ""
    let content = { fileUrl: fullPath };
    let baseurl = ProperBasePath() + "api/file/OpenWord";
    http.open("POST", baseurl, true);
    http.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    http.onreadystatechange = () => {
      if (http.readyState === 4) {
        if (http.status === 200 || http.status === 304) {
          //open the SFDT text in Document Editor
          if (!documentEditorRef) {
            return;
          }
          documentEditorRef.documentEditor.open(http.responseText);
        }
      }
    };
    http.send(JSON.stringify(content));
  };
  const loadUI = () => {
    return (
      <Container fluid>
        <Row>
          <Col xs={12} style={{ height: "78vh" }}>
            <DocumentEditorContainerComponent
              ref={(scope) => {
                documentEditorRef = scope;
                loadTheFile();
              }}
              height={"100%"}
              serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
            />
          </Col>
        </Row>
      </Container>
    );
  };
  if (props.modal) {
    return (
      <Modal show={true} onHide={props.toggle} size="xl" className="full">
        <Modal.Body>{loadUI()}</Modal.Body>
      </Modal>
    );
  }
  return <>{loadUI()}</>;
};

export default DocumentContainer;
