import moment from "moment";
import React, { useEffect, useState } from "react";
import AddUsersToChat from "./AddUsersToChat";
import { parsedUser } from "../../common/GetCurrentUser";

const ConversationUI = props => {
    const [text, setText] = useState("");
    const maxMessage = 50;
    const { socket, selectedConversation } = props;
    const [messages, setMessages] = useState([]);
    const user = parsedUser();
    const OnGetMessages = (items, total) => {

        console.log("OnGetMessages", items, total)
        setMessages(items);
        scrollBottom();

        socket.invoke("MarkAsRead", items[0].id, user.ID)

    };


    const OnSendMessage = (items, total) => {
        socket.invoke("GetMessages", props.selectedConversation.conversationId, 0, maxMessage);


    };



    socket.on("OnJoinRoom", (conversationid, conversation) => {
        console.log("OnJoinRoom", new Date());
        // setSelectedConversation(conversation);
        //  socket.invoke("GetMessages", conversationid, 0, 50);
    });

    //For the current user
    // socket.on("OnSendMessage", (newMessage, currentUser) => {
    //     console.log("OnSendMessage", new Date());
    //     let messagesNew = messages;
    //     messagesNew.push(newMessage);
    //     setMessages(messagesNew);
    //     scrollBottom();
    //     //debugger;
    //     //socket.invoke("GetMessages",props.selectedConversation.conversationId, 0, 50);
    // });

    //for all the other users
    socket.on("OnReceiveMessages", (items, total) => {

        console.log("OnReceiveMessages", items, total)

        socket.invoke("GetMessages", props.selectedConversation.conversationId, 0, maxMessage);
    });


    const sendMessageHandler = async e => {

        if (e.key === "Enter" || e.keyCode === 13) {

            socket.invoke("SendMessage", selectedConversation.conversationId, text);
            setTimeout(() => {

                setText("");
            }, 200)
        }
    }
    useEffect(() => {
        //  socket.off("OnGetMessages", OnGetMessages);
        //socket.on("OnGetMessages", OnGetMessages);
        console.log("selected conversation update", props.selectedConversation)
        socket.invoke("GetMessages", props.selectedConversation.conversationId, 0, maxMessage);
    }, [props.selectedConversation]);

    useEffect(() => {
        socket.on("OnGetMessages", OnGetMessages);
        socket.on("OnSendMessage", OnSendMessage);
        return () => {
            console.log("unmounting the conversation ui")
            socket.off("OnGetMessages", OnGetMessages);
            socket.off("OnSendMessage", OnSendMessage);
        }
    }, []);

    const scrollBottom = () => {
        setTimeout(() => {
            const element = document.getElementsByClassName("messages")[0];
            if (element) {

                console.log("Going to scroll on messages height:", element.scrollHeight);
                element.scrollTop = element.scrollHeight;
            }
        }, 1000)
    }
    const [showAddUserstoChat, setShowAddUserstoChat] = useState(false);
    const toggleAddUsers = () => {
        setShowAddUserstoChat(!showAddUserstoChat);
    }

    const [showDetails, setShowDetails] = useState(false);
    const toggleDetails = () => {
        setShowDetails(!showDetails);
    }

    return (<div className="conversationContainer">
        {
            console.log(selectedConversation)
        }
        {showAddUserstoChat ?
            <AddUsersToChat conversation={selectedConversation} toggle={toggleAddUsers} show={showAddUserstoChat} /> : null}
        <div className="row conversationHeader">
            {/*    <div className="col text-truncate">


                <div className="clickable px-2"
                    onClick={() => {
                        toggleDetails();
                    }}>
                    <span>Group:&nbsp;</span><b className="d-inline " title={selectedConversation && selectedConversation.name}> {selectedConversation && selectedConversation.name}</b>
                    <small className="text-muted">
                        <br />
                        {selectedConversation.lastMessage && selectedConversation.lastMessage.length > 0 ?
                            <>

                                <small className="font-weight-light ">{moment(selectedConversation.lastMessageTime).fromNow()}</small>
                            </> :
                            <small>-</small>}
                    </small>
                </div>
            </div> */}
            <div className="col text-right">

                {/* 
                <button className="btn btn-sm" title="Llamar" onClick={() => {
                    props.toggleCall(selectedConversation, true)
                }}>
                    <i className="fas fa-phone"></i>
                </button> */}

                {/* <button className="btn" title="Mensaje grupal"
                    onClick={() => {
                        toggleAddUsers();
                    }}>
                    <i className="fas fa-user-plus"></i>
                </button> */}

            </div>
        </div>
        <div className="messages ">
            {messages && messages.length > 0 ?
                messages.map((item, index) => {
                    return (<div className="row mx-0 mb-2 clickable" key={index}>
                        <div className="col-12 text-wrap">
                            {item.senderAccount ?
                                <small><b>{item.senderAccount.firstName + " " + (item.senderAccount.lastName ? item.senderAccount.lastName.substring(0, 1) + "." : "")}</b></small>
                                : null}
                            <small>
                                &nbsp;{moment(item.date).fromNow()}</small>

                            <br />
                            {item.message}
                        </div>
                    </div>)
                }) : null}
        </div>
        <div className="messageContainer">
            <textarea className="form-control" id={"textMessageContainer" + new Date().getTime()} value={text}
                autoComplete="false"
                onChange={e => {
                    setText(e.target.value)
                }}
                onKeyUp={sendMessageHandler}
            ></textarea>
        </div>
    </div >)
}

export default ConversationUI;