
import React, { useEffect, useRef, useState } from 'react';
import AddEditFolder from './AddEditFolder';
import { v4 as uuidv4 } from 'uuid';
import swal from 'sweetalert';

import API from '../../common/API';
import { DropdownButton, Dropdown } from '@themesberg/react-bootstrap';

import { copyToClipboard, openInNewTab, typeIconEquivalent } from './helper';
import { parseJwt, prettyFileSize } from '../../common/helpers';
import GetCurrentUser, { parsedUser } from '../../common/GetCurrentUser';
import moment from 'moment';
import AddeditLink from './AddEditLink';
import ListFolders from './listFolders';

import NOCONTENT from '../../assets/img/illustrations/no-files.png';
import AddEditFile from './AddEditFile';

function Files(props) {
    const [state, setState] = useState({
        Files: [],
        UI: {
            showAddEditFolder: false,
            showAddEditFile: false,
            ShowPickFolder: false,
        },
        CurrentUser: {},
        ChoosenFolder: {},
        SelectedFile: null,
        showViewerModal: false,
        previewFile: null,
        ChoosenLink: null,
        ChoosenFile: null,
        SelectedFiles: [],
        showVisibleByEvents: false,
        Events: []
    });

    const [selectedFolder, setSelectedFolder] = useState("/");
    const [selectedFile, setSelectedFile] = useState(null)
    const [CurrentFolderPath, setCurrentFolderPath] = useState([]);
    const [showAddEditFolder, setShowAddEditFolder] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const requestFolderRemote = async () => {
        try {

            let selectedUser = parseJwt(GetCurrentUser());
            setTheUser(parseJwt(GetCurrentUser()));
            setState({
                ...state,
                SelectedFiles: [],


            })
            let query = "";
            console.log("the user", theUser, selectedUser);
            if (selectedUser.OrganizationId) {
                query += "OrganizationId=" + selectedUser.OrganizationId;
            }
            if (props.projectId) {
                query += "&projectId=" + props.projectId;
            }
            if (selectedUser.ID) {
                query += "&UserID=" + selectedUser.ID;
            }
            console.log("state selecte folder", selectedFolder);
            if (selectedFolder) {
                query += (selectedFolder !== "/" ? "&FolderId=" + selectedFolder : "");
            }

            setIsLoading(!isLoading);
            const request = await API.getAction(
                "Files/List?" + query
            );

            let response = request.data;
            if (response.status === "ok") {
                setState({
                    ...state,
                    Files: response.response
                })
            }
        } catch (ex) {
            console.error(ex);
        }
        finally {

            setIsLoading(false);
        }
    }


    const [theUser, setTheUser] = useState(null);
    useEffect(() => {
        setTheUser(parseJwt(GetCurrentUser()));
        setCurrentFolderPath([{ id: "/", name: "/" }]);
        setSelectedFile(null);
        setSelectedFolder(null)
        setTimeout(() => {

            //    requestFolderRemote();

        }, 500)
    }, [props.projectId]);


    useEffect(() => {
        console.log("enter on selected folder change", selectedFolder);
        requestFolderRemote();
    }, [selectedFolder, props.projectId])
    //Depending on the file type do some actions
    const openItem = async item => {
        //First check the type
        switch (item.type) {
            case "folder":
                await enterFolder(item);
                break;
            case "file":
            case "link":
                openFile(item);
                break;
            default:
                openFile(item);
                break;
        }
    }

    const downloadItem = item => {
        window.open("https://voapi2.shearly.com/files/" + item.path, item.name, "_blank", "width=1280,height=840");
    }
    const enterFolder = async item => {
        setSelectedFile(null);
        setSelectedFolder(item.id);

        let copy = CurrentFolderPath;
        var exist = copy.some(x => x.id === item.id);
        if (exist) {
            //If exist we must go over the array and create a new one until this record
            let newArray = [];
            for (var i = 0; i < copy.length; i++) {
                newArray.push(copy[i]);
                if (copy[i].id === item.id) {
                    break;
                }
            }

            copy = newArray;
        } else {
            copy.push({ id: item.id, name: item.name });
        }
        setCurrentFolderPath(copy);

        // await requestFolderRemote();
    }
    const openFile = (item) => {
        setState({
            ...state,
            SelectedFile: item
        })
        openInNewTab(item);
    }

    const deleteFile = item => {

        if (item.type === "folder") {
            deleteFolderRemote(item);
        } else {
            swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover this!",
                icon: "warning",
                buttons: true,
                dangerMode: true,

            }).then(async willDelete => {
                if (willDelete) {
                    setIsLoading(!isLoading);
                    const request = await API.getAction("Files/DeleteFile?Id=" + item.id + "&userId=" + theUser.ID);

                    setIsLoading(!isLoading);
                    let response = request.data;
                    if (response.status === "ok") {

                        await requestFolderRemote();
                    }
                    //  })

                }
            });
        }
    }

    const requestFilesUI = () => {
        let files = [];
        try {
            let items = state.Files;
            items.forEach((item, index) => {
                let newFile =
                    <div className="row filesContainer px-0 showVisibility" key={"file-" + index}>
                        <div className="col-auto text-center px-0">
                            <button className="btn btn-sm" onClick={() => MarkAsSelected(item)}>
                                {state.SelectedFiles.includes(item.id) ?
                                    <i className="far fa-dot-circle checked text-secondary fa-sm"></i> :
                                    <i className="far fa-circle uncheck  text-secondary fa-sm"></i>
                                }
                            </button>
                        </div>

                        <div className="col-11 col-md-5 text-truncate">
                            <i className={typeIconEquivalent(item)} />
                            <span className="title clickable mx-2 mt-1 d-inline " onClick={async () => {
                                setState({
                                    ...state,
                                    SelectedFile: item
                                })

                                await openItem(item);
                            }}
                                onDoubleClick={async () => { await openItem(item) }}>
                                {item.name}

                            </span>

                            {item.description ? <small className="text-muted"><br />{item.description}</small> : null}
                        </div>

                        <div className="col-auto col-md-1 ms-4">
                            <small>{item.size ?
                                prettyFileSize(item.size, true)
                                : "-"}
                            </small>
                        </div>
                        <div className="col-auto col-md-2  text-truncate ">
                            <small className="text-muted" title={item.updatedBy ? item.updatedBy : item.createdBy ? item.createdBy : ""}>
                                {moment(item.created).fromNow()}</small><br />
                            <small className="text-muted">
                                {item.updatedBy ? item.updatedBy : item.createdBy ? item.createdBy : "-"}
                            </small>
                        </div>
                        <div className="col-auto col-md-2 text-right ms-auto">

                            {item.type !== "folder" ?
                                <button className='btn btn-sm canBeHidden Invert ' onClick={() => downloadItem(item)}>
                                    <i className="fas fa-download"></i>
                                </button>
                                : null}
                            <Dropdown className='d-inline'>
                                <Dropdown.Toggle variant="link" id="dropdown-basic">
                                    <i className="fa fa-ellipsis-v" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>

                                    <Dropdown.Item onClick={() => item.type === "folder" ? toggleAddEditFolder(item) :
                                        item.type === "link" ?
                                            toggleAddEditLink(item) : toggleAddEditFile(item)}>Editar</Dropdown.Item>
                                    {item.type !== "folder" ? <Dropdown.Item onClick={() => downloadItem(item)}>Descargar</Dropdown.Item> : null}
                                    <Dropdown.Divider />

                                    <Dropdown.Item onClick={() => {
                                        setState({
                                            ...state,
                                            SelectedFiles: []
                                        });
                                        MarkAsSelected(item);
                                        toggleListFolder();
                                    }
                                    }>Mover</Dropdown.Item>
                                    <Dropdown.Item onClick={() => deleteFile(item)}>Eliminar</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            {/* ACTIONS */}
                        </div>
                        <div className='col-12'>
                            <hr className='my-1' />
                        </div>

                    </div>;
                files.push(newFile);
            });
        } catch (ex) {
            console.error(ex);
        }

        return (files);
    };

    const SelectAll = () => {
        let objs = state.Files;

        let selectedFields = [];
        if (state.SelectedFiles.length === objs.length) {
            //Un selected all 
            setState({
                ...state,
                SelectedFiles: []
            })
        } else if (state.SelectedFiles.length === 0) {
            //Select all

            objs.forEach(record => {
                selectedFields.push(record.id);
            });
            setState({
                ...state,
                SelectedFiles: selectedFields
            })
        } else {
            //un select all 
            setState({
                ...state,
                SelectedFiles: []
            })
        }

    }
    const MarkAsSelected = (record, onlyThisFile) => {
        let selectedFields = state.SelectedFiles;

        if (onlyThisFile !== undefined && onlyThisFile === true) {
            selectedFields.push(record.id);
        } else {

            if (selectedFields.includes(record.id)) {
                selectedFields = selectedFields.filter(x => x !== record.id);//We are removing the existing file
            } else {
                selectedFields.push(record.id);
            }
        }
        setState({
            ...state,
            SelectedFiles: selectedFields
        })
    }


    const toggleAddEditFolder = async (obj, reload) => {

        if (obj !== null) {
            setState({
                ...state,
                ChoosenFolder: obj
            })
        }
        setShowAddEditFolder(!showAddEditFolder)

        if (reload) {
            await requestFolderRemote();
            //   RequestFilesRemote();
        }
    }

    const toggleListFolder = async (reload) => {
        setState({
            ...state,
            UI: {
                ...state.UI,
                ShowPickFolder: !state.UI.ShowPickFolder
            }

        })

        if (reload) {
            await requestFolderRemote();
        }
    }

    const [ChoosenLink, setChoosenLink] = useState(null);
    const [showAddEditLink, setshowAddEditLink] = useState(false);
    const toggleAddEditLink = async (choosen, reload) => {
        if (choosen !== null) {
            setChoosenLink(choosen);
        }

        setshowAddEditLink(!showAddEditLink)


        if (reload) {
            await requestFolderRemote();
        }
    }

    const toggleAddEditFile = async (choosen, reload) => {
        if (choosen !== null) {
            setState({
                ...state,
                ChoosenFile: choosen
            })
        }

        setState({
            ...state,
            UI: {
                ...state.UI,
                showAddEditFile: !state.UI.showAddEditFile
            }

        });
        if (reload) {
            await requestFolderRemote();
        }
    }


    const deleteFolderRemote = (item) => {


        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async willDelete => {
                if (willDelete) {
                    setIsLoading(!isLoading);

                    const request = await API.getAction("Files/DeleteFolder?Id=" + item.id + "&userId=" + theUser.ID);

                    setIsLoading(!isLoading);
                    let response = request.data;
                    if (response.status === "ok") {
                        await requestFolderRemote();
                    }
                    swal("has been deleted!", {
                        icon: "success",
                    });
                } else {
                    //  swal("Your imaginary file is safe!");
                }
            });

    }

    const AddAttachments = (e) => {
        console.log("FILES", e.target.files);
        var theFiles = Array.from(e.target.files);

        theFiles.forEach(async item => {
            let newItem = {
                id: uuidv4(),
                Name: item.name,
                Note: "",
                Path: item.name,
                Userid: theUser.id,
                Parentid: selectedFolder && (selectedFolder.length === 0 || selectedFolder === "/") ? null : selectedFolder,
                Type: "file",
                projectId: props.projectId,
                organizationId: theUser.OrganizationId
            };
            let query = {
                Id: theUser.ID,
                Data: newItem
            }



            const requestGeneral = await API.postAction(
                "Files/AddEditFileWithoutFile", query);

            if (requestGeneral.data.status === "ok") {
                var responseGeneral = requestGeneral.data.response;
                //Upload the actual file now

                let bodyData = new FormData();
                bodyData.append("path", item);
                setIsUploading(true);
                await API.postAction(`Files/UploadFile?id=${responseGeneral.id}`, bodyData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });
                setIsUploading(false);
            }
            setIsLoading(!isLoading);
            await requestFolderRemote();

        });
    };

    const GoBack = async () => {



        let current = CurrentFolderPath;
        let pop = current.pop();

        setState({
            ...state,
            SelectedFile: null,

        });
        setCurrentFolderPath(current);

        console.log("current value", current[current.length - 1], "pop ", pop, "current ", current);

        await enterFolder(current[current.length - 1]);
    }
    const newLocal = <button className="ms-2 btn btn-sm btn-danger mr-2 d-none" onClick={() => toggleListFolder()}>
        <i className='fa fa-trash' /> Eliminar seleccionados
    </button>;
    return (
        <div className=" ">
            {/*MODALS AREA */}

            {showAddEditFolder ?
                <AddEditFolder Model={state.ChoosenFolder} toggle={toggleAddEditFolder} show={showAddEditFolder} /> : null}

            {showAddEditLink ?
                <AddeditLink Model={ChoosenLink} toggle={toggleAddEditLink} show={showAddEditLink} /> : null}
            {state.UI.showAddEditFile ?
                <AddEditFile Model={state.ChoosenFile} toggle={toggleAddEditFile} show={state.showAddEditFile} /> : null}
            {state.UI.ShowPickFolder ?
                <ListFolders Model={state.ChoosenLink}
                    projectId={props.projectId} parentState={state}
                    toggle={toggleListFolder} show={state.ShowPickFolder} /> : null}



            {/*END MODALS AREA*/}

            {/*  NAVIGATION  */}
            <div className="row customToolbar mx-0 shadow-sm my-2">
                <div className="col-10 px-1">

                    {CurrentFolderPath.length > 0 ?
                        <nav aria-label="breadcrumb" className="mb-2">
                            <ol className="breadcrumb m-0 p-0">

                                {CurrentFolderPath.map(path => {
                                    console.log(path);
                                    return (path.id === "/" ?
                                        <li key={path.id} className="breadcrumb-item clickable" onClick={async () => await enterFolder(path)}><i className="fa fa-home "></i></li> :
                                        <li key={path.id} className="breadcrumb-item clickable " onClick={async () => await enterFolder(path)}>{path.name}</li>);

                                })}
                            </ol>
                        </nav>
                        : null
                    }
                </div>
                <div className="col-2  text-right">


                </div>

            </div>
            {/* END NAVIGATION */}

            {/*MAIN MENU */}
            <div className='row mainMenu rounded mx-0'>
                <div className='col-12 py-2'>
                    {CurrentFolderPath.length > 1 ?
                        <button key={"back"} className="btn" onClick={GoBack}>
                            <i className="fas fa-chevron-left"></i>
                        </button> : null}
                    <div className="btn-group">
                        <Dropdown>
                            <Dropdown.Toggle variant="link" id="dropdown-basic" className='btn-sm'>
                                <i className="fa fa-plus" /> Agregar
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => toggleAddEditFolder({
                                    id: uuidv4(),
                                    organizationId: theUser.OrganizationId,
                                    projectId: props.projectId,
                                    parentId: selectedFolder === "/" ? null : selectedFolder,

                                })}>Agregar Carpeta</Dropdown.Item>
                                <Dropdown.Divider />


                                <Dropdown.Item onClick={() => toggleAddEditLink({
                                    id: uuidv4(),
                                    organizationId: theUser.OrganizationId,
                                    projectId: props.projectId,
                                    parentId: selectedFolder === "/" ? null : selectedFolder,
                                    name: "",
                                    path: "",
                                    type: "link"
                                })}>Agregar Enlace</Dropdown.Item>
                                {/* <Dropdown.Item href="#/action-2">Agregar Documento</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Add Spreadsheet</Dropdown.Item> */}
                            </Dropdown.Menu>
                        </Dropdown>
                        <button className="btn btn-file" type="button">
                            <i className='fa fa-upload mx-2'></i>
                            Subir Archivo
                            <input
                                className=""
                                onChange={AddAttachments}
                                id="inputFiles"
                                type="file"
                                multiple
                            />
                        </button>

                    </div>

                    {state.SelectedFiles.length > 0 ?
                        <>

                            <button className="btn btn-sm  mr-2" onClick={() => toggleListFolder()}>
                                Mover
                            </button>
                            {newLocal}
                        </> :
                        null}
                </div>
            </div>
            {/**HEADER */}
            <div className=" row mx-0 page-header d-none">
                <div className="col-4  px-0">
                    <h1 className="mr-2">Files</h1>
                </div>
                <div className="col-4 text-center">

                </div>
                <div className="text-right col-4 px-0 ">

                </div>


            </div>
            {/**END HEADER */}

            <div className="row mt-4 containerFullHeight">
                <div className="col-12">
                    <div className="card shadow-sm ">
                        <div className="card-body overflow50 ">
                            {isUploading ? <div className="text-center">
                                <div className="spinner-border" style={{ width: "3rem", height: "3rem" }}>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div> : null}

                            {/*FILES */}
                            {state.Files && state.Files.length > 0 ?
                                <>
                                    <div className="row d-none d-md-flex">
                                        <div className="col-auto px-0 mb-2">
                                            <button className="btn btn-sm text-secondary" onClick={() => SelectAll()}>
                                                {state.SelectedFiles.length === 0 ?
                                                    <i className="far fa-circle"></i>
                                                    : state.SelectedFiles.length === state.Files.length ?
                                                        <i className="fas fa-dot-circle"></i> :
                                                        <i className="fas fa-minus-circle"></i>}
                                            </button>
                                        </div>
                                        <div className="col-5 mb-2">
                                            <small>Nombre</small>
                                            {isLoading ?
                                                <div className="spinner-border spinner-border-sm mx-2" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                : null
                                            }
                                        </div>
                                        <div className="col-auto col-md-1 ms-4 mb-2">
                                            <small>Tamaño</small>
                                        </div>
                                        <div className='col-auto col-md-2 mb-2'>
                                            <small>Actualizado por</small>
                                        </div>

                                        <div className='col-1 mb-2'>

                                        </div>
                                    </div>
                                    {CurrentFolderPath.length > 1 ?
                                        <div className='row' onClick={GoBack}>
                                            <div className="col-auto px-0 mb-2">
                                                <button className="btn btn-sm text-secondary">

                                                    <i className="fas fa-circle"></i>
                                                </button>
                                            </div>
                                            <div className='col-11'>
                                                <i class="fa fa-folder"></i>
                                                <span className='title clickable mx-2 mt-1 d-inline '>...</span>
                                            </div>
                                        </div>
                                        : null}

                                    {requestFilesUI()}

                                </>
                                :
                                <div className='col-4 mx-auto text-center'>
                                    {CurrentFolderPath.length > 1 ?
                                        <div className='row' onClick={GoBack}>
                                            <div className="col-auto px-0 mb-2">
                                                <button className="btn btn-sm text-secondary">

                                                    <i className="fas fa-circle"></i>
                                                </button>
                                            </div>
                                            <div className='col-11'>
                                                <i class="fa fa-folder"></i>
                                                <span className='title clickable mx-2 mt-1 d-inline '>...</span>
                                            </div>
                                        </div>
                                        : null}

                                    <img src={NOCONTENT} className="img-fluid" />
                                    <br />
                                    <p>Aún no hay contenido</p>
                                    <p>
                                        <span className="btn btn-file btn-primary" type="button">
                                            <i className='fa fa-upload mx-2'></i>
                                            Subir Archivo
                                            <input
                                                className=""
                                                onChange={AddAttachments}
                                                id="inputFiles"
                                                type="file"
                                                multiple
                                            />
                                        </span>
                                    </p>

                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>


        </div>

    );
}
export default Files;