import { Button, Card } from "react-bootstrap";
import { parsedUser } from "../../common/GetCurrentUser";
import { userAvatar } from "../../common/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useParams } from "react-router-dom";
import API from "../../common/API";
const ProfileCardWidget = () => {
    const user = parsedUser();

    const search = useLocation().search
    const searchParams = new URLSearchParams(search)
    let zoomClientID = '5A7D7FjBQpCuhNr1bqm3rg';
    let zoomClientAPISecret = 'GEOIqP1NJu96g2CfjdXXj2oQJwZKQlFY'
    const connectToZoom = () => {

        let zoomAPI = `https://zoom.us/oauth/authorize?response_type=code&client_id=5A7D7FjBQpCuhNr1bqm3rg&redirect_uri=${window.location}`;
        window.location = zoomAPI;
    }

    const requestOauthTokenZoom = async (code) => {
        let basicAuth = btoa(zoomClientID + ":" + zoomClientAPISecret);
        let header = {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'Basic ' + basicAuth },
            //crossdomain: true
        };
        let query = { code: code, grant_type: 'authorization_code', redirect_uri: window.location };
        var request = API.postActionExternal("https://zoom.us/oauth/token", query, header)
        console.log("response", request);
    }

    useEffect(() => {
        console.log("Got a code", searchParams, searchParams.get('code'));
        requestOauthTokenZoom(searchParams.get('code'))
    }, [searchParams])

    return (
        <Card border="light" className="text-center p-0 mb-4">
            <div className="profile-cover rounded-top" />
            <Card.Body className="pb-5">

                <img src={userAvatar(user && user.Email, 200)} className="user-avatar large-avatar rounded-circle mx-auto mt-n7 mb-4" alt="User" />
                {/* <Card.Img src={Profile1} alt="Neil Portrait" className="user-avatar large-avatar rounded-circle mx-auto mt-n7 mb-4" /> */}
                <Card.Title>{user.Email}</Card.Title>
                {/* <Card.Subtitle className="fw-normal">Senior Software Engineer</Card.Subtitle>
          <Card.Text className="text-gray mb-4">New York, USA</Card.Text> */}

                <Button variant="primary" size="sm" className="me-2 mt-2"
                    onClick={() => connectToZoom()}
                >
                    <FontAwesomeIcon icon={faUserPlus} className="me-1" /> Connect Zoom Account
                </Button>
                {/* <Button variant="secondary" size="sm">Send Message</Button> */}
            </Card.Body>
        </Card>
    );
};

export default ProfileCardWidget;