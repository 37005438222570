import React, { useEffect, useState } from "react";
import AddEditFolder from "./AddEditFolder";
import { v4 as uuidv4 } from "uuid";
import swal from "sweetalert";

import API from "../../common/API";
import { Card, Col, Container, Dropdown, Row } from "react-bootstrap";

import { ProperBasePath, openInNewTab, typeIconEquivalent } from "./helper";

import { parsedUser } from "../../common/GetCurrentUser";
import moment from "moment";

//Translation
import { useTranslation } from "react-i18next";
import SpreadsheetContainer from "./SpreadsheetContainer";
import AddEditLink from "./AddEditLink";
import DragAndDrop from "../Common/DragAndDrop";
import PDFContainer from "./PDFContainer";
import DocumentContainer from "./DocumentContainer";
import Settings from "../../common/Settings";

function Files(props) {
  const { t } = useTranslation("global");
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const user = parsedUser();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    CurrentFolderPath: [],

    UI: {
      showAddEditFolder: false,

      ShowPickFolder: false,
    },

    showViewerModal: false,
    previewFile: null,
    ChoosenLink: null,

    showVisibleByEvents: false,
    Events: [],
  });

  const requestFolderRemote = async () => {
    try {
      //   selectedFiles.set([])

      setSelectedFiles([]);

      let query = "";
      if (user.OrganizationId) {
        query += "OrganizationId=" + user.OrganizationId;
      }
      if (props.EmployeeId) {
        query += "&EmployeeId=" + props.EmployeeId;
      }
      if (props.projectId) {
        query += "&ProjectId=" + props.projectId;
      }
      if (user.id) {
        query += "&UserID=" + user.id;
      }
      if (selectedFolder) {
        query += selectedFolder !== "/" ? "&FolderId=" + selectedFolder : "";
      }
      const request = await API.getAction("File/List?" + query);

      let response = request.data;
      if (response.status === "ok") {
        console.log(response.response);
        setFolders(response.response);
        //setState({ ...state, Files: response.response })
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  //Request files
  const RequestFilesRemote = async () => {
    try {
      let query = "";
      if (user.OrganizationId) {
        query += "OrganizationId=" + user.OrganizationId;
      }
      if (props.EmployeeId) {
        query += "&EmployeeId=" + props.EmployeeId;
      }
      if (user.id) {
        query += "&UserID=" + user.id;
      }
      if (selectedFolder) {
        query += selectedFolder !== "/" ? "&FolderId=" + selectedFolder : "";
      }

      setState({ state, UI: { ...state.UI, isLoading: !state.UI.isLoading } });

      const request = await API.getAction("Files/ListFiles?" + query);

      let response = request.data;
      if (response.status === "ok") {
        setFiles(response.response);
      }
    } catch (ex) {
      console.error(ex);
    }
  };
  const [currentFolderPath, setCurrentFolderPath] = useState([]);
  useEffect(() => {
    let copy = currentFolderPath;
    copy.push({ id: "/", Name: "/" });

    setCurrentFolderPath(copy);

    requestFolderRemote();
    //RequestFilesRemote();
  }, []);

  const openItem = (item) => {
    console.log(item);
    //First check the type
    if (!item.type) {
      openFile(item);
      return;
    }
    switch (item.type.toLowerCase()) {
      case "folder":
        enterFolder(item);
        break;
      case "file":
      case "link":
        openFile(item);
        break;
      case "document":
        //openFile(item)
        break;
      default:
        openFile(item);
        break;
    }
  };

  const [selectedFolder, setSelectedFolder] = useState("/");
  const enterFolder = (item) => {
    setSelectedFolder(item.id);
    let copy = currentFolderPath;

    var exist = copy.some((x) => x.id === item.id);
    if (exist) {
      //If exist we must go over the array and create a new one until this record
      let newArray = [];
      for (var i = 0; i < copy.length; i++) {
        newArray.push(copy[i]);
        if (copy[i].id === item.id) {
          break;
        }
      }

      copy = newArray;
    } else {
      copy.push({ id: item.id, Name: item.name });
    }
    console.log("current folder", copy);
    setCurrentFolderPath(copy);
    // setState({ ...state, CurrentFolderPath: copy })
  };
  useEffect(() => {
    requestFolderRemote();

    // RequestFilesRemote();
  }, [selectedFolder]);
  const openFile = (item) => {
    console.log(item);
    debugger;
    setSelectedFile(item);
    if (!item.path) {
      console.log("item ", item);
      return;
    }
    let extension = item.path.split(".").pop();
    if (item.type === "link") {
      openInNewTab(item);
      return;
    }
    if (
      (item.type === "file" && extension.includes("xls")) ||
      extension.includes("xlsx")
    ) {
      toggleCreateSpreadSheet();
      return;
    }
    if (
      (item.type === "file" && extension.includes("doc")) ||
      extension.includes("docx")
    ) {
      toggleWordEditor();
      return;
    }
    if (item.type === "file" && extension.includes("pdf")) {
      togglePDF();
      return;
    }
  };

  const deleteFile = (item) => {
    swal({
      title: t("areyousure"),

      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const request = await API.getAction("File/DeleteFile?Id=" + item.id);

        let response = request.data;
        if (response.status === "ok") {
          await requestFolderRemote();
          //      await RequestFilesRemote();
        }
        swal(t("operationsucceed"), {
          icon: "success",
        });
      } else {
        //  swal("Your imaginary file is safe!");
      }
    });
  };

  const singleFileUI = (item) => {
    let fullFilePath = `${ProperBasePath()}files/${item.path}`;
    let sharelink = `/files/external/${item.id}`;
    return (
      <Col xs={12}>
        <div className="row filesContainer px-0">
          <div className="col-auto text-center px-2">
            <button
              className="btn btn-sm mt-1"
              onClick={() => MarkAsSelected(item, false)}
            >
              {selectedFiles.includes(item.id) ? (
                <i className="far fa-dot-circle checked  "></i>
              ) : (
                <i className="far fa-circle uncheck   "></i>
              )}
            </button>
          </div>
          <div className="col-auto text-center mt-1">
            <i className={typeIconEquivalent(item)} />
          </div>
          <div className="col-6 mt-1">
            <span
              className="title  mt-1"
              onClick={() => {
                console.log(item);
                openItem(item);
              }}
            >
              {item.name}
            </span>
            {item.type === "folder" && item.description ? (
              <small className="text-muted">
                <br />
                {item.description}
              </small>
            ) : null}
          </div>
          <div className="col ml-auto text-right">
            {item.update ? (
              <small className="text-muted">
                {t("by")} {item.update}
              </small>
            ) : null}
            {item.created ? (
              <small className="text-muted mx-2">
                {moment(item.created).fromNow()}
              </small>
            ) : null}
          </div>
          <div className="col-auto text-right ">
            <Dropdown>
              <Dropdown.Toggle variant="link" id="dropdown-basic">
                <i className="fa fa-ellipsis-v" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href={sharelink} target="_blank">
                  {t("share")}
                </Dropdown.Item>
                {item.type === "folder" ? (
                  <Dropdown.Item
                    onClick={() => {
                      toggleAddEditFolder(item);
                    }}
                  >
                    {t("edit")}
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item
                    onClick={() => {
                      window.open(
                        ProperBasePath() + "files/" + item.path,
                        "_blank"
                      );
                    }}
                    type="button"
                  >
                    {t("download")}
                  </Dropdown.Item>
                )}

                <Dropdown.Divider />

                <Dropdown.Item
                  onClick={() => {
                    console.log(item);
                    item.type === "folder"
                      ? deleteFolderRemote(item)
                      : deleteFile(item);
                  }}
                >
                  {t("delete")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* ACTIONS */}
          </div>
        </div>
      </Col>
    );
  };
  const filesUI = () => {
    let combined = folders.concat(files);
    let items = combined.map((item, index) => {
      return (
        <Row
          key={index}
          className={
            "clickable " + (selectedFiles.includes(item.id) ? "active" : "")
          }
          style={{ position: "relative" }}
          onClick={() => {
            MarkAsSelected(item, true);
          }}
          onDoubleClick={() => openItem(item)}
        >
          {item.type === "folder" ? (
            <DragAndDrop
              fontSize={16}
              dropText="Drop your file into this folder"
              handleDropCalendarEvent={(e) => {
                AddFilesToFolder(e, item);
              }}
            >
              {singleFileUI(item)}
            </DragAndDrop>
          ) : (
            singleFileUI(item)
          )}
        </Row>
      );
    });

    return items;
  };

  const SelectAll = () => {
    let objs = files;

    let selectedFields = [];
    if (selectedFiles.length === objs.length) {
      //Un selected all

      setSelectedFiles([]);
    } else if (selectedFiles.length === 0) {
      //Select all

      objs.forEach((record) => {
        selectedFields.push(record.id);
      });
      setSelectedFiles(selectedFields);
    } else {
      // un select all
      setSelectedFiles([]);
    }
  };
  const MarkAsSelected = (record, onlyThisFile) => {
    let selectedFields = selectedFiles;

    if (onlyThisFile && onlyThisFile === true) {
      selectedFields = [record.id];
    } else {
      if (selectedFields.includes(record.id)) {
        console.log("enter here", selectedFields);
        selectedFields = selectedFields.filter((x) => x !== record.id); //We are removing the existing file
        console.log("enter here 2", selectedFields);
      } else {
        selectedFields.push(record.id);
      }
    }

    setSelectedFiles(selectedFields);
  };

  const [choosenFolder, setChoosenFolder] = useState(null);
  const [showAddEditFolder, setShowAddEditFolder] = useState(false);
  const toggleAddEditFolder = (obj, reload) => {
    setChoosenFolder(obj);
    setShowAddEditFolder(!showAddEditFolder);

    if (reload) {
      requestFolderRemote();
      //   RequestFilesRemote();
    }
  };

  const [showAddEditLink, setshowAddEditLink] = useState(false);
  const [choosenLink, setChoosenLink] = useState(null);
  const toggleAddEditLink = (obj, reload) => {
    setshowAddEditLink(!showAddEditLink);
    setChoosenLink(obj);
    if (reload) {
      requestFolderRemote();
    }
  };

  const deleteFolderRemote = (item) => {
    swal({
      title: t("areyousure"),
      text: item.name,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const request = await API.getAction("File/DeleteFolder?Id=" + item.id);

        let response = request.data;
        if (response.status === "ok") {
          requestFolderRemote();
        }
        swal(t("operationsucceed"), {
          icon: "success",
        });
      } else {
        //  swal("Your imaginary file is safe!");
      }
    });
  };

  const AddFilesToFolder = (e, item) => {
    console.log("Item", e, item);

    AddAttachments(e, item.id);
  };
  const AddAttachments = (e, folder) => {
    var theFiles = Array.from(e.target ? e.target.files : e);

    theFiles.forEach(async (item) => {
      let newItem = {
        id: uuidv4(),
        Name: item.name,
        Note: "",
        Path: item.name,
        OrganizationId: user.OrganizationId,
        projectId: props.projectId,
        Userid: user.id,
        ParentId: folder
          ? folder
          : selectedFolder.length === 0 || selectedFolder === "/"
          ? null
          : selectedFolder,
        Type: "file",
      };
      //var responseGeneral = requestGeneral.data.Response
      //Upload the actual file now

      let bodyData = new FormData();
      bodyData.append("path", item);
      var request = await API.postAction(`File/Upload`, bodyData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(request);
      if (request.data.status === "ok") {
        // debugger
        newItem.Path = request.data.response;
        await API.postAction("file/AddEditFile", newItem);
      }
      await requestFolderRemote();
    });
  };
  const [showSpreadSheet, setShowSpreadSheet] = useState(false);
  const toggleCreateSpreadSheet = () => {
    setShowSpreadSheet(!showSpreadSheet);
  };
  const [showPDF, setShowPDF] = useState(false);
  const togglePDF = () => {
    setShowPDF(!showPDF);
  };

  const [showWordEditor, setShowWordEditor] = useState(false);
  const toggleWordEditor = () => {
    setShowWordEditor(!showWordEditor);
  };
  const onChangeDrop = (files) => {
    AddAttachments(files);
  };

  return (
    <>
      {/*MODALS AREA */}
      {showAddEditLink ? (
        <AddEditLink
          toggle={toggleAddEditLink}
          model={choosenLink}
        ></AddEditLink>
      ) : null}
      {showSpreadSheet ? (
        <SpreadsheetContainer
          modal
          file={selectedFile}
          toggle={toggleCreateSpreadSheet}
        ></SpreadsheetContainer>
      ) : null}
      {showWordEditor ? (
        <DocumentContainer
          modal
          file={selectedFile}
          toggle={toggleWordEditor}
        ></DocumentContainer>
      ) : null}

      {showPDF ? (
        <PDFContainer
          modal
          file={selectedFile}
          toggle={togglePDF}
        ></PDFContainer>
      ) : null}
      {showAddEditFolder ? (
        <AddEditFolder
          model={choosenFolder}
          toggle={toggleAddEditFolder}
          show={showAddEditFolder}
        />
      ) : null}

      {/*END MODALS AREA*/}
      <Container fluid>
        <div className="row">
          <div className="col-12  text-right"></div>

          <div
            className="col-12"
            style={{
              position: "relative",
              height: "calc(100vh - 220px) !important",
            }}
          >
            {/*FILES */}
            <DragAndDrop handleDropCalendarEvent={onChangeDrop}>
              <Card className="shadow-sm">
                <Card.Header className="p-1">
                  <Row className="mx-0">
                    <Col className="py-2 text-center" xs={"auto"}>
                      {files.length > 0 ? (
                        <button
                          className="btn btn-sm "
                          onClick={() => SelectAll()}
                        >
                          {selectedFiles.length === 0 ? (
                            <i className="far fa-circle"></i>
                          ) : selectedFiles.length === files.length ? (
                            <i className="fas fa-dot-circle"></i>
                          ) : (
                            <i className="fas fa-minus-circle"></i>
                          )}
                        </button>
                      ) : null}
                    </Col>
                    <Col xs={"auto"} className="py-1">
                      {currentFolderPath && currentFolderPath.length > 0 ? (
                        <nav aria-label="breadcrumb">
                          <ol className="breadcrumb mb-0">
                            {currentFolderPath.length > 1 ? (
                              <li
                                className="breadcrumb-item clickable"
                                onClick={() => {
                                  let newRoute = currentFolderPath;
                                  newRoute.pop();
                                  let selected =
                                    newRoute[newRoute.length - 1].id;
                                  setSelectedFolder(selected);
                                  console.log(currentFolderPath, newRoute);
                                  setCurrentFolderPath(newRoute);
                                }}
                              >
                                <i className="fas fa-chevron-left"></i>
                              </li>
                            ) : null}
                            {currentFolderPath.map((path) => {
                              console.log(path);
                              return path.id === "/" ? (
                                <li
                                  key={path.id}
                                  className="breadcrumb-item clickable"
                                  onClick={() => enterFolder(path)}
                                >
                                  <i className="fa fa-home"></i>
                                </li>
                              ) : (
                                <li
                                  key={path.id}
                                  className="breadcrumb-item clickable "
                                  onClick={() => enterFolder(path)}
                                >
                                  {path.Name}
                                </li>
                              );
                            })}
                          </ol>
                        </nav>
                      ) : null}
                    </Col>
                    <Col className="text-end">
                      {/* {selectedFiles && selectedFiles.length > 0 ? (
                        <>
                          <button
                            className="btn mx-2"
                            onClick={() => toggleListFolder()}
                          >
                            {t("move")}
                          </button>
                        </>
                      ) : null} */}
                      <div className="btn-group mx-2">
                        <button className="btn btn-file mx-2" type="button">
                          <i className="fa fa-upload mx-2"></i>
                          Subir Archivo
                          <input
                            className=""
                            onChange={AddAttachments}
                            id="inputFiles"
                            type="file"
                            multiple
                          />
                        </button>
                        <Dropdown>
                          <Dropdown.Toggle variant="primary">
                            <i className="fa fa-plus" />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {/* <Dropdown.Item onClick={()=>{
                            
                          }}>
                            {t("upload_file")}
                          </Dropdown.Item> */}
                            <Dropdown.Item
                              onClick={() =>
                                toggleAddEditFolder({
                                  id: "",
                                  name: "",
                                  organizationId: user.OrganizationId,
                                  customerId: props.CustomerId,
                                  projectId: props.projectId,
                                  parentId:
                                    selectedFolder === "/"
                                      ? null
                                      : selectedFolder,
                                  employeeId: props.EmployeeId,
                                  type: "folder",
                                })
                              }
                            >
                              {t("add_folder")}
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item
                              onClick={() =>
                                toggleAddEditLink({
                                  id: uuidv4(),
                                  organizationId: "",
                                  name: "",
                                  type: "link",
                                  path: "",

                                  projectId: props.projectId,
                                  parentId:
                                    selectedFolder === "/"
                                      ? null
                                      : selectedFolder,
                                })
                              }
                            >
                              {t("add_link")}
                            </Dropdown.Item>
                            {/* <Dropdown.Item href="#/action-2">
                              {t("add_document")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                toggleCreateSpreadSheet();
                              }}
                            >
                              {t("add_spreadsheet")}
                            </Dropdown.Item> */}

                            <Dropdown.Divider />
                            {/* <Dropdown.Item onClick={()=>deleteFolderRemote()}>
                            {t("delete_folder")}
                          </Dropdown.Item> */}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body
                  style={{
                    overflowY: "auto",
                    height: "calc(100vh - 32vh)",
                  }}
                >
                  {filesUI()}
                </Card.Body>
              </Card>
            </DragAndDrop>
          </div>
        </div>
      </Container>
    </>
  );
}
export default Files;
