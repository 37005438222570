import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { SocketContext } from "../../Context/socket";
import AddGroup from "./AddGroup";
import ConversationUI from "./ConversationUI";
import { parsedUser } from "../../common/GetCurrentUser";

const ChatSidebar = props => {

    const [records, setRecords] = useState([]);
    const user = parsedUser();
    const [showConversation, setShowConversation] = useState(false);
    const [showAddGroup, setShowAddGroup] = useState(false);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [messages, setMessages] = useState([]);

    const socket = useContext(SocketContext);

    const toggleConversation = (person) => {

        setShowConversation(!showConversation);
        setSelectedConversation(person);

        if (!showConversation === true && person.conversationId) {
            console.log("conversation exist we are going to join the chat", person.conversationId)
            //Conversation exist
            //Conversation exist so we must provide the join room 
            socket.invoke("JoinRoom", person.conversationId, person.userId);
        }
        else if (!showConversation === true) {
            //Conversation does not exist yet
            let theUser = parsedUser();
            console.log("start conversation", person.conversationId, theUser.ID, person.userId);

            socket.invoke("StartConversation", person.conversationId ? person.conversationId : null, theUser.ID, person.userId);
        }
        else if (person && person.conversationId) {
            //Leave, and go back to the list
            socket.invoke("LeaveRoom", person.conversationId);
            socket.invoke("GetConversations", user.ID);

        }
        setTimeout(() => {
            document.getElementById("textMessageContainer") && document.getElementById("textMessageContainer").focus();
        }, 500)
    }

    const userListUI = () => {
        try {
            if (records && records.length > 0) {
                let ui = records.map((item, index) => {
                    return (<div className="row px-2 py-1 mb-2 clickable bg-light"
                        key={index}
                        onClick={() => { toggleConversation(item) }}>
                        <div className="col-auto px-0">
                            <div className="avatarContainer">
                                <img src={`https://via.placeholder.com/32/3f67fa/ffffff/?text=${item.name?.substring(0, 1)}`} alt={item.firstName} title={item.firstName} className="img-fluid rounded " />

                                {item.status === 0 ?
                                    <i className="fa fa-circle text-light status"></i>
                                    : <i className="fa fa-circle text-success status"></i>}
                            </div>
                        </div>
                        <div className="col pl-0 text-truncate">
                            <small><b>{item.name}</b>
                            </small>
                            {item.lastMessage && item.lastMessage.length > 0 ?
                                <>
                                    <br />
                                    <small className="text-muted">{item.lastMessage} <span className="font-weight-light">- {moment(item.lastMessageTime).fromNow()}</span> </small>
                                </> : null}
                        </div>
                        <div className="col-auto">
                            {item.unreadCount > 0 ?
                                <span className="badge bg-danger">{item.unreadCount}</span>
                                : null}
                        </div>

                    </div>)
                });

                return ui;
            }
        } catch (ex) {
            console.error(ex);
        }
    }

    const scrollBottom = () => {
        setTimeout(() => {
            const element = document.getElementsByClassName("messages")[0];
            if (element) {
                element.scrollTop = element.scrollHeight;
            }
        }, 1000)

    }


    useEffect(() => {

        if (props.conversationSelected) {

            toggleConversation(props.conversationSelected);
        }

    }, [props.conversationSelected])

    useEffect(() => {

        socket.on("onGetConversations", items => {

            setRecords(items);
        })

        socket.on("OnStartConversation", (item, withAccountId) => {

            setSelectedConversation(item);
            socket.invoke("JoinRoom", item.id, withAccountId);
        })
        socket.on("OnReceiveMessages", (items, total) => {

            setMessages(items);
            setTimeout(() => {
                scrollBottom();
            }, 500)
        });
        socket.on("OnJoinRoom", (conversationid, conversation) => {

            setSelectedConversation(conversation);
            socket.invoke("GetMessages", conversationid, 0, 50);
        });
        socket.on("OnReceiveMessage", (newMessage, currentUser) => {
            let themessages = messages;
            themessages.push(newMessage);
            //setMessages(themessages); 
            socket.invoke("GetMessages", newMessage.conversationId, 0, 50);
        })

        // if (!props.conversationSelected || props.conversationSelected === null) {

        //     socket.invoke("GetConversations", theUser.ID);
        // }
    }, []);
    const toggleAddGroup = (reload) => {
        setShowAddGroup(!showAddGroup);
        if (reload) {
            socket.invoke("GetConversations", user.ID);
        }
    }

    useEffect(() => {
        if (socket.state === 'Connected') {
            socket.invoke("GetConversations", user.ID);
        }
    }, [socket.state])

    return (
        <>
            {showAddGroup ? <AddGroup show={showAddGroup} toggle={toggleAddGroup} /> : null}
            <div className={"chatSidebar rounded bg-white border shadow-md col-12 col-md-4 col-lg-3 p-2 px-4" + (showConversation ? "conversation" : "")}>
                <div className="row">
                    <div className="col text-truncate">
                        {showConversation ? <>
                            <button className="btn " type="button" onClick={() => toggleConversation(selectedConversation)}>
                                <i className="fa fa-chevron-left"></i>
                            </button>
                            <b className="d-inline " title={selectedConversation && selectedConversation.name}>{selectedConversation && selectedConversation.name}</b>

                        </> : <>
                            <b className="d-inline">Chats</b>

                        </>}

                    </div>
                    <div className="col text-right">
                        {showConversation ?
                            <>
                                {/* <button className="btn btn-sm" title="Video" onClick={() => {
                                    props.toggleCall(selectedConversation, true, true)
                                }}>
                                    <i className="fas fa-video"></i>
                                </button>
                                <button className="btn btn-sm" title="Llamar" onClick={() => {
                                    props.toggleCall(selectedConversation, true)
                                }}>
                                    <i className="fas fa-phone"></i>
                                </button> */}
                            </>
                            :
                            <button className="btn" title="Mensaje grupal"
                                onClick={() => { toggleAddGroup() }}>
                                <i className="fas fa-user-plus"></i>
                            </button>}
                        <button className="btn" onClick={() => props.toggle()}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div>
                </div>
                {showConversation ?

                    <ConversationUI messages={messages} socket={socket} selectedConversation={selectedConversation} /> :
                    userListUI()
                }
            </div>
        </>);
}

export default ChatSidebar;