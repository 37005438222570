import React, { useContext, useEffect, useState } from 'react';

import { Modal, Button, Col, Row, Form } from '@themesberg/react-bootstrap';
import * as yup from 'yup';
import API from '../../common/API';
import { parsedUser } from '../../common/GetCurrentUser';
import { SocketContext } from '../../Context/socket';
import { useFormik } from 'formik';

//Validation
const validationSchema = yup.object({
    name: yup.string().required(),

    //  phone: yup.string().required(),

});
//Component
const AddUsersToChat = props => {

    const [state, setState] = useState({

        UI: {
            isLoading: false
        },
    })
    const [userSelected, setUserSelected] = useState([])
    const [users, setUsers] = useState([]);
    const user = parsedUser();
    const socket = useContext(SocketContext);
    socket.on("OnReceiveMessages", (items, total) => {
        props.toggle();
    });
    useEffect(() => {
        console.log("the props", props)
        bindUsers();
    }, [])


    const bindUsers = async () => {

        let query = "OrganizationID=" + user.OrganizationId;

        var request = await API.getAction("Users/List", query);
        console.log("Response from api", request)
        if (request.data.status === "ok") {
            setUsers(request.data.response.filter(x => x.userId !== user.ID));

        }
    }
    const handleSubmit = async obj => {
        try {

            let together = '';
            userSelected.forEach(item => {
                together = together + (together.length > 0 ? "," : "") + item.userId;
            })
            debugger;
            socket.invoke("AddToConversation", formik.values.name, together)
        } catch (ex) {
            console.error(ex);
            setState({
                ...state,
                UI: {
                    ...state.UI,
                    isLoading: false
                }
            })

        }

    };

    const formik = useFormik({
        initialValues: { name: '' },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const toggleSelectUser = obj => {
        let selected = userSelected;
        //Check if exist, if exist we must remove it
        let exist = userSelected.filter(x => x.id === obj.userId)[0]
        if (exist) {
            //We must remove it 
            selected = selected.filter(x => x.id !== obj.userId);
        } else {
            selected.push(obj);
        }

        setUserSelected(selected);
    }


    return (
        <Modal show={props.show} onHide={props.toggle} size='md'>
            <Modal.Header closeButton>
                <Modal.Title>Agregar a la conversación</Modal.Title>
            </Modal.Header>

            <Form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <Row>


                        <Col xs={12} className="mt-2">

                            {users && users.filter(x => x.user.id !== user.id).map((item, index) => {
                                console.log("item ", userSelected, item)
                                let exist = userSelected.length > 0 && userSelected.filter(x => x.id === item.id)[0];
                                return (<div className='row mx-0' key={index}>
                                    <div className='col-auto'>
                                        {exist ?
                                            <input type="checkbox" className='d-inline'
                                                onChange={() => {
                                                    toggleSelectUser(item)
                                                }}
                                                name="user"
                                                value={item.userId}
                                                checked
                                            ></input> :
                                            <input type="checkbox" className='d-inline'
                                                name="user"
                                                value={item.userId}
                                                onChange={() => {
                                                    toggleSelectUser(item)
                                                }}

                                            ></input>
                                        }

                                    </div>
                                    <div className='col'>
                                        {item.user.firstName} {item.user.lastName}<br />
                                        <small className='text-muted'>{item.user.email}</small>
                                        <hr />
                                    </div>
                                </div>)
                            })}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit" className="btn-sm"
                        disabled={state.UI.isLoading}>
                        Agregar
                    </Button>
                </Modal.Footer>
            </Form>


        </Modal>
    )
}

export default AddUsersToChat;