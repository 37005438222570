import React, { useEffect } from 'react';

import { Modal, Button, Col, Row, Form } from '@themesberg/react-bootstrap';
import { useState } from '@hookstate/core';
import * as yup from 'yup';
import { useFormik } from 'formik';
import 'react-quill/dist/quill.snow.css'; // ES6

import API from '../../common/API';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

//Validation
const validationSchema = yup.object({
    name: yup.string().required("required"),
    lastName: yup.string().required(),
    phone: yup.string().required(),

});
//Component
const AddEdit = props => {
    const state = useState({

        UI: {
            isLoading: false
        },

    })
    useEffect(() => {
        console.log("props received on contact", props);
    }, [])

    const handleSubmit = async obj => {
        console.log("values to submit", obj);

        if (obj.id.length === 0) {
            obj.id = uuidv4();
            obj.created = moment().format("YYYY-MM-DD HH:mm:ss")
        }
        state.UI.isLoading.set(!state.UI.isLoading.value);
        let request = await API.postAction("todotask/AddEditsection", obj);
        state.UI.isLoading.set(!state.UI.isLoading.value);
        if (request.status === 200) {
            props.toggle(request.data, true);
        }
    };

    const formik = useFormik({
        initialValues: props.model ? JSON.parse(JSON.stringify(props.model)) :
            {
                name: "", lastName: "",
                phone: "", position: "",
                email: "", note: "",

                created: new Date(),
                id: "", OrganizationId: "", employeeId: "", customerId: ""
            },
        validationSchema,
        onSubmit: handleSubmit,
    });


    return (
        <Modal show={props.show} onHide={props.toggle} size='md'>
            <Modal.Header closeButton>
                <Modal.Title>To-Do Sección</Modal.Title>
            </Modal.Header>

            <Form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control type="text"
                                name="name"
                                value={formik.values.name} onChange={formik.handleChange} />
                            {formik.errors.name ? <div className="invalid text-sm">{formik.errors.name}</div> : null}

                        </Col>


                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.toggle} className="btn-sm">
                        Cerrar
                    </Button>
                    <Button variant="primary" type="submit" className="btn-sm"
                        disabled={state.UI.isLoading.value}
                    >Guardar</Button>
                </Modal.Footer>
            </Form>


        </Modal>
    )
}

export default AddEdit;