import { useState } from '@hookstate/core';
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment';
import PostList from '../../Posts/PostList';
import AddEditActivity from './AddEditActivity';
import Files from '../../files';
import ContactList from '../../Contacts/ContactList';
import API from '../../../common/API';
import { Routes } from '../../../routes';
import { parsedUser } from '../../../common/GetCurrentUser';

const ActivityDetails = props => {
    const state = useState({
        User: null,
        CurrentSection: "general",
        UI: {
            showAddEdit: false,
            showAssingDiscounts: false,
            showAssingBenefits: false,
        },
        TotalValueForEmployee: 0
    });

    useEffect(() => {
        let theUser = parsedUser();

        if(!theUser){
            window.location = Routes.SignIn.path;
        }
        request();
    }, []);
    const AssignSection = opt => {
        state.CurrentSection.set(opt);
    }
    const request = async () => {
        let requestAPI = await API.getAction("Appointments/Details?Id=" + props.match.params.id);

        if (requestAPI.data.status === "ok") {

            let copy = JSON.parse(JSON.stringify(state.value));
            copy.User = requestAPI.data.response;
            state.set(copy);
            toggle();
        }
    };
    const toggle = async (obj, reload) => {
        //state.UI.SelectedItem.set(obj);
        state.UI.showAddEdit.set(!state.UI.showAddEdit.value);
        if (reload !== undefined) {
            request();
        }
    };


  
    console.log("state value ", state);
    return (
        <>
            {state.UI.showAddEdit.value ?
                <AddEditActivity toggle={toggle} show={state.UI.showAddEdit.value} model={state.User.value} />
                : null}
         
            <div className="row">
                <nav aria-label="breadcrumb" className="mb-2">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/"><i className="fa fa-home"></i></Link></li>

                        <li className="breadcrumb-item"><Link to={Routes.Calendar.path}>Actividades</Link></li>
                        <li className="breadcrumb-item">Detalles</li>
                    </ol>
                </nav>
               
            </div>
        </>
    )
}
export default ActivityDetails;