import React, { useEffect, useState } from 'react';

import { Modal, Button, Col, Row, Form } from '@themesberg/react-bootstrap';
import API from '../../common/API';
import { useFormik } from 'formik';

import * as yup from 'yup';
import { parsedUser } from '../../common/GetCurrentUser';

//Validation
const validationSchema = yup.object({
  name: yup.string().required(),
  path: yup.string().required()
});

export default function AddeditLink(props) {

  const [state, setState] = useState({
    Model: props.Model,
    IsLoading: false,


  });

  useEffect(() => {
    setTimeout(() => {
      let focus =
        document.getElementById("name");

      if (focus !== null) {
        focus.focus();

      }
    }, 1000);


    console.log("props state", state);
  }, []);

  const handleSubmit = async (obj) => {

    obj.createdBy = null;
    obj.updatedBy = null;
    let theUser = parsedUser();
    let query = {
      Id: theUser.ID,
      Data: obj
    }
    //We have to sent to api    
    const request = await API.postAction(
      `Files/AddEditFileWithoutFile`, query
    );
    setState({
      ...state,
      IsLoading : !state.IsLoading
    });
    if (request.data.status === "ok") {
      props.toggle(request.data.response, true);
    }

    //props.toggle();
  }
  const formik = useFormik({
    initialValues: props.Model,
    validationSchema,
    onSubmit: handleSubmit,
  })
  return (
    <Modal show={true} onHide={props.toggle} size='md'>
      <Modal.Header closeButton>
        <Modal.Title>Enlace</Modal.Title>
      </Modal.Header>



      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <div className="row">
            <Col xs={12} md={12}>
              <Form.Label>Nombre</Form.Label>
              <Form.Control type="text"
                id="name"
                value={formik.values.name} onChange={formik.handleChange} />
              {formik.errors.name ? <div className="invalid text-sm">{formik.errors.name}</div> : null}
            </Col>
            <Col xs={12} md={12}>
              <Form.Label>URL</Form.Label>
              <Form.Control type="text"
                id="path"
                value={formik.values.path}
                onChange={formik.handleChange} />
              {formik.errors.path ? <div className="invalid text-sm">{formik.errors.path}</div> : null}
            </Col>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='row'>
            <div className="col-12 text-right">
              {state.IsLoading ?
                <div className="spinner-border spinner-border-sm mr-2" role="status">
                  <span className="sr-only">Cargando...</span>
                </div>
                : null}

              <Button variant="primary" type="submit" className="btn-sm"
                disabled={state.IsLoading}
              >
                Guardar
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>

  );
}