import React, { useEffect, useState } from 'react';
import { Modal, Button, Col, Row, Form } from '@themesberg/react-bootstrap';
import API from '../../common/API';
import { useFormik, } from 'formik';
import * as yup from 'yup';
import { parsedUser } from '../../common/GetCurrentUser';

//Validation
const validationSchema = yup.object({
  name: yup.string().required(),

});

export default function AddEditFolder(props) {

  const [state, setState] = useState({
    Model: props.Model ? props.Model :
      {
        name: "", userAccess: "", organizationId: "", parentId: null,
        projectId: null,
        created: new Date(),
        id: ""
      },
    IsLoading: false,


  });

  useEffect(() => {

    setTimeout(() => {
      let focus = document.getElementById("Name");
      if (focus !== null) {
        focus.focus();

      }
    }, 1000);

  }, []);

  const handleSubmit = async (obj) => {

    try {
      
     
      setState({
        ...state,
        Model: obj
      });


      const theUser = parsedUser();
      let query = {
        Id: theUser.ID,
        Data: obj
      }
      //We have to sent to api    
  
      const request = await API.postAction(
        'Files/AddEditFolder', query
      );
     

      if (request.data.status === "ok") {
        props.toggle(request.data.response, true);
      }
    } catch (ex) {
      console.error(ex);
    }
    finally {
      setState({
        ...state,
        IsLoading: false
      });
    }
    //props.toggle();
  }

  const formik = useFormik({
    initialValues: state.Model,
    validationSchema,
    onSubmit: handleSubmit,
  })
  return (
    <Modal show={true} onHide={props.toggle} size='md'>
      <Modal.Header closeButton>
        <Modal.Title>Carpeta</Modal.Title>
      </Modal.Header>



      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>

          <Row>
            <Col xs={12} md={12}>
              <Form.Label>Nombre</Form.Label>
              <Form.Control type="text"
                name="name" id="Name"
                value={formik.values.name} onChange={formik.handleChange} />
              {formik.errors.title ? <div className="invalid text-sm">{formik.errors.firstName}</div> : null}
            </Col>

          </Row>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" type="submit" className="btn-sm"
            disabled={state.IsLoading}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Form>

    </Modal>


  );
}