import React, { useEffect , useState} from 'react';

import { Modal, Button, Col, Row, Form, InputGroup } from '@themesberg/react-bootstrap';

import * as yup from 'yup';
import { makeId, onlyHTMLTags } from '../../../common/helpers';
//Validation
const validationSchema = yup.object({
    name: yup.string().required(),


});
//Component
const CreateMeeting = props => {
    const id = makeId(5);
    const [url, setUrl] = useState("");
    useEffect(() => {
        console.log("props received on activity", props);
        setUrl("https://vo.shearly.com/meeting/details/"+ id);
    }, []);
    return (
        <Modal show={props.show} onHide={props.toggle} size='md'>
            <Modal.Header closeButton>
                <Modal.Title>Online Meeting</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row>

                    <Col xs={12}>
                        
                    <Form.Label>URL</Form.Label>
                        <div className='input-group'>
                            <Form.Control type="text"
                                name="name"
                                value={url} />
                            <button className='btn btn-primary'
                                onClick={() => {
                                    window.open(url, "_blank")
                                }}>
                                <i className="fas fa-external-link-alt"></i>
                            </button>
                            <button className='btn btn-primary'
                                onClick={() => {
                                    /* Get the text field */
                                    var copyText = url;
                                    /* Copy the text inside the text field */
                                    navigator.clipboard.writeText(copyText);

                                    /* Alert the copied text */
                                    alert("Copied " + copyText);
                                }}>
                                <i className="fas fa-copy"></i>
                            </button>
                        </div>
                    </Col>


                </Row>
            </Modal.Body>



        </Modal>
    )
}

export default CreateMeeting;