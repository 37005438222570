import React, { useEffect, useState } from "react";
import PDFContainer from "./PDFContainer";
import { useParams } from "react-router-dom";
import { parsedUser } from "../../common/GetCurrentUser";
import API from "../../common/API";

const External = props => {
	const [selectedFile, setSelectedFile] = useState(null);
	const { id } = useParams();
	const user = parsedUser();
	const bindDataRemote = async () => {

		try {
			let query = "id=" + id;




			const request = await API.getAction("File/details?" + query);

			let response = request.data;
			console.log(response)
			if (response.status === "ok") {
				setSelectedFile(response.response);
			}
		} catch (ex) {
			console.error(ex);
		}

	}
	useEffect(() => {
		bindDataRemote();
	}, [id])
	if (selectedFile === null) {
		return (<></>)
	}
	return (<PDFContainer
		//modal
		file={selectedFile}
	//toggle={togglePDF}
	></PDFContainer>)
}

export default External;