import React, { useEffect, useState } from "react";
import API from "../../common/API";
import { parsedUser } from "../../common/GetCurrentUser";

const OverviewTaskByAssignee = props => {
    const [state, setState] = useState([]);

    const request = async () => {
        let theUser = parsedUser();
        let requested = await API.getAction("dashboard/TotalByAssigne?organizationid=" + theUser.OrganizationId);
        if (requested.data.status === "ok") {
            setState(requested.data.response);
        }
    }
    useEffect(() => {
        request();
    }, []);

    return (<div className="card shadow-sm">
        <div className="card-header p-2">
            <b>Actividades por colaborador</b>
        </div>
        <div className="card-body p-2">
            <table className="table table-stripped">
                <tbody>
                    {state.map((item, index) => {
                        return (<tr key={index}>
                            <td >
                                {item.assigne}
                            </td>
                            <td className=" text-right">
                                {item.taskCount}
                            </td>
                        </tr>);
                    })}
                </tbody>
            </table>

        </div>
    </div>)
}

export default OverviewTaskByAssignee;