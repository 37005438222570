
import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCog, faEnvelopeOpen, faSearch, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { Nav, Form, Navbar, Dropdown, Container, InputGroup } from '@themesberg/react-bootstrap';

import logoCapital from "../../assets/logos/logoShearlyCap.png";
import logoInitiative from "../../assets/logos/logoShearlyI.png";
import logoBluesteel from "../../assets/logos/logo-bluesteelPlain.png";

import NOTIFICATIONS_DATA from "../../data/notifications";
import { Routes } from "../../routes";
import GetCurrentUser from "../../common/GetCurrentUser";
import { userAvatar } from "../../common/helpers";
import { parseJwt } from "../../common/helpers";


import { SocketContext } from "../../Context/socket";
import ChatSidebar from "../Chat/ChatSidebar";
import ChatNotificationSound from '../../assets/sound/ChatNotification.mp3';

import ShareCalendar from "../Common/Agenda/shareCalendar";

import AcceptRequestCall from "../Chat/AcceptRequestCall";
import { useHistory } from "react-router-dom";
import NewsUpdatesList from "../NewsUpdates/NewsUpdatesList";


export default (props) => {
  const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
  const user = parseJwt(GetCurrentUser());

  const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);

  const history = useHistory();

  const [showShare, setShowShare] = useState(false);
  const [callRequestedBy, setCallRequestedBy] = useState(null);

  const toggleShare = () => {
    setShowShare(!showShare);
  }
  const audio = new Audio(ChatNotificationSound);

  const socket = useContext(SocketContext);

  useEffect(() => {

    // socket.on("onOnline", data => {
    //   socket.invoke("GeneralUnreadCount")
    // });
    // socket.on("onNotifyAboutNewMessage", () => {
    //   socket.invoke("GeneralUnreadCount");
    //   audio.play();
    // });

    // socket.on("OnReceiveCall", (conversation, callRequestedByReceivedOnServer) => {
    //   console.log("the user receive a call", conversation, "requested by", callRequestedByReceivedOnServer);

    //   setCallRequestedBy(callRequestedByReceivedOnServer);
    //   setCallData({
    //     conversation: conversation,
    //   })
    //   setTimeout(() => {
    //     toggleCall(conversation);
    //   }, 500)
    // })
    // socket.on("ReceiveCallNotPossible", msg => {
    //   console.log("call not possible", msg);
    // })


    // socket
    //   .on("ReceiveUnreadMessagesInGeneral", (data, status, user) => {
    //     console.log("ReceiveUnreadMessagesInGeneral", data, status, user);
    //     if (data === 0) {
    //       setHasUnreadMessages(false);
    //     } else {
    //       setHasUnreadMessages(true);

    //     }
    //   })


  }, []);

  const [showCall, setShowCall] = useState(false);
  const [callData, setCallData] = useState(null);

  const toggleCall = (obj, caller, isVideoCall) => {
    if (obj) {
      setCallData({
        conversation: obj,
        requestedBy: user,
        isVideoCall: isVideoCall
      })
      if (caller === true) {

        socket.invoke("RequestToStartCall", obj.id, user.ID)
      }

    }
    setShowCall(!showCall);
  }
  const [showNewsUpdate, setShowNewsUpdate] = useState(false);
  const toggleNewsUpdate = () => {
    setShowNewsUpdate(!showNewsUpdate);
  }

  const [showChat, setShowChat] = useState(false);
  const toggleChat = () => {
    setShowChat(!showChat);
  }
  console.log(user)

  if (!user) {
    history.push(Routes.SignIn.path);
    return;
  }
  return (
    <>
      {showChat ?
        <ChatSidebar toggle={toggleChat} show={showChat} /> : null}
      {showNewsUpdate ?
        <NewsUpdatesList toggle={toggleNewsUpdate} show={showNewsUpdate} />
        : null}
      {showCall ?
        <AcceptRequestCall toggle={toggleCall}
          show={showCall}
          call={callData}
          callRequestedBy={callRequestedBy} /> : null}
      {showShare ?
        <ShareCalendar toggle={toggleShare} show={showShare} global />
        : null}
      <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
        <Container fluid className="px-0">
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex align-items-center">
              <a href={Routes.SelectOrganization.path}>

                <img src={user && user.OrganizationId === "1" ? logoInitiative : user.OrganizationId === "2" ? logoCapital : logoBluesteel} alt="" className="img-fluid rounded " style={{ maxHeight: "32px" }} />
              </a>
              {/* &nbsp;-&nbsp;
              <h4 className="mx-2 mt-2 text-truncate">Virtual <b>Office</b></h4> */}


              <Form className="navbar-search d-none">
                <Form.Group id="topbarSearch">
                  <InputGroup className="input-group-merge search-bar ">
                    <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                    <Form.Control type="text" placeholder="Search" />
                  </InputGroup>
                </Form.Group>
              </Form>
            </div>
            <Nav className="align-items-center">
              {/* <ConnectedUsers onPersonalClick={onPersonalClick} /> */}
              {/* <button className="btn position-relative"
                title="Notas"
                onClick={() => {

                  const windowFeatures = "left=100,top=100,width=800,height=600";
                  window.open('/notes/', '_blank', windowFeatures)

                }
                }>
                <i className="far fa-sticky-note"></i>

              </button> */}
              {/* <button className="btn position-relative"
                title="Chat"
                onClick={() => {
                  toggleChat()
                  // history.push(Routes.Chat.path);
                }
                }>
                <i className="fas fa-comments"></i>
                {hasUnreadMessages ?
                  <span className="icon-badge rounded-circle unread-notifications" />
                  : null}
              </button> */}
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                  <div className="media d-flex align-items-center">
                    <img src={userAvatar(user && user.Email, 40)} className="user-avatar rounded" alt="User" />
                    {/* <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                      <span className="mb-0 font-small fw-bold">{user && user.Email}</span>
                    </div> */}
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                  <Dropdown.Item className="fw-bold" onClick={() => props.history.push(Routes.Profile.path)}>
                    <FontAwesomeIcon icon={faUserCircle} className="me-2" /> Perfil
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold" onClick={() => toggleShare()}>
                    <FontAwesomeIcon icon={faCalendar} className="me-2" /> Calendario Global
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold" onClick={() => props.history.push(Routes.Settings.path)}>
                    <FontAwesomeIcon icon={faCog} className="me-2" /> Configuración
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold d-none">
                    <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" /> Messages
                  </Dropdown.Item>


                  <Dropdown.Divider />

                  <Dropdown.Item className="fw-bold"
                    onClick={() => {
                      props.history.push(Routes.SignIn.path)
                    }}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Cerrar Sesión
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <button type="button" className="btn btn-sm" onClick={() => {
                toggleNewsUpdate();
              }}>
                <i className="fas fa-bullhorn"></i>

              </button>
            </Nav>
          </div>
        </Container>
      </Navbar>
    </>
  );
};
