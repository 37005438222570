import moment from "moment";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import API from "../../../common/API";
import { parsedUser } from "../../../common/GetCurrentUser";
import UserItem from "../UserItem";
import AddEditActivity from "./AddEditActivity";

const ActivityListItem = props=>{
    const [item, setItem] = useState( props.item);
    const [showAddEdit, setShowAddEdit] = useState(false);

    const toggleAddEdit = async (opt, mode, reload) => {
        
       
        if (mode === "edit") {
            let ExistingTask = await API.getAction("appointments/details?id=" + opt.id);
            if (ExistingTask.data.status === "ok") {
                opt = ExistingTask.data.response;
                
            }
        }

        setShowAddEdit(!showAddEdit);

        if (reload === true) {
         //   await request();
        }

    }

    const markAsCompleted = async (obj, status) => {
        try {


            let parsed = parsedUser();
            obj.status = status;


            await API.postAction("Appointments/AddEdit?CurrentUserId=" + parsed.ID, obj);
            await props.request();

        }
        catch (ex) {
            console.error(ex);
           
        }
    };

    return (<div className="row mx-0" >
        {showAddEdit ? <AddEditActivity model={item} toggle={toggleAddEdit} show={showAddEdit} mode={"edit"} /> : null}
    <div className="col-auto text-center">
        <div className="btn-group">
            {item.status === 0 ?
                <button className="btn btn-sm " title={"Marcar como completado"}
                    onClick={() => {
                        markAsCompleted(item, 2);
                    }}>
                    <i className="far fa-circle" />
                </button>
                :
                <button className="btn btn-sm " title={"Marcar como pendiente"}
                    onClick={() => {
                        markAsCompleted(item, 0);
                    }}>
                        {item.status === 1? <i className="fas fa-minus-circle"></i> : <i className="fas fa-check-circle text-success"></i>}
                </button>
            }

            <Dropdown>
                <Dropdown.Toggle split variant="link" id="dropdown-split-basic" className="btn-sm">
                    <i className="fas fa-caret-down"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => {
                        markAsCompleted(item, 0);
                    }}>Pendiente</Dropdown.Item>
                    <Dropdown.Item 
                    onClick={()=>{
                        markAsCompleted(item, 1);
                    }}>En Proceso</Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                        markAsCompleted(item, 2);
                    }}>Completada</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

        </div>

    </div>
    <div className="col-11 col-md-5 clickable text-truncate" onClick={() => {
        toggleAddEdit(item, "edit")
    }} >
        <small className="text-muted">
        {item.project? <b>{item.project.color? <i className="fa fa-circle" style={{color: item.project.color}}></i>:null} {item.project.name}</b>:null} {moment(item.created).fromNow()} 
        </small><br />
        {item.date ? <small title={moment(item.date).format()}><b>{moment(item.date).format("MMM DD")} - </b></small> : ""} {item.name}
    </div>
    <div className="col-auto col-md-2">
        {props.withLabel?
        <small className="text-muted">Asignado A</small>:null}
        {item.assignedTo ?
        <UserItem model={item.assignedTo} />
        : null}
    </div>
    <div className="col-2 text-center">
        {item.priority ? item.priority : "-"}
    </div>
    <div className="col-1 text-left text-truncate" title={item.invitees}>
        {props.withLabel?
        <small className="text-muted">Invitado<br/></small>:null}
        {item.invitees ?
        item.invitees
        : null}
    </div>
    <div className="col-auto ms-auto text-center">
        <button className="btn btn-sm my-2">
            <i className="fas fa-ellipsis-v"></i>
        </button>
    </div>
    <div className="col-12">
        <hr />
    </div>
</div>)
}

export default ActivityListItem;