import React from 'react';

const UserItem = props => {
    let initials = props.model.firstName.substring(0,1)+props.model.lastName.substring(0,1);
    let urlImg = "https://via.placeholder.com/22/3f67fa/ffffff/?text=" + initials;
    let fullName = props.model.firstName + " " + props.model.lastName;
    return (<div className='userItem text-truncate' title={fullName}>
        <img src={urlImg} className="fluid-img d-inline rounded " alt={fullName} title={fullName} ></img>
        <span className="mx-2">{props.model.firstName} {props.model.lastName.substring(0,1) + "."}</span>
    </div>)
}

export default UserItem;