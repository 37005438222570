import React, { useEffect, useState } from "react";
import { SpreadsheetComponent } from "@syncfusion/ej2-react-spreadsheet";

import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import moment from "moment";

import { ProperBasePath, ProperBasePathAPI } from "./helper";

const SpreadsheetContainer = (props) => {
  const { id } = useParams();
  const apiBasePath = ProperBasePathAPI();
  const spreadsheetRef = React.useRef(null);
  const [fileSeleted, setFileSelected] = useState(null);
  const [fileName, setfileName] = useState(
    !id || !fileSeleted ? "Untitled " + moment().format() : setFileSelected.name
  );

  const beforeSave = (args) => {
    console.log(args, fileSeleted);
    // setCustomParams({ customParams: 'you can pass custom params in server side' });
    //args.customParams = customParams; // you can pass the custom params

    args.fileName = fileSeleted.path;
    args.isFullPost = false;
    //args.needBlobData = true
  };

  useEffect(() => {
    //Now let's try to get the file and open
    let fullPath = `${ProperBasePath()}files/${props.file.path}`;
    console.log(fullPath, props.file);
    setfileName(props.file.name);
    const fetchData = async () => {
      const response = await fetch(fullPath); // fetch the remote url
      const fileBlob = await response.blob(); // convert the excel file to blob
      const file = new File([fileBlob], props.file.name); //convert the blob into file
      let spreadsheet = spreadsheetRef.current;
      if (spreadsheet) {
        spreadsheet.open({ file }); // open the file into Spreadsheet
      }
    };
    fetchData();
    setFileSelected(props.file);
  }, [props.file]);

  const loadUI = () => {
    return (
      <Container fluid>
        <Row>
          <Col xs={12} style={{ height: "78vh" }}>
            <SpreadsheetComponent
              ref={spreadsheetRef}
              dialogBeforeOpen={(args) => {
                console.log(args);
                if (args.dialogName === "Save As") {
                  args.cancel = true;
                  let spreadsheet = spreadsheetRef.current;
                  spreadsheet.save();
                }
              }}
              beforeSave={beforeSave}
              allowSave={true}
              allowOpen={true}
              saveUrl={`${apiBasePath}file/SaveExcel`}
              openUrl={`${apiBasePath}file/OpenSpreadsheet`}
            />
          </Col>
        </Row>
      </Container>
    );
  };
  if (props.modal) {
    return (
      <Modal show={true} onHide={props.toggle} size="xl" className="full">
        <Modal.Body>{loadUI()}</Modal.Body>
      </Modal>
    );
  }
  return <>{loadUI()}</>;
};

export default SpreadsheetContainer;
