
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import API from '../../common/API';
import AddEditPost from './AddEditPost';
import { MentionsInput, Mention } from 'react-mentions'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { parsedUser } from '../../common/GetCurrentUser';
import NOCONTENT from '../../assets/img/illustrations/no_posts.png';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.bubble.css';
import 'quill-mention';

import 'quill-mention/dist/quill.mention.css';

import { v4 as uuidv4 } from 'uuid';
import { onlyHTMLTags } from '../../common/helpers';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';

const PostList = props => {
    const [state, setState] = useState({
        Posts: [],
        UI: {
            showAddEdit: false,
            SelectedItem: null
        },
        user: {}

    })

    const theUser = parsedUser();
    useEffect(() => {
        //Load remote records 
        setState({
            ...state,
            user: theUser
        })
    }, []);

    useEffect(() => {

        request();
    }, [props.projectId]);

    const request = async () => {
        let theUser = parsedUser();
        let query = "OrganizationId=" + theUser.OrganizationId;

        if (props.projectId) {
            query += "&ProjectId=" + props.projectId;
        }

        if (query.length === 0) {
            return;
        }
        let requestAPI = await API.getAction("Post/List?" + query);

        if (requestAPI.data.status === "ok") {
            setState({
                ...state,
                Posts: requestAPI.data.response
            })

        }
    };
    const toggle = async (obj, reload) => {
        setState({
            ...state,
            UI: {
                ...state.UI,
                SelectedItem: obj,
                showAddEdit: !state.UI.showAddEdit
            }
        })

        if (reload) {
            await request();
        }
    }

    const deleteConfirm = async (obj) => {
        confirmAlert({
            title: 'Confirm',
            message: 'Are you sure to delete ' + obj.title + "?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {

                        await API.getAction("Post/Delete?id=" + obj.id);
                        await request();
                    }
                },
                {
                    label: 'No',
                    onClick: () => alert('Click No')
                }
            ]
        });
    }

    const handleSubmitPost = async () => {
        let user = parsedUser();
        if (formik.values.content.length === 0) {
            return;
        }

        let obj = {
            id: uuidv4(),
            created: moment().format("YYYY-MM-DD HH:mm:ss"),
            title: "",
            content: onlyHTMLTags(formik.values.content) ? "" : formik.values.content,
            projectId: props.projectId,
            organizationId: user.OrganizationId,
            creatorID: user.ID,

        }

        let requestAPI = await API.postAction("Post/AddEdit", obj);
        if (requestAPI.status === 200) {
            formik.setFieldValue("content", '');
            await request();
        }
    }
    const users = [
        {
            id: 'walter',
            display: 'Walter White',
        },
        {
            id: 'pipilu',
            display: '皮皮鲁',
        },
        {
            id: 'luxixi',
            display: '鲁西西',
        },
        {
            id: 'satoshi1',
            display: '中本聪',
        },
        {
            id: 'satoshi2',
            display: 'サトシ・ナカモト',
        },
        {
            id: 'nobi',
            display: '野比のび太',
        },
        {
            id: 'sung',
            display: '성덕선',
        },
        {
            id: 'jesse',
            display: 'Jesse Pinkman',
        },
        {
            id: 'gus',
            display: 'Gustavo "Gus" Fring',
        },
        {
            id: 'saul',
            display: 'Saul Goodman',
        },
        {
            id: 'hank',
            display: 'Hank Schrader',
        },
        {
            id: 'skyler',
            display: 'Skyler White',
        },
        {
            id: 'mike',
            display: 'Mike Ehrmantraut',
        },
        {
            id: 'lydia',
            display: 'Lydìã Rôdarté-Qüayle',
        },
    ]

    const formik = useFormik({
        initialValues:
        {
            content: ""
        },
        //validationSchema,
        onSubmit: handleSubmitPost,
    });
    return (
        <>
            {state.UI.showAddEdit ?
                <AddEditPost toggle={toggle} show={state.UI.showAddEdit} model={state.UI.SelectedItem} />
                : null}

            {state.Posts.length > 0 ?
                <div className='postContainer' style={{ height: "auto" }}>
                    <div className='postListcontainer pe-4' style={{

                        height: "70vh"

                    }}>
                        {state.Posts.map((item, index) => {
                            let itemParsed = item;
                            let combinedName = item.creator.firstName + " " + item.creator.lastName;
                            return (
                                <div className='row mb-2' key={index}>
                                    <div className='col-auto'>
                                        <img
                                            title={item.creator.firstName}
                                            src={"https://via.placeholder.com/40/3f67fa/ffffff/?text=" + item.creator.firstName.substring(0, 1)} className="rounded img-fluid" />
                                    </div>
                                    <div className='col'>
                                        <div className='card shadow-sm  '>
                                            <div className='card-body px-4 py-2'>
                                                <div className="row">
                                                    <div className="col-auto">

                                                        <span className="small text-gray">
                                                            <b>{combinedName}</b> &nbsp;
                                                            <i className="fa fa-clock-o mr-1"></i>{moment(item.created).fromNow()}</span>
                                                        {item.title ?
                                                            <h2 className="h5 mb-0">{item.title}</h2>
                                                            : null}

                                                    </div>
                                                    <div className="col ml-auto text-right">
                                                        <div className={"btn-group " + (item.creatorId === theUser.ID ? "" : "d-none")}>
                                                            <button className="btn btn-primary btn-sm" onClick={() => toggle(itemParsed)}>
                                                                <i className="fa fa-edit" />
                                                            </button>
                                                            <button className="btn btn-primary btn-sm" onClick={() => deleteConfirm(itemParsed)}>
                                                                <i className="fa fa-trash text-danger" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className="text-small mt-2 font-weight-light" dangerouslySetInnerHTML={{ __html: item.content }} ></div>
                                                    </div>

                                                </div>

                                            </div>
                                            {/* <div className='card-footer px-4 py-2'>
                                                <i className='fa fa-reply'></i> &nbsp; Responder
                                    
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                            );
                        })}
                        <div className='row'>
                            <div className='col-auto'>
                                <img
                                    src={"https://via.placeholder.com/40/3f67fa/ffffff/?text=NA"} className="rounded img-fluid" style={{ opacity: "0" }} />
                            </div>
                            <div className='col'>
                                {/* <button className='btn btn-primary my-4' onClick={() => toggle({
                                    id: "",
                                    created: "",
                                    title: "",
                                    content: "",
                                    projectId: props.projectId,
                                    organizationId: state.user.OrganizationId
                                })}>
                                    Crear Post
                                </button> */}
                            </div>
                        </div>
                    </div>

                    <div className='postResponseContainer mx-2' style={{

                        height: "calc(100% - 68vh)"

                    }}>
                        <div className='card shadow-sm'>
                            <div className='card-body p-0'>


                                <Form onSubmit={formik.handleSubmit}>
                                    <div className='row mx-0'>
                                        {/* <div className='col-auto text-center'>
                                        <button className='btn btn-sm mt-1'>
                                            <i className="fas fa-microphone"></i>
                                        </button>
                                    </div> */}
                                        <div className='col '>
                                            {/* <textarea className='form-control' value={newPost}
                                            name="content"
                                            rows={1}
                                            onChange={e => {
                                                let onlyTags = onlyHTMLTags(e.target.value);
                                                setNewPost(onlyTags ? "" : e.target.value)
                                            }}
                                        ></textarea> */}

                                            {/* <MentionsInput value={formik.values.content}
                                                className="form-control"
                                                onKeyUp={e => {
                                                    console.log("key press", e, e.key);
                                                    // if (e.key === "Enter" || e.keyCode === 13) {
                                                    //     //     e.preventDefault();
                                                    //     handleSubmitPost();
                                                    // }
                                                }}
                                                onChange={(event, newValue, newPlainTextValue, mentions) => {
                                                    formik.setFieldValue("content", newValue)
                                                }}>
                                                <Mention
                                                    trigger="@"
                                                    data={users}
                                                //  renderSuggestion={this.renderUserSuggestion}
                                                />
                                            </MentionsInput> */}

                                            <ReactQuill value={formik.values.content}
                                                name="content"
                                                theme="bubble"
                                                onKeyUp={e => {
                                                    if (e.key === "Enter" || e.keyCode === 13) {
                                                        //     e.preventDefault();
                                                        handleSubmitPost();
                                                    }
                                                }}
                                                onChange={e => {
                                                    let onlyTags = onlyHTMLTags(e);
                                                    formik.setFieldValue("content", onlyTags ? "" : e)
                                                }}


                                            />
                                        </div>
                                        <div className='col-auto  text-center px-0'>
                                            <button className='btn btn-sm m-2'
                                                type='submit'>
                                                <i className="fas fa-paper-plane"></i>
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
                : <div className='col-4 mx-auto text-center'>
                    <img src={NOCONTENT} className="img-fluid" />
                    <br />
                    <p>Aún no hay contenido</p>
                    <p>
                        <button className='btn btn-primary' onClick={() => toggle({
                            id: "",
                            created: "",
                            title: "",
                            content: "",
                            projectId: props.projectId,
                            organizationId: state.user.OrganizationId
                        })}>
                            Crear Post
                        </button>
                    </p>
                </div>}

        </>)
}

export default PostList