import swal from "sweetalert"
import Settings from "../../common/Settings"

export const typeIconEquivalent = item => {
  console.log(item, "icon type")
  let fontReference = ""
  switch (item.type) {
    case "file":
    case "link":
      fontReference = fileIcon(item)
      break

    case "folder":
      fontReference = "fa fa-folder"
      break
    default:
      fontReference = fileIcon(item)
      break
  }
  console.log("Icon selected", fontReference)
  return fontReference
}
export const ProperBasePath = () => {
  return (window.location.toString().includes("bluesteel.do") ? Settings.BasePathBluesteel : Settings.BasePath);
}
export const ProperBasePathAPI = () => {
  return (window.location.toString().includes("bluesteel.do") ? Settings.BasePathForAPIBluesteel : Settings.BasePathForAPI);
}

export const openInNewTab = item => {
  console.log(item);
  let extension = item.path.split(".").pop()
  // https://docs.google.com/viewerng/viewer?url=https://app.plnify.com/files/Baezrosario+-Inicio.pptx
  let path =
    item.type === "link"
      ? item.path
      : item.type === "file" &&
        (extension.includes("pdf") ||
          extension.includes("doc") ||
          extension.includes("docx") ||
          extension.includes("xls") ||
          extension.includes("xlsx") ||
          extension.includes("pptx") ||
          extension.includes("ppt"))
        ? "https://docs.google.com/viewerng/viewer?url=" +
        `${ProperBasePath()}files/` +
        item.path
        : `${ProperBasePath()}files/` + item.path
  const newWindow = window.open(path, "_blank", "noopener,noreferrer")
  if (newWindow) newWindow.opener = null
}

export const copyToClipboard = item => {
  navigator.clipboard.writeText(
    item.type === "link"
      ? item.path
      : `${ProperBasePath()}files/${item.path}`
  )
  swal("Copied to clipboard!")
}

export const fileIcon = item => {
  if (item.path === undefined) {
    return ""
  }
  console.log("Icon " + item.type, item)
  if (item.type === "link") {
    if (item.path.includes("dropbox")) {
      return "fab fa-dropbox dropboxColor"
    } else if (item.path.includes("google")) {
      return "fab fa-google googleDriveColor"
    }
    {
      return "fas fa-globe  text-secondary"
    }
  } else if (item.path) {
    if (item.type === "Folder") {
      return "fas fa-folder"
    }
    switch (item.path.split(".").pop()) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return "far fa-file-image text-secondary "
        break
      case "pdf":
        return "far fa-file-pdf text-danger"
        break
      case "doc":
      case "docx":
        return "far fa-file-word text-primary"
        break
      case "xls":
      case "xlsx":
        return "far fa-file-excel text-success"
      case "ppt":
      case "pptx":
        return "far fa-file-powerpoint text-warning"
      default:
        return "far fa-file text-secondary"
    }
  } else {
    switch (item.type) {
      case "Folder":
        return "fas fa-folder"

      default:
        return "far fa-file"
    }
  }
}
