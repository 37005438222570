import React, { useContext, useEffect, useState } from 'react';

import { Modal, Button, Col, Row, Form } from 'react-bootstrap';


import 'react-bootstrap-typeahead/css/Typeahead.css';
import { parsedUser } from '../../common/GetCurrentUser';
import { userAvatar } from '../../common/helpers';
import { SocketContext } from '../../Context/socket';
import ChatCallNotificationSound from '../../assets/sound/ring.mp3';
import { Routes } from '../../routes';
import ActiveCall from './ActiveCall';

//Component
const AcceptRequestCall = props => {

    const audioCall = new Audio(ChatCallNotificationSound);
    const [acceptCall, setAcceptCall] = useState(false);
    const [userCalled, setUserCalled] = useState(null);
    const currentUser = parsedUser();
    const [cancelCall, setCancelCall] = useState(false);//This value needs to be update 
    const [userAnswerTheCall, setUseranswerTheCall] = useState(false);
    console.log("props received on activity", props);
    const socket = useContext(SocketContext);

    const stopSound = () => {
        audioCall.pause();
        audioCall.currentTime = 0;
    }

    useEffect(() => {

        socket.on("OnAcceptToJoinCall", data => {
            console.log("on accept ");
            stopSound();
            setAcceptCall(true);
            setUseranswerTheCall(true);
            //   window.open(Routes.Meeting.clean + data);
            //  props.toggle();
        });
        socket.on("OnReceiveDeclineCall", data => {
            stopSound();
            console.log("the user decline to join");
            props.toggle();
        });
        setTimeout(() => {
            if (acceptCall !== true) {


                setCancelCall(true);
                if (!userAnswerTheCall) {
                    props.toggle();
                }
            }
        }, 50000)

        permissions();
        try {
            audioCall.play();
        } catch (ex) {
            alert("Probablemente necesite habilitar el sonido en la applicación")
        }
    }, [props.show])

    const permissions = async () => {
        const permissionDescriptors = [
            { name: 'camera' },
            { name: 'microphone' },
        ];

        const permissions = await Promise.all(permissionDescriptors.map(async descriptor => ({
            descriptor,
            status: await navigator.permissions.query(descriptor),
        })));

        for (const { descriptor, status } of permissions) {
            console.log(
                descriptor.name, // 'camera' | 'microphone'
                status.state, // 'granted' | 'denied' | 'prompt'
            );
        }
    }


    return (
        <Modal show={props.show} size='sm' backdrop={"static"}>

            <Modal.Body>
                <Row>

                    <Col xs={12}>
                        Llamada: <br />
                        {!props.callRequestedBy ?
                            <b>{props.call.conversation.name}</b> :
                            <b>{props.callRequestedBy.firstName + " " + props.callRequestedBy.lastName}</b>}
                    </Col>
                </Row>
                {acceptCall ?
                    <ActiveCall  {...props}/>
                    : null}
            </Modal.Body>
            <Modal.Footer>
                <Row style={{ width: "100%" }}>
                    {props.call && props.callRequestedBy ?
                        <>
                            <Col xs="6">
                                <button className='btn btn-danger btn-block'
                                    onClick={() => {
                                        socket.invoke("DeclineToJoinCall", props.call.conversation.id);
                                        props.toggle();
                                    }}>
                                    Cancelar
                                </button>
                            </Col>
                            <Col xs="6">
                                <button className='btn btn-primary btn-block' onClick={() => {
                                    socket.invoke("AcceptToJoinCall", props.call.conversation.id);
                                }}>
                                    Aceptar
                                </button>
                            </Col>
                        </> :
                        <>
                            <Col xs="12">
                                <button className='btn btn-danger btn-block'
                                    onClick={() => {
                                        socket.invoke("DeclineToJoinCall", props.call.conversation.id)
                                        props.toggle();
                                    }}>
                                    Cancelar
                                </button>
                            </Col>
                        </>}
                </Row>

            </Modal.Footer>

        </Modal>
    )
}

export default AcceptRequestCall;