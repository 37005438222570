
import React, { useEffect, useState } from "react";
import { Row } from '@themesberg/react-bootstrap';

import CalendarView from "../Common/Agenda/CalendarView";
import ActivityList from "../Common/Agenda/ActivityList";
import { parsedUser } from "../../common/GetCurrentUser";

export default (props) => {


  const [selectedOption, setSelectedOption] = useState("calendar");
  const toggleViewMode = mode => {
    setSelectedOption(mode)
  }



  useEffect(() => {
  }, [])
  return (<>
    {/* <NewsUpdates /> */}
    <Row>
      <div className="col-12">

        {selectedOption === "calendar" ?
          <CalendarView toggleViewMode={toggleViewMode} unified full />
          : null}

        {selectedOption === "list" ?
          <ActivityList toggleViewMode={toggleViewMode} unified />
          : null}

      </div>
    </Row>
  </>
  );
};
