import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
import { parsedUser } from "../../common/GetCurrentUser";
import API from "../../common/API";
import { db } from "../../common/db";
import { Routes } from "../../routes";
import no_content from '../../assets/img/illustrations/no_content.svg';
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";

const Notes = props => {

    const { id } = useParams();

    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const user = parsedUser();
    const [menu, setMenu] = useState([])
    const [selectedMenu, setSelectedMenu] = useState(null);
    const projectRequest = async (lastSelected) => {
        let user = parsedUser();
        let requestAPI = await API.getAction(
            "projects/List?OrganizationId=" + user.OrganizationId
        );
        if (requestAPI.data.status === "ok") {
            await db.projects.bulkPut(requestAPI.data.response);
            await bindLocal();
        }
    };

    const requestNotes = async (lastSelected) => {
        let user = parsedUser();
        let requestAPI = await API.getAction(
            "notes/List?projectId=" + selectedProject.id
        );
        if (requestAPI.data.status === "ok") {
            setMenu(requestAPI.data.response);
            if (lastSelected) {
                setSelectedMenu(requestAPI.data.response[requestAPI.data.response.length - 1])
            }

        }
    };

    const bindLocal = async () => {

        if (!user) {
            window.location = Routes.SignIn.path;
        }
        let projectsLocal = await db.projects
            .where({
                organizationId: user.OrganizationId,
            })
            .toArray();
        if (id) {
            setSelectedProject(projectsLocal.filter(x => x.id === id)[0]);
        }
        setProjects(projectsLocal);
    }

    const addNote = async () => {
        let obj = {
            id: uuidv4(),
            title: '',
            content: '',
            created: moment().format(),
            createdId: user.ID,
            projectId: selectedProject.id
        }
        let newMenu = menu;
        newMenu.push(obj);
        setMenu(newMenu)

        let requestAPI = await API.postAction(
            "notes/addedit", obj
        );
        if (requestAPI.data.status === "ok") {
            await requestNotes(true);
        }
    }
    const saveNote = async () => {

        if (selectedMenu) {
            let requestAPI = await API.postAction(
                "notes/addedit", selectedMenu
            );
        }

    }

    // if (document.hasFocus()) {
    //     console.log("in focus", new Date())
    //     saveInterval = setInterval(() => { saveNote() }, 5000);
    // } else {
    //     console.log("loss focus", new Date())
    //     saveNote();
    //     clearInterval(saveInterval);
    // }

    useEffect(() => {
        let timeout = setTimeout(() => {
            saveNote()
        }, 2000)

        return () => clearTimeout(timeout);
    }, [selectedMenu])
    useEffect(() => {
        if (selectedProject) {
            requestNotes();
        }
    }, [selectedProject]);

    useEffect(() => {
        projectRequest();
    }, [])

    return (<Row className="mx-0">
        <Col xs={12} md={3} className="bg-light" style={{ height: '100vh' }}>
            <Row>
                <Col xs={12} className="mt-4">
                    <select className="form-control" onChange={e => {
                        setSelectedProject(projects.filter(x => x.id === e.target.value)[0])
                    }}
                    value={selectedProject? selectedProject.id : ''}
                    >
                        <option value="">Select project</option>
                        {projects && projects.map((item, index) => {
                            return (<option key={index} value={item.id}>{item.name}</option>)
                        })}
                    </select>
                </Col>
            </Row>
            <ul style={{ height: '70vh' }} className="notesMenu my-4 list-unstyled">
                {menu.map((item, index) => {
                    var element = item;
                    return (<li key={index}
                        className={selectedMenu !== null && element.id === selectedMenu.id ? "active" : ""}
                        onClick={() => {
                            setSelectedMenu(element)
                        }}
                    >
                        <label>{item.title}</label>
                        <br />
                        <small className="text-muted">{moment(item.created).format("MMM DD, YYYY")}</small>
                    </li>)
                })}

            </ul>
            <Row className="">
                <Col xs={12}>
                    <button type="button " className="btn btn-block btn-primary"
                        disabled={!selectedProject}
                        onClick={() => addNote()}>Agregar</button>
                </Col>
            </Row>
        </Col>
        <Col xs={12} md={9} className="bg-white" style={{ height: '100vh' }}>
            {selectedMenu ?
                <div>
                    <input type="text" className="form-control title-text" placeholder="title"
                        name="title"
                        value={selectedMenu.title}
                        onChange={e => {
                            setSelectedMenu({
                                ...selectedMenu,
                                title: e.target.value
                            })

                            let menuSet = menu;
                            let index = menuSet.indexOf(menuSet.filter(x => x.id === selectedMenu.id)[0]);
                            if (index >= 0) {
                                menuSet[index].title = e.target.value;
                                setMenu(menuSet);
                            }
                        }}
                    ></input>
                    <hr />
                    <ReactQuill

                        value={selectedMenu.content}
                        name="content"
                        onChange={(e) => {
                            //    debugger
                            setSelectedMenu({
                                ...selectedMenu,
                                content: e
                            })
                        }}
                    />
                </div>
                : <div className=" text-center my-4">
                    <img src={no_content} className="mt-4 pt-4 img-fluid" style={{ maxHeight: '200px' }} alt=''></img>
                    <h5 className="my-4">No content</h5>
                </div>}
        </Col>
    </Row>)
}

export default Notes