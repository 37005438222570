import React from 'react';
import { Col,Row } from '@themesberg/react-bootstrap';
import ProfileCardWidget from './ProfileCardWidget';

const Profile = props=>{
    return (<>
     <Col xs={12} xl={4}>
          <Row>
            <Col xs={12}>
              <ProfileCardWidget />
            </Col>
            <Col xs={12}>
              {/* <ChoosePhotoWidget
                title="Select profile photo"
                photo={Profile3}
              /> */}
            </Col>
          </Row>
        </Col>
    </>);
}

export default Profile;