import React, { useEffect, useState } from "react";
import API from "../../common/API";
import { parsedUser } from "../../common/GetCurrentUser";
import { Routes } from "../../routes";
import { BarChart, SalesValueChartphone } from "../Charts";
import OverviewTaskByAssignee from "./OverviewTaskByAssignee";
import OverviewTaskByProject from "./OverviewTaskByProject";

const DashboardOverview = props => {
    const [state, setState] = useState({
        completedTasks: 0,
        pendingTasks: 0,
        totalTasks: 0,
        overdueTasks: 0
    })
    const request = async () => {
        let theUser = parsedUser();
        if (theUser.OrganizationId === undefined) {
            window.location = Routes.SignIn.path;
        }
        let requested = await API.getAction("dashboard/general?organizationid=" + theUser.OrganizationId);
        if (requested.data.status === "ok") {
            setState(requested.data.response);
        }
    }

    const [newsUpdates, setNewsUpdates] = useState([])
    const requestNewsUpdate = async () => {
        debugger;
        let theUser = parsedUser();

        let requested = await API.getAction("NewsUpdates/NotSeen?userId=" + theUser.ID);
        if (requested.data.status === "ok") {
            setNewsUpdates(requested.data.response)
            debugger;
        }
    }
    useEffect(() => {
        let theUser = parsedUser();
        console.log(theUser)
        if (!theUser) {
            window.location = Routes.SignIn.path;
            return;
        }
        if (window.location.toString().includes("http:")) {
            // window.location= window.location.replace("http:", "https:");
        }

        request();
        requestNewsUpdate();
    }, []);

    const pushNotificationPermissionsRequest = async () => {
        let response = await Notification.requestPermission();
        if (response === "granted") {
            new Notification("Hello", {
                body: "This is more text",


            });
        } else {
            alert(response)
        }
    }

    return (<div className="row mt-4">
        <div className="col-6 col-md-3 mb-2">
            {/* <button className="btn btn-primary" onClick={()=>{

        pushNotificationPermissionsRequest();
            }}>
                Allow notifications
            </button> */}
            <div className="card shadow-sm">
                <div className="card-header p-2">
                    <b>Actividades Completadas</b>
                </div>
                <div className="card-body p-2">
                    {state.completedTasks}
                </div>
            </div>
        </div>
        <div className="col-6 col-md-3  mb-2">
            <div className="card shadow-sm">
                <div className="card-header p-2">
                    <b>Actividades Sin Iniciar</b>
                </div>
                <div className="card-body p-2">
                    {state.pendingTasks}
                </div>
            </div>
        </div>
        <div className="col-6 col-md-3  mb-2">
            <div className="card shadow-sm bg-danger text-white">
                <div className="card-header p-2">
                    <b>Actividades Atrasadas</b>
                </div>
                <div className="card-body p-2">
                    {state.overdueTasks}
                </div>
            </div>
        </div>
        <div className="col-6 col-md-3 mb-2">
            <div className="card shadow-sm">
                <div className="card-header p-2">
                    <b>Total de Actividades</b>
                </div>
                <div className="card-body p-2">
                    {state.totalTasks}
                </div>
            </div>
        </div>

        <div className="col-12 col-md-6 mb-2">
            <OverviewTaskByProject />
        </div>

        <div className="col-12 col-md-6 mb-2">
            <OverviewTaskByAssignee />
        </div>


    </div>)
}

export default DashboardOverview;