
import React, { useEffect, useState } from 'react';

import { copyToClipboard, typeIconEquivalent } from './helper';
import API from '../../common/API';
import { Modal } from 'react-bootstrap';
import { parsedUser } from '../../common/GetCurrentUser';

function ListFolders(props) {

    let theUser = parsedUser();
    const [fileCollection, setFiles] = useState([])
    const [state, setState] = useState({
        DestinationFolder: null,
        CurrentFolderPath: [],
        CurrentUser: theUser,

        UI: {
            isLoading: false,
        },

        SelectedFolder: "/",
    });

    useEffect(() => {

        requestFolderRemote();

    }, []);

    const moveHandler = async () => {


        let items = [];
        console.log("files to process", props.parentState)
        debugger;
        props.parentState.SelectedFiles.forEach(item => {
            items.push({
                id: item,
            })
        })
        console.log("destination ", state.DestinationFolder, items);
        setState({
            ...state,
            UI: {
                ...state.UI,
                isLoading: state.UI.isLoading
            }
        })


        //We have to sent to api    
        const request = await API.postAction(
            "Files/Move?Target=" + (state.DestinationFolder.id !== undefined ? state.DestinationFolder.id : ""), items
        );


        setState({
            ...state,
            UI: {
                ...state.UI,
                isLoading: state.UI.isLoading
            }
        })
        props.toggle(true);
    }

    const requestFolderRemote = async () => {
        try {
            setState({
                ...state,
                UI: {
                    ...state.UI,
                    isLoading: !state.UI.isLoading
                }
            })
            let query = "OnlyFolder=true";
            query += "&UserID=" + state.CurrentUser.ID +
                (state.SelectedFolder !== undefined && state.SelectedFolder !== "/" ? "&FolderId=" + state.SelectedFolder : "&FolderId=");

            if (props.projectId) {
                query += "&projectId=" + props.projectId
            }

            const request = await API.getAction("Files/List", query);

            setState({
                ...state,
                UI: {
                    ...state.UI,
                    isLoading: !state.UI.isLoading
                }
            })

            let response = request.data;
            if (response.status === "ok") {
                setFiles(response.response);


            }
        } catch (ex) {
            console.error(ex);
            setState({
                ...state,
                UI: {
                    ...state.UI,
                    isLoading: false
                }
            })
        } finally {
            setState({
                ...state,
                UI: {
                    ...state.UI,
                    isLoading: false
                }
            });

        }
    }


    const enterFolder = item => {
        setState({
            ...state,
            SelectedFile: null,
            SelectedFolder: item.id
        });
        let copy = state.CurrentFolderPath;
        var exist = copy.some(x => x.id === item.id);
        if (exist) {
            //If exist we must go over the array and create a new one until this record
            let newArray = [];
            for (var i = 0; i < copy.length; i++) {
                newArray.push(copy[i]);
                if (copy[i].id === item.id) {
                    break;
                }
            }

            copy = newArray;
        } else {
            copy.push({ id: item.id, name: item.name });
        }

        setState({
            ...state,
            CurrentFolderPath: copy
        });


        requestFolderRemote();
    }

    const requestFilesUI = () => {
        let files = [];
        console.log("the files to process", fileCollection);
        fileCollection.forEach( (item, index) => {

            let newFile = 
                <div className={(item.id === state.SelectedFolder ? "active " : "") + "row mx-0"} key={index}>

                    <div className="col-auto text-center">
                        <i style={item.color ? { color: item.color } : item.type === "folder" ? { color: "#E6E8EA" } : null} className={typeIconEquivalent(item)  } />
                    </div>
                    <div className="col-10">
                        <span className="title clickable mt-1"
                            onDoubleClick={() => enterFolder(item)}
                            onClick={() => {
                                setState({
                                    ...state,
                                    SelectedFolder: item.id,
                                    DestinationFolder: item
                                });

                            }}>
                            {item.name}
                        </span>
                    </div>

                    <div className="col-12">
                        <hr className=" my-1 " />
                    </div>
                </div>
            ;
            files.push(newFile);
        });

        return (files);
    };

    return (
        <Modal show={true} onHide={props.toggle} size='md'>
            <Modal.Header closeButton>
                <Modal.Title>Carpeta</Modal.Title>
                <small>Haga doble click para entrar en las carpetas</small>
            </Modal.Header>
            <Modal.Body>
                <div className="card pickFolders">
                    <div className="card-body modalScrollable">

                        <div className="row">
                            <div className="col-10">
                                {/*NAVIGATION */}
                                {state.CurrentFolderPath.length > 0 ?
                                    <nav aria-label="breadcrumb" className="mb-2">
                                        <ol className="breadcrumb m-0 p-0">

                                            {state.CurrentFolderPath.map(path => {
                                                console.log(path);
                                                return (path.id === "/" ?
                                                    <li key={path.id} className="breadcrumb-item clickable" onClick={() => enterFolder(path)}><i className="fa fa-home-alt text-secondary"></i></li> :
                                                    <li key={path.id} className="breadcrumb-item clickable " onClick={() => enterFolder(path)}>{path.name}</li>);

                                            })}
                                        </ol>
                                    </nav>
                                    : null
                                }
                                {/*END NAVIGATION */}
                            </div>
                            <div className="col-2">
                                {state.UI.isLoading ?
                                    <div className="spinner-border spinner-border-sm ml-2 " role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    : null
                                }
                            </div>
                            {fileCollection.length > 0 ?
                                requestFilesUI()
                                :
                                <>/</>
                                //Supposed to display empty state
                            }
                        </div>
                    </div>
                    <div className="card-footer text-right">

                        {state.SelectedFolder !== undefined && state.SelectedFolder !== null ?
                            <button className="btn btn-sm btn-primary ml-auto" onClick={() => {
                                moveHandler();
                            }}>Mover aqui</button> :
                            null}
                    </div>

                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ListFolders;