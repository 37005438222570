import React, { useEffect, useState } from "react";
import API from "../../common/API";
import { parsedUser } from "../../common/GetCurrentUser";
import { Routes } from "../../routes";

const Summary = props => {
    const [state, setState] = useState(null)
    const request = async () => {
        let theUser = parsedUser();
        if (theUser.OrganizationId === undefined) {
            window.location = Routes.SignIn.path;
        }
        let requested = await API.getAction("dashboard/quarter?organizationid=" + theUser.OrganizationId);
        //if (requested.data.status === "ok") {
        setState(requested.data);
        //}
    }
    useEffect(() => {
        let theUser = parsedUser();
        console.log(theUser)
        if (!theUser) {
            window.location = Routes.SignIn.path;
            return;
        }
        if (window.location.toString().includes("http:")) {
            // window.location= window.location.replace("http:", "https:");
        }

        request();

    }, []);

    const pushNotificationPermissionsRequest = async () => {
        let response = await Notification.requestPermission();
        if (response === "granted") {
            new Notification("Hello", {
                body: "This is more text",


            });
        } else {
            alert(response)
        }
    }

    return (<div className="row mt-4">
        <div className="col-12 mb-2" style={{
            height: "85vh"
        }}>

            <div className="row"
            >
                <div className="col-12 bg-light">
                    <h4>INICIATIVA</h4>
                    <div className="row">
            <div className="col-auto">
                <i className="fas fa-egg"></i> Incubación
            </div>
            <div className="col-auto">
                <i className="fas fa-briefcase"></i> Desarrollo
            </div>
            <div className="col-auto">
                <i className="fas fa-rocket"></i> Lanzamiento
            </div>
            <div className="col-auto">
                <i className="fas fa-flag-checkered"></i> Hito
            </div>
            <div className="col-auto">

                <i className="fad fa-sticky-note"></i> Sin asignar
            </div>
        </div>
                    <br />
                    {state && state.projects.map((item, index) => {

                        return (
                            <div className="row" key={index}>
                                <div className="col-2">
                                    <div className="card shadow-sm mb-1"
                                        key={index}
                                        style={{ background: item.color ?? "#fff", height: "91px",
                                        color: item.color? "#fff": "#000" }}
                                    >
                                        <div className="card-body">
                                            {item.name}
                                        </div>
                                    </div>
                                </div>
                                {item.years.map((itemYear, indexYear) => {
                                    return (<div className="col" key={indexYear}>
                                        
                                        <div className="row">
                                        {itemYear && itemYear.quarter.map((itemQuarter, itemQuarterIndex) => {
                                            let isEmpty = itemQuarter.development === 0 && 
                                            itemQuarter.launch === 0 &&
                                            itemQuarter.incubation === 0 &&
                                            itemQuarter.milestone === 0 && itemQuarter.unasigned ===0;
                                            return (
                                                <div key={itemQuarterIndex} className="col quarters">
                                                    
                                                    {/* Project container */}
                                                    <div className="card shadow-sm mb-1" 
                                                    
                                        style={{ background: item.color ?? "#fff", height: "91px", opacity: isEmpty? "0.1":"1" ,
                                    color: item.color? "#fff": "#000" }}
                                                    >
                                                        <div className="card-body text-left p-1">
                                                            <div className="row mx-0">
                                                                <div className="col-4  text-center">
                                                                    <i className="fas  text-info fa-egg"></i> {itemQuarter.incubation}
                                                                </div>
                                                                <div className="col-4 text-center">
                                                                    <i className="fas fa-briefcase text-warning "></i> {itemQuarter.development}
                                                                </div>
                                                                <div className="col-4 text-center">
                                                                    <i className="fas text-success  fa-rocket"></i> {itemQuarter.launch}
                                                                </div>
                                                                <div className="col-3">
                                                                    <hr />
                                                                </div>
                                                                <div className="col-6 text-center">
                                                                <label className="mt-2">{itemQuarter.name} {itemYear.yearValue}</label>
                                                                </div>
                                                                <div className="col-3">
                                                                    <hr />
                                                                </div>
                                                                <div className="col-6  text-center">
                                                                    <i className="fas  text-danger fa-flag-checkered "></i> {itemQuarter.milestone}
                                                                </div>
                                                                <div className="col-6 text-center">
                                                                    <i className="fad fa-sticky-note"></i> {itemQuarter.unasigned}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
</div>
                                    </div>)
                                })}
                            </div>)
                    })}


                </div>
                {/* <div className="col-10 quarterContainer" style={{
                    overflowX: "auto",
                    height: "80vh",
                    whiteSpace: "nowrap",
                }}>


                </div> */}
            </div>
        </div>
      
    </div>)
}

export default Summary;