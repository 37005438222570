import React, { useEffect, useState } from 'react';


import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';

import API from '../../common/API';
import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";

import logoCapital from "../../assets/logos/logoShearlyCap.png";
import logoShearly from "../../assets/logos/logoShearly200.png";
import logoBluesteel from "../../assets/logos/logo-bluesteelPlain.png";

import logoInitiative from "../../assets/logos/logoShearlyI.png";
import { userAvatar } from '../../common/helpers';
import GetCurrentUser, { parsedUser } from "../../common/GetCurrentUser";
import AddEditOrganization from './AddEditOrganization';
import Settings from '../../common/Settings';
const SelectOrganization = props => {

  const logoToUse = Settings.ForBluesteel ? logoBluesteel : logoShearly;

  const [organizations, setOrganizations] = useState([]);
  const [state, setState] = useState({
    showEdit: false
  });
  const [user, setUser] = useState(null);

  useEffect(() => {

    setUser(parsedUser());
    requestOrganizations();
  }, [])


  const requestOrganizations = async () => {
    let theUser = parsedUser();



    console.log("render side bar", new Date(), user, theUser)
    if (theUser === null) {
      return;
    }
    let requestAPI = await API.getAction("Organization/OrganizationsWhereTheUserBelong?AccountId=" + theUser.ID);

    if (requestAPI.data.status === "ok") {
      setOrganizations(requestAPI.data.response);
    }
  };

  const changeTheOrganization = async obj => {
    let theUser = parsedUser();
    let requestAPI = await API.getAction("Organization/SetOrganization?AccountId=" + theUser.ID + "&OrganizationId=" + obj);

    if (requestAPI.data.status === "ok") {

      localStorage.setItem("LogedUser", requestAPI.data.response);
      window.location = "/";

    }
  }

  const toggleAddEdit = async (reload) => {
    setState({
      ...state,
      showEdit: !state.showEdit
    });
    if (reload) {
      await requestOrganizations();
    }
  }

  return (
    <main>
      {state.showEdit ?
        <AddEditOrganization
          show={state.showEdit}
          toggle={toggleAddEdit} /> : null}
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <div className=' card shadow-sm'>
            <div className='card-body'>
              <Row className="justify-content-center form-bg-image" >
                {/* <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}> */}

                <Col xs={12} className="text-center my-4">
                  <h2 className='my-4'><b>Bienvenido a Virtual Office!</b></h2>
                  {/* <h3>Nuestra Plataforma de Colaboración</h3> */}
                  <br />
                  <br />
                </Col>
                <Col xs={2}></Col>
                {organizations ?
                  organizations.map((item, index) => (

                    <Col key={index} className=" my-2 text-center " xs={12} md={4}
                    >

                      <img src={item.type === 1 ? logoInitiative : item.type === 2 ? logoCapital : logoBluesteel} className="img-fluid clickable rounded" onClick={() => {
                        changeTheOrganization(item.id);
                      }} />

                    </Col>

                  )
                  ) : null}

                <Col xs={2} >

                </Col>
                <br />
                <br />
              </Row>
              <Row>
                <Col xs={12} className="mt-4 text-center">
                  <a href={Routes.SignIn.path} className="btn btn-primary">
                    Cerrar sesión
                  </a>
                </Col>
              </Row>
              <br />
              <br />   <br />
              <br />
            </div>
          </div>
        </Container>
      </section>
    </main>
  );
}

export default SelectOrganization;

