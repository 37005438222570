import React, { useEffect } from 'react';

import { Modal, Button, Col, Row, Form } from '@themesberg/react-bootstrap';
import { useState } from '@hookstate/core';
import * as yup from 'yup';
import { Formik, useFormik } from 'formik';


import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import API from '../../common/API';
import { makeId, onlyHTMLTags } from '../../common/helpers';
import { parsedUser } from '../../common/GetCurrentUser';
//Validation
const validationSchema = yup.object({
    name: yup.string().required(),


});
//Component
const AddEditProject = props => {
    const state = useState({

        UI: {
            isLoading: false
        },

    })
    useEffect(() => {
        console.log("props received on activity", props);
    }, [])

    const handleSubmit = async obj => {
        
        console.log("values to submit", obj);
        let parsed = parsedUser();
        if (obj.id.length === 0) {
            obj.id = uuidv4();
            obj.created = moment().format("YYYY-MM-DD HH:mm:ss Z");
            obj.ownerId = parsed.ID;
        }
        
        obj.organizationId = parsed.OrganizationId;
        obj.assignedToId = obj.assignedToId ? obj.assignedToId : parsed.ID;
        console.log("rr ", parsed, obj)
        state.UI.isLoading.set(!state.UI.isLoading.value);
        let request = await API.postAction("Projects/AddEdit?CurrentUserId=" + parsed.ID, obj);
        state.UI.isLoading.set(!state.UI.isLoading.value);
        if (request.status === 200) {
            props.toggle(true);
        }
    };

    const formik = useFormik({
        initialValues: props.model ? JSON.parse(JSON.stringify(props.model)) :
            {
                name: "",
                description: "",
                color: "",
                created: new Date(),
                id: "",
                organizationId: null,
                order:null,
                monday: "",
            },
        validationSchema,
        onSubmit: handleSubmit,
    });


    return (
        <Modal show={props.show} onHide={props.toggle} size='md'>
            <Modal.Header closeButton>
                <Modal.Title>Proyecto</Modal.Title>
            </Modal.Header>

            <Form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <Row>

                        <Col xs={8}>
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control type="text"
                                name="name"
                                value={formik.values.name} onChange={formik.handleChange} />
                            {formik.errors.name ? <div className="invalid text-sm">{formik.errors.name}</div> : null}

                        </Col>
                        <div className='col-auto ml-auto'>
                            <Form.Label>Color</Form.Label>
                            <br/>
                            <input type="color" id="color-picker"
                                style={{
                                    //backgroundColor: `${groupColor}`,
                                   // background: "linear-gradient(90deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%)",
                                  //  border: "none",
                                }}
                                onChange={(e) => formik.setFieldValue("color", e.target.value)}

                            />
                          
                            {formik.errors.color ? <div className="invalid text-sm">{formik.errors.color}</div> : null}

                        </div>
                        <div className='col-auto '>
                            <Form.Label>Orden</Form.Label>
                            <br/>
                            <input type="number"
                            className='form-control'
                            value={formik.values.order? formik.values.order :0}
                                style={{
                                    //backgroundColor: `${groupColor}`,
                                   // background: "linear-gradient(90deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%)",
                                  //  border: "none",
                                }}
                                onChange={(e) => formik.setFieldValue("order", e.target.value)}

                            />
                          
                            {formik.errors.color ? <div className="invalid text-sm">{formik.errors.color}</div> : null}

                        </div>
                        <Col xs={12}>
                            <Form.Label>Descripción</Form.Label>
                            <textarea rows={3} className="form-control"
                                value={formik.values.description ? formik.values.description : ""} onChange={e=>{
                                    formik.setFieldValue("description", e.target.value);
                                }}
                            ></textarea>
                            {formik.errors.description ? <div className="invalid text-sm">{formik.errors.description}</div> : null}

                        </Col>
                        <Col xs={12}>
                            <Form.Label>Monday</Form.Label>
                            <input type="text"  className="form-control" name="monday"
                                value={formik.values.monday ? formik.values.monday : ""} onChange={e=>{
                                    formik.setFieldValue("monday", e.target.value);
                                }}
                            />
                            {formik.errors.monday ? <div className="invalid text-sm">{formik.errors.monday}</div> : null}

                        </Col>

                    </Row>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" type="submit" className="btn-sm"
                        disabled={state.UI.isLoading.value}
                    >
                        Guardar
                    </Button>
                </Modal.Footer>
            </Form>


        </Modal>
    )
}

export default AddEditProject;