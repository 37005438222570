import moment from "moment";
import React, { useEffect, useState } from "react";
import API from "../../common/API";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { parsedUser } from "../../common/GetCurrentUser";
// import NOCONTENT from '../../assets/img/illustrations/no_Items.png';

import AddEditMilestone from "./AddEditMilestone";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const MilestoneList = (props) => {
  const [state, setState] = useState({
    Items: [],
    UI: {
      showAddEdit: false,
      SelectedItem: null,
    },
    user: {},
  });

  const [showAddEdit, setShowAddEdit] = useState(false);
  useEffect(() => {
    //Load remote records
    let theUser = parsedUser();
    setState({
      ...state,
      user: theUser,
    });
  }, []);

  useEffect(() => {
    request();
  }, [props.projectId]);

  const request = async () => {
    let theUser = parsedUser();
    let query = "OrganizationId=" + theUser.OrganizationId;

    if (props.projectId) {
      query += "&ProjectId=" + props.projectId;
    }

    if (query.length === 0) {
      return;
    }
    query += "&quantity=100";
    let requestAPI = await API.getAction("milestone/List?" + query);

    if (requestAPI.data.status === "ok") {
      setState({
        ...state,
        Items: requestAPI.data.response,
      });
    }
  };
  const toggle = async (obj, reload) => {
    setShowAddEdit(!showAddEdit);
    setState({
      ...state,
      UI: {
        ...state.UI,
        SelectedItem: obj,
        //showAddEdit: !state.UI.showAddEdit
      },
    });

    if (reload) {
      await request();
    }
  };

  const deleteConfirm = async (obj) => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure to delete " + obj.name + "?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await API.getAction("milestone/Delete?id=" + obj.id);
            await request();
          },
        },
        {
          label: "No",
          onClick: () => alert("Click No"),
        },
      ],
    });
  };

  const typeIcon = (item) => {
    console.log(item);
    if (item.milestoneType === 1) {
      return <i className="fa fa-users fa-2x mt-3" />;
    }
    if (item.milestoneType === 2) {
      return <i className="fas fa-check-double fa-2x mt-3"></i>;
    }
    if (item.milestoneType === 3) {
      return <i className="fas fa-file-alt fa-2x mt-3"></i>;
    }
    if (item.milestoneType === 4) {
      return <i className="fas fa-play fa-2x mt-3"></i>;
    }

    return <i className="fas fa-circle fa-2x mt-3"></i>;
  };

  return (
    <>
      {showAddEdit ? (
        <AddEditMilestone
          toggle={toggle}
          show={showAddEdit}
          model={state.UI.SelectedItem}
        />
      ) : null}
      <div className="row">
        <div className="col-12 text-center">
          <button
            className="btn btn-primary my-2"
            onClick={() => {
              toggle({
                id: "",
                created: "",
                name: "",
                details: "",
                date: moment().format("YYYY-MM-DD"),
                projectId: props.projectId,
                organizationId: state.user.OrganizationId,
                milestoneType: 0,
              });
            }}
          >
            Agregar Hito Historico
          </button>
        </div>
        <div className="col-12 timeline">
          {state.Items.length > 0 ? (
            <VerticalTimeline>
              {state.Items.map((item, index) => {
                return (
                  <VerticalTimelineElement
                    key={index}
                    className="vertical-timeline-element--education"
                    //  contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    contentArrowStyle={{
                      borderRight: "7px solid  rgb(33, 150, 243)",
                    }}
                    date={moment(item.date).format("MMM DD, YYYY")}
                    iconStyle={{
                      background: "rgb(33, 150, 243)",
                      color: "#fff",
                    }}
                    icon={typeIcon(item)}
                  >
                    {/* <h3 className="vertical-timeline-element-title">Creative Director</h3> */}
                    <h4 className="vertical-timeline-element-subtitle">
                      {item.name}
                    </h4>
                    {item.presentation ?
                      <a href={item.presentation} target="_blank">
                        Link
                      </a>
                      : null}
                    <div className="btn-group">
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          toggle(item);
                        }}
                      >
                        <i className="fa fa-edit"></i>
                      </button>
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => {
                          deleteConfirm(item);
                        }}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </div>
                    {item.details ? (
                      <div dangerouslySetInnerHTML={{ __html: item.details }} />
                    ) : null}
                  </VerticalTimelineElement>
                );
              })}
            </VerticalTimeline>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default MilestoneList;
