import { Card, Row, Col } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import API from '../../common/API';
import { epochToDateConverter, FormatMoney } from '../../common/helpers';
import moment from 'moment';
import Pagination from '../Common/Pagination';
import AddEditUser from './AddEditUser';


import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { parsedUser } from '../../common/GetCurrentUser';

import { v4 as uuidv4 } from 'uuid';
import AddEditPassword from './AddEditPassword';
const Users = props => {

    
    const [state, setState] = useState({
        Records: [],
        totalPages: 0,
        totalRecords: 0,
        currentPage: 0,
        recordsPerPage: 50,
        From: null,
        Until: null,
        UI: {
            showAddEdit: false,
            showAddEditPassword: false
        }
    });
    const [selectedItem, setSelectedItem] = useState(null);
    useEffect(() => {
        request();
    }, []);

    const request = async () => {
        let theUser = parsedUser();

        let query = "OrganizationID=" + theUser.OrganizationId;

        query += "&Page=" + state.currentPage;
        query += "&Quantity=" + state.recordsPerPage;

        var request = await API.getAction("Users/List", query);
        console.log("Response from api", request)
        if (request.data.status === "ok") {
            setState({
                ...state,
                Records: request.data.response,
                totalPages: request.data.quantityOfPages,
                totalRecords: request.data.quantityOfRecords
            });

        }
    }

    const onPageChanged = async obj => {
        setState({
            ...state,
            currentPage: obj
        })

        await request();
    }

    const toggle = async (obj, reload) => {
        debugger;
        setState({
            ...state,
            UI: {
                ...state.UI,
                showAddEdit: !state.UI.showAddEdit
            }
        });
        setSelectedItem(obj);
        if (reload !== undefined) {
            request();
        }
    }

    const toggleAddEditPassword = async (obj, reload) => {
        
        setState({
            ...state,
            UI: {
                ...state.UI,
                showAddEditPassword: !state.UI.showAddEditPassword
            }
        });
        setSelectedItem(obj);
        if (reload !== undefined) {
            request();
        }
    }
    const deleteConfirm = async (obj) => {
        debugger
        confirmAlert({
            title: 'Confirm',
            message: 'Are you sure to delete ' + obj.user.firstName + "?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {

                        await API.getAction("Users/Delete?id=" + obj.id);
                        await request();
                    }
                },
                {
                    label: 'No',
                    //onClick: () => alert('Click No')
                }
            ]
        });
    }


    return (<>
        {state.UI.showAddEdit ?
            <AddEditUser toggle={toggle} show={state.UI.showAddEdit} model={selectedItem} />
            : null}
              {state.UI.showAddEditPassword ?
            <AddEditPassword toggle={toggleAddEditPassword} show={state.UI.showAddEditPassword} model={selectedItem} />
            : null}
        <Row>
            <Col xs={12}>
                <Card className="shadow-sm">
                    <Card.Header className="row mx-0 p-1">
                        <div className="col">
                            <Card.Title>
                                Usuarios
                            </Card.Title>
                        </div>
                        <div className="col-4 text-right">
                            <button className="btn btn-sm btn-primary" onClick={() => {

                                let theUser = parsedUser();
                                toggle({

                                    firstName: "", lastName: "",
                                    phone: "", password: "",
                                    email: "",
                                    id: uuidv4(),
                                    currentUserId: theUser.ID,
                                    organizationRoleId: theUser.OrganizationId

                                })
                            }}>
                                <i className="fa fa-plus"></i>
                            </button>
                        </div>
                    </Card.Header>
                    <Card.Body className="table-responsive p-1">
                        {state.Records.length > 0 ?
                            <table className="table table-stripped">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nombre</th>


                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.Records.map((item, index) => {

                                        let itemParsed = JSON.parse(JSON.stringify(item));
                                        return (<tr key={index}>
                                            <td>{state.currentPage === 0 ? index + 1 : (index + 1) * (state.currentPage + 1)}</td>
                                            <td>{item.user.firstName} {item.user.lastName}

                                                {item.created ?
                                                    <><br />
                                                        <small className='text-muted'>{moment(item.created).format("MMM DD, YYYY")}</small>
                                                    </>
                                                    : null}
                                                <br />
                                                <small className='text-muted'>{item.user.email}</small>
                                            </td>
                                            <td className="text-right">
                                                <div className="btn-group">
                                                    <button className="btn btn-primary btn-sm" onClick={() => toggleAddEditPassword(itemParsed)}>
                                                        <i className="fas fa-key" />
                                                    </button>
                                                    <button className="btn btn-primary btn-sm" onClick={() => toggle(itemParsed.user)}>
                                                        <i className="fa fa-edit" />
                                                    </button>
                                                    <button className="btn btn-primary btn-sm" onClick={() => deleteConfirm(itemParsed)}>
                                                        <i className="fa fa-trash text-danger" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                            : null}
                    </Card.Body>
                    <Card.Footer>
                        <Pagination totalPages={state.totalPages} currentPage={state.currentPage} onPageChanged={onPageChanged} />
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    </>);
}

export default Users;