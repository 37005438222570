import moment from "moment";
import React, { useEffect, useState } from "react";
import API from "../../common/API";
import { parsedUser } from "../../common/GetCurrentUser";

const ActivityLog = props => {

    const [records, setRecords] = useState([])
    const request = async () => {
        let query = "";
        let parsed = parsedUser();
        query = "OrganizationId=" + parsed.OrganizationId;
       
        if (props.projectId) {
            query += "&ProjectId=" + props.projectId;
        }
        var request = await API.getAction("ActivityLog/List", query);
        console.log("Response from api", request)
        if (request.data.status === "ok") {
            setRecords(request.data.items);

        }
    }

    useEffect(() => {
        request();
    }, [props.projectId])
    return (<>
        <div className="col-12">
            <div className="row">
                <div className="col-auto">
                    <h5 className="display-5 d-inline">Activity Log</h5>
                </div>
                <div className="col ml-auto text-right">

                </div>
            </div>
            {records.length > 0 ?
                <ul className="timeline">
                    {records.map((item, index) => {
                        let itemParsed = item
                        console.log("the item", item, itemParsed)
                        return (<li className="timeline-item bg-white rounded shadow-sm p-2 px-4" key={index}>
                 
                            <div className="row">
                                <div className="col-auto px-0">
                                    <span className="small text-muted"><i className="fa fa-clock-o mr-1"></i>{moment(item.date).fromNow()}</span>
                                    {item.title ?
                                        <h2 className="h5 mb-0">{item.title}</h2>
                                        : null}
                                    <div className="text-small mt-2 font-weight-light" dangerouslySetInnerHTML={{ __html: item.content }} >

                                    </div>

                                </div>

                                <div className="col-12 text-left  px-0">
                                    <img src={"https://via.placeholder.com/22/3f67fa/ffffff/?text=" + item.userName.substring(0, 1)} className="img-fluid rounded  mx-0" alt="User"></img>
                                    {item.userName ? <small className="text-muted mx-2"> {item.userName}</small> : null}
                                </div>
                            </div>

                        </li>);
                    })}

                </ul>
                : null}

        </div>

    </>)
}

export default ActivityLog;