
import swal from "sweetalert";

export const typeIconEquivalent = item => {
    let fontReference = "";
    switch (item.type) {
        case "file":
        case "link":

            fontReference = fileIcon(item);
            break;

        case "folder":
            fontReference = "fa fa-folder";
            break;
        default:

            fontReference = fileIcon(item);
            break;
    }
    //console.log("Icon selected", fontReference);
    return fontReference;
}

export const openInNewTab = (item) => {
    let extension = item.path.split(".").pop();
    // "https://docs.google.com/viewerng/viewer?url="
    let path = item.type === "link" ? item.path :
        item.type === "file" &&
            (extension.includes("pdf") || extension.includes("doc") || extension.includes("docx") ||
                extension.includes("xls") || extension.includes("xlsx") || extension.includes("pptx") ||
                extension.includes("ppt")) ? "https://docs.google.com/viewerng/viewer?url="+ "https://voapi2.shearly.com/files/" + item.path : "https://voapi2.shearly.com/files/" + item.path;
    
    
                
                const newWindow = window.open(path, '_blank', 'noopener,noreferrer,width=1280,height=840')
    if (newWindow) newWindow.opener = null
}

export const copyToClipboard = (item) => {
    navigator.clipboard.writeText(item.type === "link" ? item.path : `https://voapi2.shearly.com/files/${item.path}`);
    swal("Copied to clipboard!");
}

export const fileIcon = item => {
    if (item.path === undefined) {
        return "";
    }
    console.log("Icon " + item.type, item)
    if (item.type === "link") {
        if (item.path.includes("dropbox")) {
            return "fab fa-dropbox dropboxColor";
        } else if (item.path.includes("google")) {
            return "fab fa-google googleDriveColor";
        } {
            return "fas fa-globe  text-secondary";
        }
    } else {

        switch (item.path.split(".").pop()) {
            case "jpg":
            case "jpeg":
            case "png":
            case "gif":
                return "far fa-file-image text-secondary ";
                break;
            case "pdf":
                return "far fa-file-pdf text-danger";
                break;
            case "doc":
            case "docx":
                return "far fa-file-word text-primary"
                break;
            case "xls":
            case "xlsx":
                return "far fa-file-excel text-success";
            case "ppt":
            case "pptx":
                return "far fa-file-powerpoint text-warning"
            default:
                return "far fa-file text-secondary";
        }
    }
}