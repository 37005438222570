import React, { useEffect, useState } from "react";
import { parsedUser } from "../../common/GetCurrentUser";
import API from "../../common/API";
import { Card, Carousel, Col, Modal, Row } from "react-bootstrap";
import moment from "moment";

const NewsUpdatesList = props => {
	const user = parsedUser();
	const [newsUpdates, setNewsUpdates] = useState([])

	const requestNewsUpdate = async () => {


		let requested = await API.getAction("NewsUpdates/list?userId=" + user.ID);
		if (requested.data.status === "ok") {
			setNewsUpdates(requested.data.response)

		}
	}

	useEffect(() => {
		requestNewsUpdate();
	}, []);
	return (
		<Modal show={props.show} onHide={props.toggle} size='lg'>
			<Modal.Header>
				<Row className="w-100">
					<Col>
						Updates
					</Col>
					<Col className="ml-auto" xs={'auto'}>
						<button className="btn btn-primary btn-sm mx-2">
							<i className="fa fa-plus"></i>
						</button>
						<button className="btn btn-sm " onClick={() => props.toggle()}>
							<i className="fa fa-times"></i>
						</button>
					</Col>
				</Row>
			</Modal.Header>
			<Modal.Body style={{ minHeight: "400px" }}>
				{newsUpdates.map((item, index) => {
					return (
						<Card key={index} className="bg-light mb-2">
							<Card.Body className="p-2">
								<Row  >
									<Col>
										<label>{item.title}
										</label></Col>
									<Col className="text-end" xs={'auto'}>
										<small className="text-muted">{moment(item.created).fromNow()}</small>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					)
				})}

			</Modal.Body>
		</Modal>
	)
}

export default NewsUpdatesList;