import React, { useEffect, useState } from "react";
import { Modal, Button, Col, Row, Form } from "react-bootstrap";
import API from "../../common/API";
import { useFormik } from "formik";
import * as yup from "yup";

//Translation
import { useTranslation } from "react-i18next";
import { parsedUser } from "../../common/GetCurrentUser";

//Validation
const validationSchema = yup.object({
  name: yup.string().required(),
});

const AddEditFolder = (props) => {
  const { t } = useTranslation("global");

  useEffect(() => {
    let focus = document.getElementById("Name");
    if (focus !== null) {
      focus.focus();
    }
  }, []);

  const handleSubmit = async (obj) => {
    try {
      console.log(obj);
      //We have to sent to api
      const request = await API.postAction("File/AddEditFolder", obj);
      console.log("request add edit", request);
      // state.IsLoading.set(!state.IsLoading.value)

      if (request.data.status === "ok") {
        props.toggle(request.data.response, true);
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      // state.IsLoading.set(false)
      //props.toggle();
    }
  };

  const formik = useFormik({
    initialValues: props.model,

    validationSchema,
    onSubmit: handleSubmit,
  });
  if (!formik.values) {
    return <></>;
  }
  return (
    <Modal show={true} onHide={props.toggle} size="md">
      <Modal.Header closeButton>
        <Modal.Title>{t("folder")}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={12} md={12}>
              <Form.Label>{t("name")}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.errors.name ? (
                <div className="invalid text-sm">{formik.errors.name}</div>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            className="btn-sm"
            // disabled={state.IsLoading}
          >
            {t("save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddEditFolder;
