import moment from "moment";
import React, { useEffect, useState } from "react";
import API from "../../common/API";
import QRCode from "react-qr-code";
import emptyImg from "../../assets/img/illustrations/no_contact.svg";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import AddEditContact from "./AddEditContact";
import { parsedUser } from "../../common/GetCurrentUser";

const ContactList = (props) => {
  const [state, setState] = useState({
    Contacts: [],
    UI: {
      showAddEdit: false,
      SelectedItem: null,
    },
  });
  const [showAddEdit, setShowAddEdit] = useState(false);
  const user = parsedUser();
  useEffect(() => {
    //Load remote records
    request();
  }, []);

  const request = async () => {
    let query = "organizationid=" + user.OrganizationId;

    if (query.length === 0) {
      return;
    }
    let requestAPI = await API.getAction("Contact/List?" + query);

    if (requestAPI.data.status === "ok") {
      console.log("post", requestAPI);
      setState({
        ...state,
        Contacts: requestAPI.data.response,
      });
    }
  };
  const toggle = async (obj, reload) => {
    setState({
      ...state,
      SelectedItem: obj,
    });
    setShowAddEdit(!showAddEdit);
    if (reload !== undefined) {
      request();
    }
  };
  const toggleItem = async (obj) => {
    setState({
      ...state,
      SelectedItem: obj,
    });
  };

  const deleteConfirm = async (obj) => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure to delete " + obj.name + "?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await API.getAction("Contact/Delete?id=" + obj.id);
            await request();
          },
        },
        {
          label: "No",
          onClick: () => alert("Click No"),
        },
      ],
    });
  };

  return (
    <>
      {showAddEdit ? (
        <AddEditContact
          toggle={toggle}
          show={showAddEdit}
          model={state.SelectedItem}
        />
      ) : null}
      <div className="row py-4">
        <div className="col-12 col-md-4">
          <div className="row">
            <div className="col mb-2">
              <h5 className="display-5">Contactos</h5>
            </div>
            <div className="col-auto ml-auto text-right mb-2">
              <div className="input-group input-group-sm">
                <div className="input-group-prepend">
                  <input
                    type="text"
                    className="form-control form-control-sm d-none"
                  ></input>
                </div>
                <button
                  className="btn btn-primary d-inline ml-auto btn-sm"
                  onClick={() =>
                    toggle({
                      id: "",
                      created: "",
                      name: "",
                      lastName: "",
                      position: "",
                      phone: "",
                      note: "",
                      organizationId: user.OrganizationId,
                    })
                  }
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>
          </div>

          {state.Contacts.length > 0 ? (
            state.Contacts.map((item, index) => {
              return (
                <div
                  className={
                    "row my-2 clickable " +
                    (state.SelectedItem && state.SelectedItem.id === item.id
                      ? "active"
                      : "")
                  }
                  key={index}
                  onClick={() => toggleItem(item)}
                >
                  <div className="col text-truncate">
                    <span className="text-capitalize">{item.name}</span>
                    <br />
                    <small className="text-muted">
                      {item.phone} {item.email ? " | " + item.email : null}
                    </small>
                  </div>
                  <div className="col-auto text-right mt-2">
                    <div className="btn-group">
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => toggle(item)}
                      >
                        <i className="fa fa-edit" />
                      </button>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => deleteConfirm(item)}
                      >
                        <i className="fa fa-trash text-danger" />
                      </button>
                    </div>
                  </div>
                  <div className="col-12">
                    <hr />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="col-12 text-center">
              <img
                alt=""
                src={emptyImg}
                className="img-fluid"
                style={{ maxWidth: "25%" }}
              />
            </div>
          )}
        </div>
        <div className="col-12 col-md-8 bg-light contactContainer">
          {state.SelectedItem ? (
            <div className="row p-4">
              <div className="col-auto">
                <i className="fa fa-user fa-4x"></i>
              </div>
              <div className="col-8">
                <span className="display-4">
                  {`${state.SelectedItem.name} ${state.SelectedItem.lastName}`}
                </span>
                <p>{`${state.SelectedItem.phone} ${state.SelectedItem.email}`}</p>
                <p>
                  <div className="p-4 bg-white">
                    <QRCode
                      value={
                        "https://voapi2.shearly.com/api/contact/vcard?id=" +
                        state.SelectedItem.id
                      }
                    ></QRCode>
                  </div>
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ContactList;
