import { useState } from "@hookstate/core";
import React, { useEffect } from "react";
import { v4 } from "uuid";
import { Row } from '@themesberg/react-bootstrap';


import logoShearly from "../../assets/logos/logoShearly200.png";
let jitsiObj = null

const Meeting = props => {

    const state = useState({

        StartMeeting: false,
        isMuted: false,
        isVideoOff: false,
        Username: ""
    })
    useEffect(() => {
        initialiseJitsi("preMeetVideo", false);

        let meetingInfo = JSON.parse(window.localStorage.getItem("meetingInfo"));
        if (!meetingInfo) {
            meetingInfo = { Name: "" }
            window.localStorage.setItem("meetingInfo", JSON.stringify(meetingInfo));
        } else {
            state.Username.set(meetingInfo.Name);
        }

    }, [])
    const loadJitsiScript = async () => {
        let resolveLoadJitsiScriptPromise = null;

        const loadJitsiScriptPromise = new Promise((resolve) => {
            resolveLoadJitsiScriptPromise = resolve;
        });

        const script = document.createElement("script");
        script.src = "https://meet.jit.si/external_api.js";
        script.async = true;
        script.onload = resolveLoadJitsiScriptPromise
        document.body.appendChild(script);

        return loadJitsiScriptPromise;
    };

    const initialiseJitsi = async (target, initMeeting, id) => {
        state.StartMeeting.set(initMeeting);
        if (!window.JitsiMeetExternalAPI || initMeeting) {
            await loadJitsiScript();
        }
        let name = state.Username.value;
        if (id === undefined) {
            id = v4()
        }
        const container = document.getElementById(target);

        const _jitsi = new window.JitsiMeetExternalAPI("jitsi.debian.social", {
            parentNode: container,
            configOverwrite: {
                startWithAudioMuted: state.isMuted.value,
                startWithVideoMuted: state.isVideoOff.value,
                toolbarConfig: {
                    alwaysVisible: true
                }
            },
            roomName: id,
            userInfo: {

                displayName: name
            }
        });
        _jitsi.addListener("audioMuteStatusChanged", ev => {
            state.isMuted.set(ev.muted);
            console.log("toggle ev", ev);
        })
        _jitsi.addListener("videoMuteStatusChanged", ev => {
            state.isVideoOff.set(ev.muted);
            console.log("toggle ev", ev);
        })
        jitsiObj = _jitsi

        let muted = await jitsiObj.isAudioMuted();
        state.isMuted.set(muted);

        let videoOff = await jitsiObj.isVideoMuted();
        state.isVideoOff.set(videoOff);

        if (!muted && state.isMuted.value) {
            toggleMute();
        }

        if (!videoOff && state.isVideoOff.value) {
            toggleVideo();
        }
    };

    const toggleMute = async () => {

        let audio = await jitsiObj.executeCommand('toggleAudio');

        let muted = await jitsiObj.isAudioMuted();
        state.isMuted.set(muted);
    }

    const toggleVideo = async () => {

        let video = await jitsiObj.executeCommand('toggleVideo');

        let videoOff = await jitsiObj.isVideoMuted();

        state.isVideoOff.set(videoOff);
    }
    let secondStyle = {
        //backgroundImage: "url("+IMAGES.LOGO_BLACK_P+")",

    }
    return (
        <>

            <Row className="justify-content-center  mx-0" >
                <div className={state.StartMeeting.value ? "dashboard-screen" : "dashboard-screen d-none"}>
                    <div id="meet2" className="" style={{ height: "100vh" }}></div>
                </div>

                <div className={!state.StartMeeting.value ? "dashboard-screen" : "dashboard-screen d-none"}>
                    <div id="PreMeeting" className="row mx-0" style={{ height: "100vh" }}>

                        <div className="col-12 col-md-6 mx-auto my-auto">

                            <div className="card shadow-lg ">
                                <div className="card-body row">

                                    <div className="col-6">
                                        {/* VIDEO */}
                                        <div id="preMeetVideo" className="row mx-0" style={{ height: "300px", minHeight: "200px", background: "black" }}></div>
                                    </div>
                                    <div className="col-6 ">
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <img src={logoShearly} title="logo" alt="logo" className='img-fluid' />
                                                <h5 className="title">Ingresar a la reunión</h5>
                                            </div>
                                            <div className="col-12 py-2">
                                                <label>Introduzca su nombre para la reunión</label>
                                                <input type="text" className="form-control" id="Name" name="Name" autoFocus
                                                    value={state.Username.value}
                                                    onChange={(e) => {
                                                        state.Username.set(e.target.value)
                                                        let meetingInfo = JSON.parse(window.localStorage.getItem("meetingInfo"));
                                                        meetingInfo.Name = e.target.value;
                                                        window.localStorage.setItem("meetingInfo", JSON.stringify(meetingInfo));
                                                    }}
                                                />
                                            </div>
                                            {/* <div className="col-12 text-center my-2">
                                            <button className="btn btn-secondary mx-1" onClick={() => toggleMute()}>
                                                {state.isMuted.value ? <i className="fa fa-microphone-slash" /> : <i className="fa fa-microphone" />}
                                            </button>
                                            <button className="btn btn-secondary mx-1" onClick={() => toggleVideo()}>
                                                {state.isVideoOff.value ? <i className="fa fa-video-slash" /> : <i className="fa fa-video" />}
                                            </button>
                                        </div> */}
                                            <div className="col-12 my-4">
                                                <button className="btn btn-primary btn-block" onClick={async () => await initialiseJitsi("meet2", true, props.match.params.id)}>
                                                    Unirse
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        </>
    )
}



export default Meeting;
