import React, { useEffect, useState } from 'react';
import { Card, Button } from '@themesberg/react-bootstrap';

import Profile1 from "../../assets/img/team/profile-picture-1.jpg";

import API from '../../common/API';
import { parsedUser } from '../../common/GetCurrentUser';
import {userAvatar} from '../../common/helpers'
import AddEditOrganization from './AddEditOrganization';

const OrganizationBox = props => {

    const [state, setState] = useState({
        Organization : null,
        isLoading : false,
        showEdit: false,
    });

    

    const theUser = parsedUser();

    const request = async () => {
        setState({
            isLoading : !state.isLoading,
            ...state
        });
        let requestAPI = await API.getAction("Organization/Details?Id=" + theUser.OrganizationId);

        if (requestAPI.data.status === "ok") {
            setState({
                isLoading : !state.isLoading,
                Organization : requestAPI.data.result
            });
            
        }
    };
    const toggleAddEdit= (reload)=>{
        setState({
            ...state,
            showEdit: !state.showEdit
        });

        if(reload){
             request();
        }
    }
    useEffect(()=>{
        request();

    }, [])

    return (
    <>
    {state.showEdit?
    <AddEditOrganization 
    show={state.showEdit}
    toggle={toggleAddEdit}
    model={state.Organization} />:null}

    <Card border="light" className="text-center p-0 mb-4">
        <div className="profile-cover rounded-top" />
        <Card.Body className="pb-5">
            <Card.Img src={state.Organization && userAvatar(state.Organization.name, 160)} alt="Neil Portrait" className="user-avatar large-avatar rounded-circle mx-auto mt-n7 mb-4" />
            <Card.Title>{state.Organization && state.Organization.name}</Card.Title>
            <Card.Subtitle className="fw-normal">{state.Organization && state.Organization.email}</Card.Subtitle>
            <Card.Text className="text-gray mb-4">{state.Organization && state.Organization.address}</Card.Text>

            <button className='btn btn-primary btn-sm ' onClick={()=>toggleAddEdit()}>
                Editar
            </button>

        </Card.Body>
    </Card>
    </>);
}

export default OrganizationBox;