export const Routes = {
  // pages
  Home: { path: "/#" },
  Dashboard: { path: "/Dashboard" },
  Files2: { path: "/files2" },
  FileExternal: { path: "/files/external/:id", clean: "files/external/" },
  Files: { path: "/files" },
  Settings: { path: "/settings" },
  ActivityLog: { path: "/activitylog" },
  Summary: { path: "/summary" },

  NotFound: { path: "/examples/NotFound" },
  ServerError: { path: "/examples/ServerError" },

  Chat: { path: "/Chat" },
  Calendar: { path: "/Calendar" },
  CalendarUnified: { path: "/" },
  ProjectDashboard: {
    path: "/projects/dashboard/:id",
    clean: "/projects/dashboard/",
  },
  Profile: { path: "/profile" },
  SignIn: { path: "/signin" },
  Register: { path: "/Register" },
  ForgotPassword: { path: "/ForgotPassword" },
  ResetPassword: { path: "/ResetPassword" },
  SelectOrganization: { path: "/SelectOrganization" },
  AddEditOrganization: { path: "/AddEditOrganization" },

  Users: { path: "/Users" },
  Contacts: { path: "/Contacts" },

  Meeting: { path: "/meeting/details/:id", clean: "/meeting/details/" },

  RepslyPhoto: { path: "/repslyPhoto", clean: "/repslyPhoto/" },
  RepslyCustomers: { path: "/repslyCusotmers", clean: "/repslycusotmers/" },
  RepslyVisit: { path: "/repslyVisit", clean: "/repslyVisit/" },

  AppointmentDetails: {
    path: "/Appointment/details/:id",
    clean: "/Appointment/Details/",
  },

  Search: { path: "/search/", clean: "/search/" },
  WeeklyResume: { path: "/weeklyResume/", clean: "/weeklyResume/" },
  OutsideApointment: { path: "/a/:id", clean: "/a/" },
  Notes: { path: '/notes/:id?', clean: '/notes/' }
};
