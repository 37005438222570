
import React, { useEffect, createRef, useState } from "react";
import { Row } from '@themesberg/react-bootstrap';

import CalendarView from "../Common/Agenda/CalendarView";
import ActivityList from "../Common/Agenda/ActivityList";
import { PushNotifications } from "../Common/PushNotifications";

export default (props) => {

  const [selectedOption, setSelectedOption] = useState("calendar");
  const toggleViewMode = mode => {
    setSelectedOption(mode)
  }

  debugger;
  return (<>

    <Row>
      <div className="col-12">

        {selectedOption === "calendar" ?
          <CalendarView toggleViewMode={toggleViewMode} full />
          : null}

        {selectedOption === "list" ?
          <ActivityList toggleViewMode={toggleViewMode} />
          : null}

      </div>
    </Row>
  </>
  );
};
