import moment from "moment";
import React, { useEffect, useState } from "react";
import API from "../../../common/API";
import { parsedUser } from "../../../common/GetCurrentUser";
import UserItem from "../UserItem";
import AddEditActivity from "./AddEditActivity";

import NOCONTENT from "../../../assets/img/illustrations/no-tasks.png";

import * as yup from "yup";
import "react-datepicker/dist/react-datepicker.css";

//Validation
const validationSchema = yup.object({
  name: yup.string().required(),
});

const ActivityList = (props) => {
  const [activities, setActivities] = useState([]);
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [activityMode, setActivityMode] = useState("add");
  const [showPending, setShowPending] = useState(true);
  const [activitiesComplete, setActivitiesComplete] = useState([]);

  const [ActivitySelected, setActivitySelected] = useState({
    name: "",
    notes: "",

    status: 0,
    createdId: null,
    updatedId: "",
    invites: "",
    location: "",
    date: moment(moment().format("YYYY-MM-DD")).add(8, "hours").format(),
    created: moment().format(),
    id: "",
    organizationId: null,
    appointmentTypeId: null,
    assignedToId: null,
    projectId: props.projectId,
    type: 1,
    //  duration: duration
  });

  const [singleSelections, setSingleSelections] = useState([]);
  const [options, setOptions] = useState([]);

  const [projects, setProjects] = useState([]);

  const [state, setState] = useState({
    UI: {
      isLoading: false,
    },
    user: null,
  });
  useEffect(() => {
    // setActivities(props.Activities);
    setState({
      ...state,
      user: parsedUser(),
    });

    request();
  }, [props.projectId]);

  const toggleAddEdit = async (opt, mode, reload) => {
    setActivityMode(mode);
    let theUser = parsedUser();
    if (opt === null) {
      opt = {
        name: "",
        notes: "",

        status: 0,
        createdId: theUser.ID,
        updatedId: "",
        invites: "",
        customerId: null,
        location: "",
        date: moment().format("YYYY-MM-DD HH:mm"),
        created: moment(new Date()).format("YYYY-MM-DDTHH:mm:SSZ"),
        id: "",
        organizationId: theUser.OrganizationId,
        appointmentTypeId: null,
        assignedToId: theUser.ID,
        duration: 30,
        projectId: props.projectId,
        type: 2,
      };
    }
    setActivitySelected(opt);
    if (mode === "edit") {
      let ExistingTask = await API.getAction(
        "appointments/details?id=" + opt.id
      );
      if (ExistingTask.data.status === "ok") {
        opt = ExistingTask.data.response;
        setActivitySelected(opt);
      }
    }

    setShowAddEdit(!showAddEdit);

    if (reload === true) {
      await request();
    }
  };

  const activityLine = (item) => {
    return (
      <div className="row mx-0">
        <div className="col-auto text-center px-0">
          <div className="btn-group">
            {item.status === 0 ? (
              <button
                className="btn btn-sm "
                title={"Marcar como completado"}
                onClick={() => {
                  markAsCompleted(item, 2);
                }}
              >
                <i className="far fa-circle" />
              </button>
            ) : (
              <button
                className="btn btn-sm "
                title={"Marcar como pendiente"}
                onClick={() => {
                  markAsCompleted(item, 0);
                }}
              >
                {item.status === 1 ? (
                  <i className="fas fa-minus-circle"></i>
                ) : (
                  <i className="fas fa-check-circle text-success"></i>
                )}
              </button>
            )}
          </div>
        </div>
        <div
          className="col clickable text-truncate"
          onClick={() => {
            toggleAddEdit(item, "edit");
          }}
        >
          <small className="text-muted">{moment(item.created).fromNow()}</small>
          <br />
          {item.date ? (
            <small title={moment(item.date).format()}>
              <b>{moment(item.date).format("MMM DD")} - </b>
            </small>
          ) : (
            ""
          )}{" "}
          {item.name}
          <br />
          {item.assignedTo ? <UserItem model={item.assignedTo} /> : null}
        </div>

        <div className="col-auto ms-auto text-center"></div>
        <div className="col-12">
          <hr className="my-1 " />
        </div>
      </div>
    );
  };

  const UIList = () => {
    var pendingList = (
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            className={
              "accordion-button " + (showPending !== true ? "collapsed" : "")
            }
            type="button"
            onClick={() => {
              setShowPending(!showPending);
            }}
          >
            Pendiente ({activities.length})
          </button>
        </h2>
        <div
          className={
            "accordion-collapse collapse " +
            (showPending === true ? "show" : null)
          }
        >
          <div className="accordion-body">
            {activities &&
              activities.map((item, index) => {
                return activityLine(item);
              })}
          </div>
        </div>
      </div>
    );

    var completedList = (
      <div className="accordion-item">
        <h2 className="accordion-header" id="panelsStayOpen-headingThree">
          <button
            className={
              "accordion-button " + (showPending === true ? "collapsed" : "")
            }
            type="button"
            onClick={() => {
              setShowPending(!showPending);
            }}
          >
            Completadas ({activitiesComplete.length})
          </button>
        </h2>
        <div
          className={
            "accordion-collapse collapse " +
            (showPending === false ? "show" : null)
          }
        >
          <div className="accordion-body">
            {activitiesComplete &&
              activitiesComplete.map((item, index) => {
                return activityLine(item);
              })}{" "}
          </div>
        </div>
      </div>
    );

    var component = (
      <div
        className="accordion accordion-flush"
        id="accordionPanelsStayOpenExample"
      >
        {pendingList}
        {completedList}
      </div>
    );

    return component;
  };

  const toggleAddEditQuick = async (opt, mode, reload) => {
    setActivityMode(mode);
    let theUser = parsedUser();
    if (opt === null) {
      opt = {
        name: "",
        notes: "",

        status: 0,
        createdId: theUser.ID,
        updatedId: "",
        invites: "",
        customerId: null,
        location: "",
        date: null,
        created: moment(new Date()).format("YYYY-MM-DDTHH:mm:SSZ"),
        id: "",
        organizationId: theUser.OrganizationId,
        appointmentTypeId: null,
        assignedToId: theUser.ID,
        duration: 30,
        projectId: props.projectId,
        type: 2,
      };
    }
    setActivitySelected(opt);
    if (mode === "edit") {
      let ExistingTask = await API.getAction(
        "appointments/details?id=" + opt.id
      );
      if (ExistingTask.data.status === "ok") {
        opt = ExistingTask.data.response;
        setActivitySelected(opt);
      }
    }

    if (reload === true) {
      await request();
    }
  };

  const request = async () => {
    let currentUser = parsedUser();
    let query = "";

    if (state.UI.OnlyForTheUser) {
      query = "AssignedToId=" + currentUser.ID;
    } else {
      query = "OrganizationId=" + currentUser.OrganizationId + "&AssignedToId=";
    }
    query += "&ProjectId=" + props.projectId + "&type=1";

    let requestAPI = await API.getAction("Appointments/List?" + query);

    if (requestAPI.data.status === "ok") {
      var pending = requestAPI.data.response.filter((x) => x.status === 0);
      var completed = requestAPI.data.response.filter((x) => x.status === 2);

      setActivities(pending);
      setActivitiesComplete(completed);
    }
  };

  const markAsCompleted = async (obj, status) => {
    try {
      let parsed = parsedUser();
      obj.status = status;

      await API.postAction(
        "Appointments/AddEdit?CurrentUserId=" + parsed.ID,
        obj
      );
      await request();
    } catch (ex) {
      console.error(ex);
    }
  };

  return (
    <>
      {showAddEdit ? (
        <AddEditActivity
          model={ActivitySelected}
          toggle={toggleAddEdit}
          show={showAddEdit}
          mode={activityMode}
        />
      ) : null}

      <div className="card shadow-sm">
        <div className="card-header px-2">
          {/* MENU */}
          <div className="row">
            <div className="col">
              <h5>Tareas</h5>
            </div>
            <div className="col text-right mx-2 mb-3">
              <div className="btn-group">
                <button
                  className="btn btn-sm btn-block btn-primary"
                  type="button"
                  onClick={() => {
                    toggleAddEditQuick(null, "add");
                  }}
                >
                  <i className="fa fa-plus"></i> Agregar Tarea
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body px-1 py-1">
          <div className="activityListContainer">
            {/* CONTENT */}
            {UIList()}

            {/* EMPTY STATE */}
            {activities && activities.length === 0 ? (
              <div className="col-4 mx-auto text-center">
                <img src={NOCONTENT} className="img-fluid" />
                <br />
                <p>Aún no hay contenido</p>
                <p>
                  <button
                    className="btn btn-primary"
                    onClick={() => toggleAddEdit(null, "add")}
                  >
                    Agregar Actividad
                  </button>
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityList;
