import React, { useEffect } from 'react';

import { Modal, Button, Col, Row, Form } from 'react-bootstrap';
import { useState } from '@hookstate/core';
import * as yup from 'yup';
import { useFormik } from 'formik';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import { onlyHTMLTags } from '../../common/helpers';
import API from '../../common/API';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { parsedUser } from '../../common/GetCurrentUser';

//Validation
const validationSchema = yup.object({
    //  title: yup.string().required("required"),
    //    details: yup.string().required(),

    date: yup.string().required(),
    name: yup.string().required(),
});
//Component
const AddEditMilestone = props => {
    const state = useState({

        UI: {
            isLoading: false
        },

    })
    useEffect(() => {
        console.log("props received on post", props);
    }, [])

    const handleSubmit = async obj => {
        console.log("values to submit", obj);
        let user = parsedUser();
        debugger;
        if (obj.id.length === 0) {
            obj.id = uuidv4();
            obj.created = moment().format("YYYY-MM-DD HH:mm:ss")
            obj.creatorID = user.ID;
            //     obj.projectId= props.projectId;
            obj.organizationId = user.OrganizationId;

        } else {
            obj.UpdateID = user.ID;
        }
        let request = await API.postAction("milestone/AddEdit?currentuserid=" + user.ID, obj);
        if (request.status === 200) {
            props.toggle(request.data, true);
        }
    };

    const formik = useFormik({
        initialValues: props.model ? props.model :
            {
                name: "", 
                content: "",
                created: moment().format(),
                id: "",
                date: moment().format("YYYY-MM-DD"),
                organizationId: "",
                projectId: "",
                milestoneType :0,
            },
        validationSchema,
        onSubmit: handleSubmit,
    });


    return (
        <Modal show={props.show} onHide={props.toggle} size='md' className='modalRight'>
            <Modal.Header closeButton>
                <Modal.Title>Hito</Modal.Title>
            </Modal.Header>

            <Form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control type="text"
                                name="name"
                                value={formik.values.name} onChange={formik.handleChange} />
                            {formik.errors.name ? <div className="invalid text-sm">{formik.errors.name}</div> : null}

                        </Col>
                        <Col xs={12}>
                            <Form.Label>Fecha</Form.Label>
                            <Form.Control type="date"
                                name="date"
                                value={moment(formik.values.date).format("YYYY-MM-DD")} onChange={formik.handleChange} />
                            {formik.errors.date ? <div className="invalid text-sm">{formik.errors.date}</div> : null}

                        </Col>
                        <Col xs={12}>
                            <Form.Label>Tipo</Form.Label>
                            <select className='form-control' name="milestoneType" value={formik.values.milestoneType ? formik.values.milestoneType : 0} onChange={formik.handleChange}>
                                <option value="0">-</option>
                                <option value="1">Reunión</option>
                                <option value="2">Revisión</option>
                                <option value="3">Entrega de documento</option>
                                <option value="4">Inicio Obra</option>


                            </select>
                            
                            {formik.errors.date ? <div className="invalid text-sm">{formik.errors.date}</div> : null}

                        </Col>
                        <Col xs={12} className="mt-2">
                            <Form.Label>Contenido</Form.Label>

                            <ReactQuill value={formik.values.details} name="details"
                                    modules={{
                                        toolbar:  [['bold', 'italic'], ['link', 'image'],   [{ 'list': 'ordered'}, { 'list': 'bullet' }],]
                                    }}
                                onChange={e => {
                                    let onlyTags = onlyHTMLTags(e);
                                    formik.setFieldValue("details", onlyTags ? "" : e)
                                }} />
                            {formik.errors.content ? <div className="invalid text-sm">{formik.errors.details}</div> : null}
                        </Col>

                    </Row>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" type="submit" className="btn-sm">
                        Guardar
                    </Button>
                </Modal.Footer>
            </Form>


        </Modal>
    )
}

export default AddEditMilestone;