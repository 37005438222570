import React, { useState, useEffect } from "react";
import SimpleBar from "simplebar-react";
import { useHistory, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faSignOutAlt,
  faTable,
  faTimes,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  Badge,
  Image,
  Button,
  Accordion,
  Navbar,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { parsedUser } from "../../common/GetCurrentUser";
import { Routes } from "../../routes";
import API from "../../common/API";

import AddEditProject from "../Projects/AddEditProject";
import CreateMeeting from "./OmniBar/CreateMeeting";
import { db } from "../../common/db";
import { sortArray } from "../../common/helpers";

export default (props) => {
  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  const [organizations, setOrganizations] = useState([]);
  const [user, setUser] = useState(null);
  const [showAddEditProject, setShowAddEditProject] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const [projects, setProjects] = useState([]);
  const [hiddenProjects, setHiddenProjects] = useState([]);
  const [showCreateMeeting, setShowCreateMeeting] = useState(false);
  const toggleShowMeeting = () => {
    setShowCreateMeeting(!showCreateMeeting);
  };

  useEffect(() => {
    let collectedUser = parsedUser();
    console.log("the user selected", collectedUser);
    setUser(collectedUser);
    if (!collectedUser) {
      history.push(Routes.SignIn.path);
    }
    bindLocal();
    projectRequest();
  }, []);

  const projectsUI = () => {
    let projectsData = [];

    projectsData = projects.map((item, index) => {
      return (
        // <NavItem key={index} title={item.name} icon={faCog} link={Routes.Settings.path} smaller badgeText="3" />)
        <NavItem
          key={index}
          model={item}
          title={item.name}
          canBeHidden
          color={item.color}
          icon={faCircle}
          link={Routes.ProjectDashboard.clean + item.id}
          smaller
        />
      );
    });
    return projectsData;
  };

  const projectRequest = async () => {
    let user = parsedUser();
    let requestAPI = await API.getAction(
      "projects/List?OrganizationId=" + user.OrganizationId
    );
    if (requestAPI.data.status === "ok") {
      await db.projects.bulkPut(requestAPI.data.response);
      await bindLocal();
    }
  };

  const bindLocal = async () => {
    let user = parsedUser();
    if (!user) {
      window.location = Routes.SignIn.path;
    }
    let projectsLocal = await db.projects
      .where({
        organizationId: user.OrganizationId,
      })
      .toArray();

    //FILTER THE PROJECT THE USER CAN SEE
    projectsLocal = projectsLocal.filter(
      (x) =>
        x.ownerId === user.ID ||
        (x.membersAssigned && x.membersAssigned.includes(user.ID))
    );

    let hiddenProjectsLocal = await db.hiddenProjects
      .where({ organizationId: user.OrganizationId })
      .toArray();

    projectsLocal = projectsLocal.filter((project) =>
      hiddenProjectsLocal.every((fd) => fd.id !== project.id)
    );

    //sort
    projectsLocal = sortArray(projectsLocal, "order", "number");

    setProjects(projectsLocal);

    console.log("projects count", projectsLocal.length);

    //sort
    hiddenProjectsLocal = sortArray(hiddenProjectsLocal, "order", "number");
    //New get the hidden projects
    setHiddenProjects(hiddenProjectsLocal);
  };

  const toggleAddEditProject = async (reload) => {
    setShowAddEditProject(!showAddEditProject);

    if (reload) {
      await projectRequest();
    }
  };

  const onCollapse = () => setShow(!show);

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button
            as={Nav.Link}
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">{children}</Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const toggleHiddeProject = async (projectSelected) => {
    let exist = await db.hiddenProjects
      .where({ id: projectSelected.id })
      .first();

    if (exist) {
      //We have to remove it
    } else {
      await db.hiddenProjects.put(projectSelected);
      let filtered = projects.filter((x) => x.id !== projectSelected.id);
      setProjects(filtered);
    }

    await bindLocal();
  };

  const toggleShowProject = async (projectSelected) => {
    let exist = await db.hiddenProjects
      .where({ id: projectSelected.id })
      .first();

    if (exist) {
      //We have to remove it
      await db.hiddenProjects.delete(projectSelected.id);
    }

    await bindLocal();
  };

  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    const colorAssigned = props.color ? props.color + "" : "#262b40 ";
    // if(title.trim() === "Meet"){
    //   debugger;
    // }

    return (
      <Nav.Item
        className={navItemClassName + (props.smaller ? " navItemSmaller" : "")}
        onClick={link === undefined ? props.onClick : () => setShow(false)}
      >
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <div className="row showVisibility">
            <div className="col text-truncate" title={title}>
              {icon ? (
                <span
                  className={"sidebar-icon "}
                  style={{
                    color: colorAssigned,
                  }}
                >
                  <FontAwesomeIcon icon={icon} />{" "}
                </span>
              ) : null}
              {image ? (
                <Image
                  src={image}
                  width={32}
                  height={32}
                  className="sidebar-icon svg-icon"
                />
              ) : null}

              <span className="sidebar-text">{title}</span>
            </div>

            {badgeText ? (
              <Badge
                pill
                bg={badgeBg}
                text={badgeColor}
                className="badge-md notification-count ms-2"
              >
                {badgeText}
              </Badge>
            ) : null}
            {props.canBeHidden ? (
              <div
                className="ml-auto col-auto  canBeHidden"
                onClick={() => {
                  toggleHiddeProject(props.model);
                }}
              >
                <i className="fa fa-eye"></i>
              </div>
            ) : null}
            {props.restorable ? (
              <div
                className="ml-auto col-auto  canBeHidden"
                onClick={() => {
                  toggleShowProject(props.model);
                }}
              >
                <i className="fas fa-star"></i>
              </div>
            ) : null}
            {props.sideButton ? props.sideButton : null}
          </div>
        </Nav.Link>
      </Nav.Item>
    );
  };

  console.log(organizations)

  return (
    <>
      {showCreateMeeting ? (
        <CreateMeeting toggle={toggleShowMeeting} show={showCreateMeeting} />
      ) : null}
      {showAddEditProject ? (
        <AddEditProject
          toggle={toggleAddEditProject}
          show={showAddEditProject}
          model={selectedProject}
        />
      ) : null}
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none primaryNavBarWithLogo"
      >
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.Home.path}>
          <img
            src="/icons/32x32-icon.png"
            alt="Virtual Office"
            className="navbar-brand-light"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block `}>
          <div className="sidebar-inner pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="d-block px-2">
                  <h6>Hola {user && user.email}, </h6>
                  <Button
                    as={Link}
                    variant="secondary"
                    size="xs"
                    to={Routes.SignIn.path}
                    className="text-dark"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
                    Cerrar Sesión
                  </Button>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="row pt-3 pt-md-0 mainNavbar mx-0">

              <NavItem
                title="Calendario"
                link={Routes.CalendarUnified.path}
                icon={faCalendar}
                smaller
              />
              <div className="row mx-0 mt-4 ">
                <div className="col">
                  <small>
                    <b>
                      {user !== null && user.OrganizationType === "0"
                        ? "Inversiones"
                        : "Proyectos"}
                    </b>
                  </small>
                </div>
                <div className="col-auto ml-auto">
                  <button
                    className="btn btn-sm"
                    type="button"
                    onClick={() => {
                      toggleAddEditProject();
                    }}
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                  {/* <button className="btn btn-sm text-light">
                    <i className="fas fa-chevron-down"></i>
                  </button> */}
                </div>
                <div className="col-12 px-0">
                  <hr className="m-0" />
                </div>
              </div>
              {projectsUI()}

              <CollapsableNavItem
                eventKey="tables/"
                title={
                  (user !== null && user.OrganizationType === "0"
                    ? "Inversiones ocultas "
                    : "Proyectos ocultos") +
                  (hiddenProjects.length > 0
                    ? "(" + hiddenProjects.length + ")"
                    : "")
                }
                icon={faTable}
              >
                {hiddenProjects &&
                  hiddenProjects.map((item, index) => {
                    return (
                      <NavItem
                        key={index}
                        title={item.name}
                        link={Routes.ProjectDashboard.clean + item.id}
                        smaller
                        restorable
                        model={item}
                      />
                    );
                  })}
              </CollapsableNavItem>

              {/* USERS */}
              {/* <NavItem title="Configuracion" icon={faCog} link={Routes.Settings.path} /> */}
              {user &&
                organizations &&
                organizations.filter((x) => x.id === user.OrganizationId)[0] ? (
                <div
                  className="currentOrganization mt-auto clickable mx-2 text-center  py-4"
                  onClick={() => {
                    window.location = "/selectOrganization";
                  }}
                ></div>
              ) : null}
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
