import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";


import OneSignal from "react-onesignal";
import runOneSignal from "../../common/runOneSignal";
import { parsedUser } from "../../common/GetCurrentUser";

export const PushNotifications = () => {
	const [needPermission, setNeedPermision] = useState(false);
	const [show, setShow] = useState(false);
	const user = parsedUser();
	console.log(user);
	const checkPermission = async (permitionPrev) => {
		const permision = await Notification.permission;
		if (
			permision === "granted" ||
			permision === "denied" ||
			permitionPrev === "false"
		)
			return OneSignal.login(user.ID);
		setNeedPermision(true);
		setShow(true);
		return permision;
	};

	const toggle = (e) => {
		if (e) sessionStorage.setItem("showNotification", "false");
		setShow((prev) => !prev);
	};

	const askPermission = async () => {
		await Notification.requestPermission();
		OneSignal.login(user.ID);
		toggle();
	};

	useEffect(() => {
		const notificationTimer = setTimeout(() => {
			const permitionPrev = sessionStorage.getItem("showNotification");
			checkPermission(permitionPrev);
		}, 5000);
		return () => clearTimeout(notificationTimer);
	}, []);

	useEffect(() => {

		if (!OneSignal?.User?.PushSubscription?.id) {
			runOneSignal();
		}
	}, []);

	return (
		show && (
			<>
				{
					<Modal
						show={needPermission}
						onHide={() => toggle(true)}
						style={{ position: "absolute", top: 0, left: 0 }}
					>
						<div className=" px-4 py-3 rounded-lg d-flex flex-column m-2">
							<button
								className="btn btn-sm position-absolute text-light"
								style={{ top: 10, right: 10 }}
								onClick={() => toggle(true)}
							>
								<i className="fa fa-times" style={{ fontSize: "20px" }}></i>
							</button>
							<span className="d-flex align-items-center mb-2 mt-2">
								<i className="fa fa-bell-o" style={{ fontSize: "20px" }}></i>
								<h4 className="font-weight-bold ml-2">Enable notifications</h4>
							</span>
							<p className="my-2 h6">
								Turn on push notifications to get alerts when updates happen.
							</p>
							<button
								className="btn btn-primary align-self-end rounded border-0 py-2 px-4"
								onClick={askPermission}
							>
								Enable
							</button>
						</div>
					</Modal>
				}
			</>
		)
	);
};
