import React, { useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import API from "../../common/API";
import { db } from "../../common/db";
import { parsedUser } from "../../common/GetCurrentUser";
import NOCONTENT from '../../assets/img/illustrations/no_content.svg';
import ActivityListItem from "./Agenda/ActivityListItem";
const Search = props => {
    const [state, setState] = useState({
        UI: {
            isLoading: false
        },
        activityTypeSelected: null,
        user: null,
        selectedColaborator: null,
    });
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [search, setSearch] = useState("");
    const [activityTypes, setActivityTypes] = useState([]);
    const [projects, setProjects] = useState([]);
    const [colaborators, setColaborators] = useState([]);
    const [searchResults, setSearchResults] = useState([]);

    const projectRequest = async () => {

        let user = parsedUser();
        let projectsLocal = await db.projects.where({
            organizationId: user.OrganizationId
        }).toArray();
        projectsLocal = projectsLocal.sort((a, b) => {
            return a.name.localeCompare(b.name);
        })

        setProjects(projectsLocal);
    };

    const handleSearchUsers = async (query) => {

        var theUser = parsedUser();
        var request = await API.getAction("users/AutoCompleteSearch?Search=" + query + "&OrganizationId=" + theUser.OrganizationId);
        if (request.data.status === "ok") {
            let opts = request.data.response.map(e =>
            ({
                id: e.id,
                label: e.firstName + " " + e.lastName + " (" + e.email + ")"
            })
            );
            setColaborators(opts);
        }

    };


    useEffect(() => {

        setState({
            ...state,
            user: parsedUser()
        })

        projectRequest();
        setTimeout(() => {
            bindSearch();
        }, 1000)

    }, [props.projectId, props.Activities])

    useEffect(() => {
        bindSearch();
    }, [selectedProject, state.selectedColaborator, selectedStatus, search])

    const bindSearch = async () => {
        let theUser = state.user;
        if (theUser === null) {
            theUser = parsedUser();
        }

        let query = "CurrentOrganizationId=" + theUser.OrganizationId + "&currentUserId=" + theUser.ID;
        if (search.length > 0) {
            query += "&search=" + search;
        }
        //   debugger;
        if (selectedProject) {
            query += "&projectId=" + selectedProject;
        }
        if (selectedStatus) {
            query += "&status=" + selectedStatus;
        }
        if (state.selectedColaborator) {
            query += "&colaborator=" + state.selectedColaborator[0].id;
        }

        var request = await API.getAction("search/global", query);
        if (request.data.status === "ok") {

            setSearchResults(request.data.response);
        }

    }

    const handleProjectChange = value => {
        setSelectedProject(value);

    }

    return (<>
        <div className="card shadow-sm mt-4">
            <div className="card-body px-4 py-2">
                <div className="row">
                    <div className="col">

                        <b>Filtro:</b>
                    </div>
                    <div className="col-auto ml-auto">
                        <div className="input-group ">
                            <span className="input-group-text" >Búsqueda</span>
                            <input type="text" className="form-control" value={search} onChange={async e => {
                                setSearch(e.target.value);

                            }} />
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="input-group ">
                            <span className="input-group-text" >Estatus</span>
                            <select className="form-control" value={selectedStatus} onChange={e => setSelectedStatus(e.target.value)}>
                                <option value="">Todos</option>
                                <option value="0">Por hacer</option>
                                <option value="1">En Progreso</option>
                                <option value="2">Completado</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-auto">
                        <div className="input-group ">
                            <span className="input-group-text" >Proyecto</span>
                            <select className="form-control" value={selectedProject} onChange={(e) => {
                                handleProjectChange(e.target.value)


                            }}>
                                <option value="">Todos</option>
                                {projects && projects.map((item, index) => {
                                    return (<option value={item.id} key={index}>{item.name}</option>)
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="input-group ">
                            <span className="input-group-text" >Colaborador</span>
                            <AsyncTypeahead
                                id="colaborator"

                                isLoading={state.UI.isLoading}
                                onChange={e => {
                                    setState({
                                        ...state,
                                        selectedColaborator: e
                                    })

                                    debugger;
                                    if (e[0] !== undefined) {

                                    }
                                }}
                                options={colaborators}
                                minLength={2}
                                onSearch={handleSearchUsers}

                                selected={state.selectedColaborator}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* RESULTS */}
        <div className="card shadow-sm my-4 ">
            <div className="card-body px-4 py-2">
                {/* EMPTY STATE */}
                {searchResults && searchResults.length === 0 ?
                    <div className='col-4 mx-auto text-center'>
                        <img src={NOCONTENT} className="img-fluid" />
                        <br />
                        <p>No hay resultados</p>

                    </div>
                    :
                    searchResults && searchResults.map((recordsContainer, index) => {
                        console.log("search results", searchResults)
                        return (<div className="row" key={index}>
                            <div className="col-12">
                                {recordsContainer.type === "appointments" ?
                                    <>
                                        <b>Actividades</b>
                                        {recordsContainer.records.map((subItem, indexSubItem) => {
                                            return (<ActivityListItem item={subItem} key={indexSubItem} request={bindSearch} withLabel />)
                                        })}
                                    </> : null}

                            </div>
                        </div>)
                    })
                }
            </div>
        </div>

    </>);

}

export default Search;