import moment from "moment";
import React, { useContext, useEffect, useState } from "react";

const UserListUI = props => {

    const [records, setRecords] = useState([])

    useEffect(() => {
        setRecords(props.records);
    }, [props]);


    return (<>{records && records.map((item, index) => {
        let record = item;

        return (<div className={"row mx-1 py-1 conversation clickable px-2 " + (props.selectedConversation && record.conversationId === props.selectedConversation.conversationId ? "active" : "")}
            key={index}
            onClick={() => { props.toggleConversation(record) }}>
            <div className="col-auto px-0">
                <div className="avatarContainer pt-2">
                    <img src={`https://via.placeholder.com/100x100/3f67fa/ffffff?text=${record.name?.substring(0, 1)}`} alt={record.firstName}
                        style={{ height: '32px' }}
                        title={record.firstName} className="img-fluid rounded " />

                    {/* {record.status === 0 ?
                        <i className="fa fa-circle text-light status"></i>
                        : <i className="fa fa-circle text-success status"></i>} */}
                </div>
            </div>
            <div className="col pl-0 text-truncate">
                <small><b>{record.name}</b>
                </small>
                {record.lastMessage && record.lastMessage.length > 0 ?
                    <>
                        <br />
                        <small >{record.lastMessage} <span className="font-weight-light ">- {moment(record.lastMessageTime).fromNow()}</span> </small>
                    </> : <><br />
                        <small>-</small></>}
            </div>
            <div className="col-auto">
                {record.unreadCount > 0 ?
                    <span className="badge bg-danger">{record.unreadCount}</span>
                    : null}
            </div>

        </div>)
    })

    }</>)
}

export default UserListUI;