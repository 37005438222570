import React, { useEffect, createRef, useState } from "react";
import {
  Row,
  Dropdown,

} from "@themesberg/react-bootstrap";

import { Routes } from "../../routes";
import IslogedIn from "../../common/IsLogedIn";
import API from "../../common/API";

import moment from "moment-timezone";

import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { parsedUser } from "../../common/GetCurrentUser";
import AddEditActivity from "../Common/Agenda/AddEditActivity";

import PostList from "../Posts/PostList";

import ShareCalendar from "../Common/Agenda/shareCalendar";
import AddEditProject from "./AddEditProject";
import { confirmAlert } from "react-confirm-alert";

import Files2 from "../files2";
import ActivityList from "../Common/Agenda/ActivityList";
import CalendarView from "../Common/Agenda/CalendarView";
import MilestoneList from "../Milestones/MilestoneList";
import ProjectPermission from "./ProjectPermission";
import { useHistory } from "react-router-dom";
import MinutesList from "../Common/Agenda/MinutesList";

export default (props) => {
  const fullCalendarRef = createRef();
  const [CurrentDate, setCurrentDate] = useState(new Date());
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [projectDetails, setProjectDetails] = useState(null);
  const [activityMode, setActivityMode] = useState("add");
  const [showAddEditProject, setShowAddEditProject] = useState(false);
  const [showProjectPermissions, setShowProjectPermissions] = useState(false);
  const [rawEvents, setRawEvents] = useState([]);
  const [showShare, setShowShare] = useState(false);
  const history = useHistory();

  const toggleShare = () => {
    setShowShare(!showShare);
  };
  const toggleProjectPermissions = (reload) => {
    setShowProjectPermissions(!showProjectPermissions);
    if (reload === true) {
      request();
    }
  };
  const [state, setState] = useState({
    Events: [],
    UI: {
      OnlyForTheUser: true,
      ActivitySelected: null,
      selectedOption: "calendar",
    },
  });

  const toggleAddEditProject = (reload) => {
    setShowAddEditProject(!showAddEditProject);
    if (reload === true) {
      request();
    }
  };

  const requestProjectDetails = async () => {
    let currentUser = parsedUser();
    let query = "id=" + props.match.params.id;

    let requestAPI = await API.getAction("projects/details?" + query);
    if (requestAPI.data.status === "ok") {
      setProjectDetails(requestAPI.data.response);
    }
  };

  const request = async () => {
    let currentUser = parsedUser();
    let query = "";

    if (state.UI.OnlyForTheUser) {
      query = "AssignedToId=" + currentUser.ID;
    } else {
      query = "OrganizationId=" + currentUser.OrganizationId + "&AssignedToId=";
    }
    query += "&ProjectId=" + props.match.params.id;

    let requestAPI = await API.getAction("Appointments/List?" + query);

    if (requestAPI.data.status === "ok") {
      setRawEvents(requestAPI.data.response);

      let parsedEvents = [];
      requestAPI.data.response.forEach((item) => {
        let theNewEvent = {
          id: item.id,
          date: item.date,
          title: item.name,
        };

        if (item.project && item.project.color) {
          theNewEvent.color = item.project.color;
        }
        parsedEvents.push(theNewEvent);
      });

      setState({
        ...state,
        Events: parsedEvents,
      });
    }
  };

  const moveMonth = (obj) => {
    let d = moment(CurrentDate).add(obj, "months");
    setCurrentDate(d.format());
    if (fullCalendarRef.current !== null) {
      fullCalendarRef.current.getApi().gotoDate(d.format());
      fullCalendarRef.current.getApi().select(d.format());
    }
    //We must request current month events :)
  };

  useEffect(() => {
    console.log(window.location.hash)
    let section = window.location.hash.substring(1);
    setState({
      ...state,
      UI: {
        ...state.UI,
        selectedOption: section
      }
    })
  }, [window.location.hash])

  const calendarUI = () => {
    return (
      <div className="card shadow-sm">
        <div className="card-header  px-2">
          <div className="row">
            <div className="col-auto pt-1">
              <h5> {moment(CurrentDate).format("MMM YYYY")}</h5>
            </div>
            <div className="col">
              <div className="btn-group">
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={() => {
                    moveMonth(-1);
                  }}
                >
                  <i className="fas fa-chevron-left"></i>
                </button>
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={() => {
                    moveMonth(1);
                  }}
                >
                  <i className="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
            <div className="col text-right pt-1">
              <button
                className="btn btn-sm btn-primary mx-2"
                title="Vista de lista"
                onClick={() => {
                  toggleViewMode("list");
                }}
              >
                <i className="fa fa-list" />
              </button>
              <button
                className="btn btn-sm btn-secondary "
                onClick={() => {
                  setCurrentDate(moment());
                }}
              >
                Hoy
              </button>
              <div className="text-right btn-group px-2">
                <button className="btn btn-sm btn-primary d-none">
                  <i className="fas fa-filter"></i>
                </button>

                <button
                  className="btn btn-primary btn-sm"
                  onClick={toggleShare}
                  type="button"
                  title="Compartir"
                >
                  <i className="fas fa-share"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body px-0 py-0">
          <FullCalendar
            ref={fullCalendarRef}
            className="calendarStyle"
            initialDate={CurrentDate}
            headerToolbar={false}
            events={state.Events}
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            eventClick={(i) => {
              console.log("click", i, i.event.id);
              toggleAddEdit(i.event, "edit", false);
              //  props.history.push(Routes.ActivityDetails.clean + i.event.id);
            }}
            dateClick={(i) => {
              console.log("click ====", i);
              var theUser = parsedUser();

              toggleAddEdit(
                {
                  name: "",
                  notes: "",

                  status: 0,
                  createdId: theUser.ID,
                  updatedId: "",
                  invites: "",
                  customerId: null,
                  location: "",
                  date: moment(i.date).format("YYYY-MM-DD"),
                  created: moment(new Date()).format("YYYY-MM-DDTHH:mm:SSZ"),
                  id: "",
                  organizationId: theUser.OrganizationId,
                  appointmentTypeId: null,
                  assignedToId: theUser.ID,
                  type: 1,
                  projectId: props.match.params.id,
                },
                "add",
                false
              );
            }}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!IslogedIn()) {
      props.history.push(Routes.SignIn.path);
    }
    requestProjectDetails();
    request();
  }, [props.match.params.id]);

  const toggleAddEdit = async (opt, mode, reload) => {
    if (mode === "edit") {
      let ExistingTask = await API.getAction(
        "appointments/details?id=" + opt.id
      );
      if (ExistingTask.data.status === "ok") {
        opt = ExistingTask.data.response;
      }
    }

    setState({
      ...state,
      UI: {
        ...state.UI,
        ActivitySelected: opt,
      },
    });

    setActivityMode(mode);

    setShowAddEdit(!showAddEdit);

    if (reload === true) {
      await request();
    }
  };

  const deleteConfirm = async () => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure to delete " + projectDetails.name + "?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await API.getAction("Projects/Delete?id=" + projectDetails.id);
            window.location = "/";
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };
  const toggleViewMode = (mode) => {
    setState({
      ...state,
      UI: {
        ...state.UI,
        selectedOption: mode,
      },
    });
  };

  return (
    <>
      {showAddEdit ? (
        <AddEditActivity
          toggle={toggleAddEdit}
          show={showAddEdit}
          model={state.UI.ActivitySelected}
          mode={activityMode}
        />
      ) : null}
      {showAddEditProject ? (
        <AddEditProject
          toggle={toggleAddEditProject}
          show={showAddEditProject}
          model={projectDetails}
        />
      ) : null}
      {showProjectPermissions ? (
        <ProjectPermission
          toggle={toggleProjectPermissions}
          show={showProjectPermissions}
          model={projectDetails}
        />
      ) : null}

      {showShare ? (
        <ShareCalendar
          projectId={props.match.params.id}
          toggle={toggleShare}
          show={showShare}
        />
      ) : null}
      <Row>
        <div className="col my-4">
          <div className=" card shadow-lg">
            <div className="card-body py-2 px-2 px-md-4">
              <div className="row">
                <div className="col-auto">
                  <h5 className="d-inline my-2 mx-2 ml-0">
                    {projectDetails !== null && projectDetails.name}
                  </h5>
                </div>

                <div className="col">
                  <div className="btn-group">
                    <button
                      className={
                        (state.UI.selectedOption === "post"
                          ? "btn-primary"
                          : "btn-secondary") + " btn  btn-sm"
                      }
                      onClick={() => {
                        toggleViewMode("post");
                      }}
                    >
                      Chat
                    </button>
                    <button
                      className={
                        (state.UI.selectedOption === "calendar" ||
                          state.UI.selectedOption === "list"
                          ? "btn-primary"
                          : "btn-secondary") + " btn  btn-sm"
                      }
                      onClick={() => {
                        toggleViewMode("calendar");
                      }}
                    >
                      Calendar
                    </button>
                    <button
                      className={
                        (state.UI.selectedOption === "files"
                          ? "btn-primary"
                          : "btn-secondary") + " btn  btn-sm"
                      }
                      onClick={() => {
                        history.push(
                          Routes.ProjectDashboard.clean +
                          props.match.params.id +
                          "#files"
                        );
                        toggleViewMode("files");
                      }}
                    >
                      Files
                    </button>

                    <button
                      className={
                        (state.UI.selectedOption === "milestone"
                          ? "btn-primary"
                          : "btn-secondary") + " btn  btn-sm"
                      }
                      onClick={() => {
                        toggleViewMode("milestone");
                      }}
                    >
                      Timeline
                    </button>
                    {projectDetails && projectDetails.monday ? (
                      <button
                        className={
                          (state.UI.selectedOption === "monday"
                            ? "btn-primary"
                            : "btn-secondary") + " btn  btn-sm"
                        }
                        onClick={() => {
                          toggleViewMode("monday");
                        }}
                      >
                        Monday
                      </button>
                    ) : null}
                    {/* <button
                      className={
                        (state.UI.selectedOption === "minutes"
                          ? "btn-primary"
                          : "btn-secondary") + " btn  btn-sm"
                      }
                      onClick={() => {
                        toggleViewMode("minutes");
                      }}
                    >
                      Minutas
                    </button> */}
                    {/* <button
                      className={
                        "btn-primary" + " btn  btn-sm"
                      }
                      onClick={() => {
                        const windowFeatures = "left=100,top=100,width=800,height=600";
                        window.open('/notes/' + props.match.params.id, '_blank', windowFeatures)
                      }}
                    >
                      Notas
                    </button> */}
                  </div>
                </div>
                <div className="col-auto ml-auto px-0"></div>
                <div className="col-auto ps-1">
                  <Dropdown>
                    <Dropdown.Toggle size="sm" id="dropdown-basic" className="btn">
                      <i className="fa fa-ellipsis-v" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={toggleProjectPermissions}>
                        Permisos
                      </Dropdown.Item>
                      <Dropdown.Item onClick={toggleAddEditProject}>
                        Editar
                      </Dropdown.Item>
                      <Dropdown.Item onClick={deleteConfirm}>
                        Eliminar
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            {/* {projectDetails !== null &&
              projectDetails.description !== null &&
              projectDetails.description.length > 0 ? (
              <div className="card-footer p-2 px-md-4">
                {projectDetails !== null && projectDetails.description}
              </div>
            ) : null} */}
          </div>
        </div>
      </Row>

      <Row>
        <div className={" col-12"}>
          {state.UI.selectedOption === "milestone" ? (
            <MilestoneList projectId={props.match.params.id} />
          ) : null}
          {state.UI.selectedOption === "post" ? (
            <PostList projectId={props.match.params.id} />
          ) : null}
          {state.UI.selectedOption === "calendar" ? (
            <CalendarView
              projectId={props.match.params.id}
              toggleViewMode={toggleViewMode}
            />
          ) : null}

          {state.UI.selectedOption === "files" ? (
            <Files2 projectId={props.match.params.id} />
          ) : null}

          {state.UI.selectedOption === "list" ? (
            <ActivityList
              projectId={props.match.params.id}
              Activities={rawEvents}
              toggleViewMode={toggleViewMode}
            />
          ) : null}
          {state.UI.selectedOption === "minutes" ? (
            <MinutesList
              projectId={props.match.params.id}
              Activities={rawEvents}
              toggleViewMode={toggleViewMode}
            />
          ) : null}
          {state.UI.selectedOption === "monday" ? (
            <div className="card shadow-sm">
              <div className="card-body p-1">
                <iframe
                  src={projectDetails && projectDetails.monday}
                  style={{ width: "100%", height: "70vh" }}
                ></iframe>
              </div>
            </div>
          ) : null}
        </div>
      </Row>
    </>
  );
};
