import React, { useEffect, useState } from 'react';

import { Modal, Button, Col, Row, Form } from '@themesberg/react-bootstrap';

import * as yup from 'yup';
import { Formik, useFormik } from 'formik';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead'; // ES2015

import 'react-bootstrap-typeahead/css/Typeahead.css';

import moment from 'moment';
import { parse, v4 as uuidv4 } from 'uuid';
import API from '../../../common/API';
import { makeId, onlyHTMLTags } from '../../../common/helpers';
import { parsedUser } from '../../../common/GetCurrentUser';
import { confirmAlert } from 'react-confirm-alert';


import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

//Validation
const validationSchema = yup.object({
    name: yup.string().required(),
    date: yup.string().required(),
    status: yup.number().required(),
    type: yup.number().required()

});
//Component
const ShareCalendar = props => {
    const [calendarURL, setCalendarURL] = useState("");
    useEffect(() => {
        let user = parsedUser();
        if(props.global !==true){
        setCalendarURL("https://voapi2.shearly.com/api/appointments/calendar?id=" + user.OrganizationId + "&currentuserid=" + user.ID + (props.projectId? "&projectId=" + props.projectId:""));
        }else{
            setCalendarURL("https://voapi2.shearly.com/api/appointments/calendarglobal?currentuserid=" + user.ID );
    
        }
    }, [])
    return (
        <Modal show={props.show} onHide={props.toggle} size='md'>
            <Modal.Header closeButton>
                <Modal.Title>Calendario</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row>

                    <Col xs={12}>
                        <Form.Label>URL (Solo lectura)</Form.Label>
                        <Form.Control type="text"
                            name="name"
                            value={calendarURL} />

                    </Col>
                </Row>
            </Modal.Body>

        </Modal>
    )
}

export default ShareCalendar;