import React, { useContext, useState, useEffect } from "react";
import { SocketContext } from "../../Context/socket";

import  { parsedUser } from "../../common/GetCurrentUser";
import ConversationUI from "./ConversationUI";
import AddGroup from "./AddGroup";
import imgNoChatSelected from '../../assets/img/illustrations/undraw_chat_re_re1u.svg'
import UserListUI from "./UserListUI";
const EntireScreenChat = props => {

    const [records, setRecords] = useState([]);
    const [currentUser, setCurrentUser] = useState(parsedUser());
    const [showConversation, setShowConversation] = useState(false);
    const [showAddGroup, setShowAddGroup] = useState(false);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [messages, setMessages] = useState([]);

    
    const socket = useContext(SocketContext);

    //EVENTS
    
    socket.on("onGetConversations", items => {
        setRecords(items);
    })

    socket.on("OnStartConversation", (item, withAccountId) => {
        setSelectedConversation(item);
        socket.invoke("JoinRoom", item.id, withAccountId);
    })
    socket.on("onNotifyAboutNewMessage", () => {
        
      console.log("on notify about new message")
    });
    
    socket.on("ReceiveUnreadMessagesInGeneral", (data, status, user) => {

        socket.invoke("GetConversations", currentUser.ID);
    })

    socket.on("onError", (newMessage) => {
        debugger;
        console.error(newMessage);
    })
    
    socket.on("onOnline", () => {
        socket.invoke("GetConversations", currentUser.ID);
        if (!props.conversationSelected || props.conversationSelected === null) {
        }
    })

    const toggleConversation = (currentConversation) => {
        console.log("toggle conversation", new Date());
        
        setShowConversation(!showConversation);
        setSelectedConversation(currentConversation);

        if (!showConversation === true && currentConversation.conversationId) {
            //Conversation exist
            //Conversation exist so we must provide the join room 
            socket.invoke("JoinRoom", currentConversation.conversationId, currentConversation.userId);
        }
        else if (!showConversation === true) {
            //Conversation does not exist yet

           //  socket.invoke("StartConversation", currentConversation.conversationId ? currentConversation.conversationId : null, currentUser.ID, currentConversation.userId);
        }
        else if (currentConversation && currentConversation.conversationId) {
            //Leave, and go back to the list
            socket.invoke("LeaveRoom", currentConversation.conversationId);
            //socket.invoke("GetConversations", currentUser.ID);

        }
        setTimeout(() => {
            document.getElementById("textMessageContainer") && 
            document.getElementById("textMessageContainer").focus();
        }, 500)
    }

    const toggleAddGroup = (reload) => {
        setShowAddGroup(!showAddGroup);
        if (reload) {

        }
        socket.invoke("GetConversations", currentUser.ID);
    }

    useEffect(() => {
        console.log("socket state", socket.state)
        if (socket.state === "Connected") {
            socket.invoke("GetConversations", currentUser.ID);
        }

    }, []);

    return (
        <>
            {showAddGroup ? <AddGroup show={showAddGroup} toggle={toggleAddGroup} /> : null}
            <div className="card mt-2 shadow-sm" style={{ height: 'calc(100vh - 100px)' }}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-md-3 userListContainer ">
                            <button className="btn btn-sm btn-primary" title="Mensaje grupal"
                                onClick={() => { toggleAddGroup() }}>
                                <i className="fas fa-user-plus me-2"></i> New Chat
                            </button>
                            <hr />
                            {<UserListUI records={records} toggleConversation={toggleConversation} selectedConversation={selectedConversation} />}
                        </div>
                        <div className="col-12 col-md-9">
                            {selectedConversation ?
                                <ConversationUI messages={messages} socket={socket} selectedConversation={selectedConversation} /> :
                                <div className="text-center">
                                    <img src={imgNoChatSelected} className="img-fluid my-4" style={{ maxWidth: "50%" }} alt={""}></img>
                                    <h5>Select a chat</h5>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>)
}

export default EntireScreenChat;