import React, { useEffect, useState } from "react";
// import "./index.css";
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  Annotation,
  TextSearch,
  FormFields,
  FormDesigner,
  Inject,
} from "@syncfusion/ej2-react-pdfviewer";
import Settings from "../../common/Settings";

import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import moment from "moment";
import { ProperBasePathAPI } from "./helper";

const PDFContainer = (props) => {
  const { id } = useParams();
  const apiBasePath = ProperBasePathAPI();
  const [fileSelected, setFileSelected] = useState(null);
  const [fileName, setfileName] = useState(
    !id || !fileSelected
      ? "Untitled " + moment().format()
      : setFileSelected.name
  );

  useEffect(() => {
    //Now let's try to get the file and open
    let fullPath = props.file.path; ////`${Settings.BasePath}files/${props.file.path}`;
    setfileName(props.file.name);
    console.log(fullPath);
    setFileSelected(fullPath);
  }, [props.file]);

  const loadUI = () => {
    return (
      <Container fluid>
        <Row>
          <Col xs={12} style={{ height: props.modal ? "78vh" : "100vh" }}>
            {/* Render the PDF Viewer */}
            <PdfViewerComponent
              id="container"
              documentPath={fileSelected}
              serviceUrl={`${apiBasePath}file/pdf`}
              //serviceUrl="https://services.syncfusion.com/react/production/api/pdfviewer"
              resourceUrl="/ej2-pdfviewer-lib"
              style={{ height: "100%" }}
            >
              <Inject
                services={[
                  Toolbar,
                  Magnification,
                  Navigation,
                  Annotation,
                  LinkAnnotation,
                  BookmarkView,
                  ThumbnailView,
                  Print,
                  TextSelection,
                  TextSearch,
                  FormFields,
                  FormDesigner,
                ]}
              />
            </PdfViewerComponent>
          </Col>
        </Row>
      </Container>
    );
  };
  if (props.modal) {
    return (
      <Modal show={true} onHide={props.toggle} size="xl" className="full">
        <Modal.Body>{loadUI()}</Modal.Body>
      </Modal>
    );
  }
  return <>{loadUI()}</>;
};

export default PDFContainer;
