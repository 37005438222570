import React, { useEffect, useState } from "react";
import API from "../../common/API";
import { parsedUser } from "../../common/GetCurrentUser";
import {Routes} from '../../routes';

const OverviewTaskByProject = props => {
    const [state, setState] = useState([]);
    const [user, setUser] = useState(null);
    const request = async () => {
        let theUser = parsedUser();
        let requested = await API.getAction("dashboard/TotalByProject?organizationid=" + theUser.OrganizationId);
        if (requested.data.status === "ok") {
            setState(requested.data.response);
        }
    }
    useEffect(() => {
        let theUser = parsedUser();
        setTimeout( ()=>{

            setUser(theUser);
        }, 500);
        request();
    }, []);


    return (<div className="card shadow-sm">
        <div className="card-header p-2">
            <b>Actividades por {user && user.OrganizationType === "0" ? "Inversión" : user && user.OrganizationType === "1" ? "Proyecto" : "-"}</b>
        </div>
        <div className="card-body p-2">
            <table className="table table-stripped">
                <tbody>
                {state.map((item, index) => {
                    return (<tr key={index}>
                        <td >
                            <a href={Routes.ProjectDashboard.clean+item.projectId} className="clickable">
                            {item.projectName}
                            </a>
                        </td>
                        <td className=" text-right">
                            {item.taskCount}
                        </td>
                    </tr>);
                })}
                </tbody>
            </table>

        </div>
    </div>)
}

export default OverviewTaskByProject;