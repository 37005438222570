import React, { useState, createRef, useEffect } from "react";
import moment from "moment";
import AddEditActivity from "./AddEditActivity";
import { parsedUser } from "../../../common/GetCurrentUser";
import API from "../../../common/API";
import { Routes } from "../../../routes";

import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import ShareCalendar from "./shareCalendar";
import IsLogedIn from "../../../common/IsLogedIn";
import { useHistory } from "react-router-dom";
import { PushNotifications } from "../PushNotifications";

const CalendarView = (props) => {
  // PARAMETERS
  const history = useHistory();
  const fullCalendarRef = createRef();
  const [CurrentDate, setCurrentDate] = useState(new Date());
  const [calendarView, setCalendarView] = useState("timeGridWeek");
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [activityMode, setActivityMode] = useState("add");
  const [showShare, setShowShare] = useState(false);
  const [state, setState] = useState({
    Events: [],
    UI: {
      OnlyForTheUser: false,
      ActivitySelected: null,
    },
  });

  // METHODS
  const request = async () => {
    let currentUser = parsedUser();
    let query = "";
    console.log("Request", CurrentDate);
    if (props.unified === undefined) {
      query += "OrganizationId=" + currentUser.OrganizationId;
    }
    if (state.UI.OnlyForTheUser) {
      query += "&AssignedToId=" + currentUser.ID;
    } else {
      query += "&AssignedToId=";
    }

    if (props.projectId) {
      query += "&projectId=" + props.projectId;
    }

    const startOfMonth = moment(CurrentDate).startOf('month').add(-5, 'days').format('YYYY-MM-DD hh:mm');
    const endOfMonth = moment(CurrentDate).endOf('month').add(5, 'days').format('YYYY-MM-DD hh:mm');

    query += "&from=" + startOfMonth + "&until=" + endOfMonth;
    query += "&quantity=200";

    let requestAPI = await API.getAction("Appointments/List?" + query);

    if (requestAPI.data.status === "ok") {
      let parsedEvents = [];
      let results = requestAPI.data.response;
      console.log("results", results);

      results.forEach((item) => {
        let theBackgroundColor =
          item.project && item.project.color ? item.project.color : "#3788d8";

        if (item.name === 'WELLMED 2024') {
          console.log(`record cound ${results.length} `, item, item.id)

        }
        let theNewEvent = {
          id: item.id,
          start: item.date,
          end: item.endDate
            ? item.endDate
            : moment(item.date).add("30", "minutes").format(),
          resourceId: "a",
          allDay: false,
          title:
            (item.project && props.projectId === undefined
              ? item.project.name + " > "
              : "") + item.name,
          rendering: "background",
          backgroundColor: theBackgroundColor,
          color: theBackgroundColor,
        };

        //  console.log("formated event", theNewEvent);

        if (
          item.assignedToId === currentUser.ID ||
          (item.invitees &&
            item.invitees.length > 0 &&
            item.invitees.includes(currentUser.Email))
        ) {
          theNewEvent.backgroundColor = theBackgroundColor;
          theNewEvent.color = theBackgroundColor;
        } else if (props.unified) {
          //We need to apply the opacity

          theNewEvent.backgroundColor = theBackgroundColor + "50";
          theNewEvent.color = theBackgroundColor + "50";
          //    console.log("enter to aply transparency", theBackgroundColor, item);
        }
        parsedEvents.push(theNewEvent);
      });

      setState({
        ...state,
        Events: parsedEvents,
      });
    }
  };

  const updateDateAndTime = async (obj) => {
    let query =
      "id=" + obj.id + "&startDate=" + obj.startStr + "&endDate=" + obj.endStr;
    await API.getAction("appointments/UpdateDateAndTime", query);
    await request();
  };
  const move = (obj) => {
    let d = null;
    if (calendarView === "timeGridDay") {
      d = moment(CurrentDate).add(obj, "days");
    } else if (calendarView === "dayGridMonth") {
      d = moment(CurrentDate).add(obj, "months");
    } else if (calendarView === "timeGridWeek") {
      d = moment(CurrentDate).add(obj, "weeks");
    }

    if (fullCalendarRef.current !== null) {
      fullCalendarRef.current.getApi().gotoDate(d.format());
      fullCalendarRef.current.getApi().select(d.format());
    }

    setCurrentDate(new Date(d.format()));

  };

  const toggleShare = () => {
    setShowShare(!showShare);
  };
  const toggleAddEdit = async (opt, mode, reload) => {
    if (mode === "edit") {
      let ExistingTask = await API.getAction(
        "appointments/details?id=" + opt.id
      );
      if (ExistingTask.data.status === "ok") {
        opt = ExistingTask.data.response;
      }
    }

    setState({
      ...state,
      UI: {
        ...state.UI,
        ActivitySelected: opt,
      },
    });

    setActivityMode(mode);
    setShowAddEdit(!showAddEdit);

    if (reload === true) {
      await request();
    }
  };

  // EVENTS

  useEffect(() => {
    request();
  }, [CurrentDate])
  useEffect(() => {
    if (!IsLogedIn()) {
      history.push(Routes.SignIn.path);
    }



  }, []);

  // UI
  return (
    <>
      <PushNotifications />
      {showShare ? (
        <ShareCalendar toggle={toggleShare} show={showShare} />
      ) : null}
      {showAddEdit ? (
        <AddEditActivity
          toggle={toggleAddEdit}
          show={showAddEdit}
          model={state.UI.ActivitySelected}
          unified={props.unified}
        />
      ) : null}
      <div
        className={
          "card shadow-sm calendarContainer" + (props.full ? " full" : "")
        }
      >
        <div className="card-header  px-2">
          <div className="row">
            <div className="col-auto pt-1">
              <h5>{moment(CurrentDate).format("MMM YYYY")}</h5>
            </div>
            <div className="col-auto">
              <div className="btn-group">
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={() => {
                    move(-1);
                  }}
                >
                  <i className="fas fa-chevron-left"></i>
                </button>
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={() => {
                    move(1);
                  }}
                >
                  <i className="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
            <div className="col text-right pt-1">
              <button
                className={"btn btn-sm mx-4"}
                title="Recargar"
                onClick={async () => {
                  await request();
                }}
              >
                <i className="fas fa-sync"></i>
              </button>

              <div className="btn-group d-none d-md-inline">
                <button
                  className={
                    "btn btn-sm " +
                    (calendarView === "timeGridDay"
                      ? "btn-primary"
                      : "btn-secondary")
                  }
                  onClick={() => {
                    setCalendarView("timeGridDay");
                    fullCalendarRef.current.getApi().changeView("timeGridDay");
                  }}
                >
                  Dia
                </button>
                <button
                  className={
                    "btn btn-sm " +
                    (calendarView === "timeGridWeek"
                      ? "btn-primary"
                      : "btn-secondary")
                  }
                  onClick={() => {
                    setCalendarView("timeGridWeek");
                    fullCalendarRef.current.getApi().changeView("timeGridWeek");
                  }}
                >
                  Semana
                </button>

                <button
                  className={
                    "btn btn-sm " +
                    (calendarView === "dayGridMonth"
                      ? "btn-primary"
                      : "btn-secondary")
                  }
                  onClick={() => {
                    setCalendarView("dayGridMonth");
                    fullCalendarRef.current.getApi().changeView("dayGridMonth");
                  }}
                >
                  Mes
                </button>
              </div>
              <button
                className="btn btn-sm btn-secondary mx-2 d-none d-md-inline "
                onClick={() => {
                  setCurrentDate(moment());
                }}
              >
                Hoy
              </button>
              <div className="text-right btn-group px-2 d-none d-md-inline">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={toggleShare}
                  type="button"
                  title="Compartir"
                >
                  <i className="fas fa-share"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body px-0 py-0">
          <FullCalendar
            ref={fullCalendarRef}
            className="calendarStyle"
            height={"100%"}
            initialDate={CurrentDate}
            editable={true}
            nowIndicator={true}
            headerToolbar={false}
            events={state.Events}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView={calendarView}
            eventClick={(i) => {
              toggleAddEdit(i.event, "edit", false);
            }}
            eventDrop={async (i) => {
              console.log(
                "finish drop ====",
                i,
                i.event,
                i.event.id,
                "START",
                i.event.startStr,
                "END",
                i.event.endStr
              );
              await updateDateAndTime(i.event);
            }}
            eventResize={async (i) => {
              console.log(
                "finish resize ====",
                i,
                i.event,
                i.event.id,
                i.event.startStr,
                i.event.endStr
              );
              await updateDateAndTime(i.event);
            }}
            dateClick={(i) => {
              console.log("click ====", i);
              var theUser = parsedUser();
              toggleAddEdit(
                {
                  name: "",
                  notes: "",

                  status: 0,
                  createdId: theUser.ID,
                  updatedId: "",
                  invites: "",
                  customerId: null,
                  location: "",
                  date: moment(i.date).format("YYYY-MM-DD HH:mm"),
                  endDate: moment(i.date)
                    .add(1, "hours")
                    .format("YYYY-MM-DD HH:mm"),
                  created: moment(new Date()).format("YYYY-MM-DDTHH:mm:SSZ"),
                  id: "",
                  organizationId: theUser.OrganizationId,
                  appointmentTypeId: null,
                  assignedToId: theUser.ID,
                  type: 1,
                  projectId: props.projectId,
                },
                "add",
                false
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

export default CalendarView;
