//We are settings an initial state here for clarity, probably is not needed

const Settings = {
    App: "VO",
    Name: "VO",

    baseURl: "./",
    BasePath: "https://voapi2.shearly.com/",
    BasePathForAPI: "https://voapi2.shearly.com/api/",
    ForBluesteel: true,
    BasePathBluesteel: "https://api.vo.bluesteel.do/",
    BasePathForAPIBluesteel: "https://api.vo.bluesteel.do/api/",

    SignalURL: "https://signal2.shearly.com/communicationHub",

    SyncFusionKey: "Ngo9BigBOggjHTQxAR8/V1NHaF5cXmVCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH5fcHVVRWZdVUZ2X0o="
};

export default Settings;
