import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";

import { v4 } from "uuid";
import { parsedUser } from "../../common/GetCurrentUser";
const ActiveCall = props => {
    let jitsiObj = null
    const [state, setState] = useState({

        StartMeeting: false,
        isMuted: false,
        isVideoOff: props.call.isVideoCall !==true,
        Username: ""
    })

    const toggleMute = async () => {

        let audio = await jitsiObj.executeCommand('toggleAudio');

        let muted = await jitsiObj.isAudioMuted();
        setState({
            ...state,
            isMuted: muted
        })

    }

    const toggleVideo = async () => {

        let video = await jitsiObj.executeCommand('toggleVideo');

        let videoOff = await jitsiObj.isVideoMuted();
        setState({
            ...state,
            isVideoOff: videoOff
        })

    }
    const loadJitsiScript = async () => {
        let resolveLoadJitsiScriptPromise = null;

        const loadJitsiScriptPromise = new Promise((resolve) => {
            resolveLoadJitsiScriptPromise = resolve;
        });

        const script = document.createElement("script");
        script.src = "https://meet.jit.si/external_api.js";
        script.async = true;
        script.onload = resolveLoadJitsiScriptPromise
        document.body.appendChild(script);

        return loadJitsiScriptPromise;
    };

    const initialiseJitsi = async (target, initMeeting, id) => {
        setState({
            ...state,
            StartMeeting: initMeeting
        });

        if (!window.JitsiMeetExternalAPI || initMeeting) {
            await loadJitsiScript();
        }
        let name = state.Username;
        if (id === undefined) {
            id = v4()
        }
        const container = document.getElementById(target);
        let theUser = parsedUser();
        debugger;
        const _jitsi = new window.JitsiMeetExternalAPI("jitsi.debian.social", {
            parentNode: container,
            configOverwrite: {
                startWithAudioMuted: state.isMuted,
                startWithVideoMuted: state.isVideoOff,
                toolbarConfig: {
                    alwaysVisible: false
                },
                enableWelcomePage: false,
                toolbarButtons: []
            },
            roomName: id,
            userInfo: {

                displayName: theUser.Email
            }
        });
        _jitsi.addListener("audioMuteStatusChanged", ev => {
            setState({
                ...state,
                isMuted: ev.muted
            });
            console.log("toggle ev", ev);
        })
        _jitsi.addListener("videoMuteStatusChanged", ev => {

            setState({
                ...state,
                isVideoOff: ev.muted
            });
            console.log("toggle ev", ev);
        })
        jitsiObj = _jitsi

        let muted = await jitsiObj.isAudioMuted();

        setState({
            ...state,
            isMuted: muted
        })
        let videoOff = await jitsiObj.isVideoMuted();
        setState({
            ...state,
            isVideoOff: videoOff
        })


        if (!muted && state.isMuted) {
            toggleMute();
        }

        if (!videoOff && state.isVideoOff) {
            toggleVideo();
        }
    };

    useEffect(() => {
        initialiseJitsi("meet2", "222d");
    }, [])

    return (<div className="activeCallContainer">

        <Row className="justify-content-center  mx-0" >
            <div>
                <div id="meet2" className="" style={{ height: "200px" }}></div>
            </div>


        </Row>
    </div>)
}

export default ActiveCall;