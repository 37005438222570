import React, { useEffect, useState } from 'react';

import { Modal, Button, Col, Row, Form } from '@themesberg/react-bootstrap';

import * as yup from 'yup';
import API from '../../common/API';

import { parsedUser } from '../../common/GetCurrentUser';

//Component
const ProjectPermission = props => {
    const [model, setModel] = useState({});
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        console.log("props received on activity", props);
        setModel(props.model);
        //load the users
        loadUsers();
    }, [props.model])

    const loadUsers = async () => {
        var theUser = parsedUser();
        let query = "";
        var request = await API.getAction("users/AutoCompleteSearch?Search=" + query + "&OrganizationId=" + theUser.OrganizationId);

        if (request.data.status === "ok") {
            let opts = request.data.response.map(e =>
                (e)
            );
            setUsers(opts);
        }

    }


    const handleSubmit = async () => {

        setIsLoading(!isLoading);

        var theUser = parsedUser();
        let request = await API.postAction("Projects/AddEdit?CurrentUserId=" + theUser.ID, model);
        setIsLoading(!isLoading);
        if (request.status === 200) {
            props.toggle(true);
        }
    };




    const toggleAddRemove = (itemId, add) => {
        if (add === true) {
            setModel({
                ...model,
                membersAssigned: model.membersAssigned + (model.membersAssigned && model.membersAssigned.length > 0 ? "," : "") + itemId
            });
        } else {
            setModel({
                ...model,
                membersAssigned: model.membersAssigned && model.membersAssigned.includes("," + itemId) ? model.membersAssigned.replace("," + itemId, "") : model.membersAssigned.replace(itemId, "")
            });
        }
    }

    return (
        <Modal show={props.show} onHide={props.toggle} size='md'>
            <Modal.Header closeButton>
                <Modal.Title>Permisos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {users && users.length > 0 && users.map((item, index) => {
                    return (<div className='row' key={index}>
                        <div className='col-1'>

                            {model.ownerId !== item.id && model.membersAssigned && model.membersAssigned.includes(item.id) ?
                                <button className='btn btn-sm' onClick={() => toggleAddRemove(item.id, false)}>
                                    <i className='fa fa-check-square' />
                                </button>
                                : model.ownerId !== item.id ?
                                    <button className='btn btn-sm' onClick={() => toggleAddRemove(item.id, true)}>
                                        <i className='fa fa-square' />
                                    </button>
                                    : null
                            }
                        </div>
                        <div className='col-11'>
                            <span>{item.firstName + " " + item.lastName}
                            </span>
                            {item.id === model.ownerId ? <span className='badge badge-sm ms-2 bg-primary'><i className="fa fa-crown" /></span> : null}

                            <br />
                            <small className='text-muted text-lowercase'>{item.email}</small>
                        </div>

                    </div>)
                })}
            </Modal.Body>
            <Modal.Footer>

                <Button variant="primary" type="submit" className="btn-sm"
                    onClick={() => handleSubmit()}
                    disabled={isLoading}
                >
                    Guardar
                </Button>
            </Modal.Footer>


        </Modal>
    )
}

export default ProjectPermission;